export const AddressID = ":addressID";
export const GiftCardID = "cardID";
export const GiftCardPIN = "pin";
export const PerkID = "perkID";
export const Action = "action";
export const PerkCrmCode = "perkCrmCode";
export const GiftCardNumberQP = "gc";
export const LanguageQP = "locale";
export const PerkGroupName = "perkName";
export const VerificationCode = "verificationCode";
export const ErrorType = "errorType";

export const NavigationPath = {
  AuthComplete: "/auth",
  AuthStart: "/auth-start",
  Home: "/",
  ApolloClubPromo: "/apollo-club-promo",
  Locations: "/locations",
  Terms: "/terms",
  GiftCard: "/gift-card",
  Profile: "/profile",
  CloseAccount: "/profile/close-account",
  Goodbye: "/goodbye",
  EditProfile: "/profile/edit",
  SignUpStart: "/sign-up-start",
  SignUpComplete: "/sign-up-complete/verify",
  ChangePassword: "/profile/change-password",
  ResetPassword: "/reset-password",
  ResetPasswordVerify: "/reset-password/verify",
  ProfileResetPassword: "/profile/reset-password",
  MyApolloClub: "/my-apollo-club",
  Emails: "/profile/emails",
  AddEmail: "/profile/emails/add",
  VerifyEmail: "/profile/emails/verify",
  Phones: "/profile/phones",
  AddPhone: "/profile/phones/add",
  Addresses: "/profile/addresses",
  AddAddress: "/profile/addresses/add",
  EditAddress: `/profile/addresses/${AddressID}`,
  EditPersonalData: "/profile/personal-data",
  Transactions: "/transactions",
  GiftCardStatus: "/status",
  GiftCardStatusDetails: "/status/details",
  PerkDetailsPage: "/details",
  PerkGroupPage: "/gift-card/perk-group",
  Newsletter: "/newsletters",
  VerificationCodeErrorPage: "/verification-code-invalid",
};

export type INavigationPath = keyof typeof NavigationPath;

export const PageTitle: Partial<{
  [Property in INavigationPath]: string;
}> = {
  Profile: "link_my_profile",
  Emails: "link_my_emails",
  Addresses: "link_my_addresses",
  Phones: "link_my_phones",
};
