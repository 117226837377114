import { isPresent } from "@apl-digital/utils";
import { NavigationPath } from "@constants/navigation";
import { selectCurrentLanguage } from "@store/language";
import { AppStartListening } from "@store/listenerMiddleware";
import { selectPerks } from "@store/perks";
import { hasData } from "@store/utils";

import {
  cmsRefreshing,
  selectIsMenuItemsUpdated,
  selectMenuItems,
  setHasMenuNotifications,
  setUpdatedMenuItems,
} from "./slice";

export const addCMSListeners = (startListening: AppStartListening) => {
  startListening({
    predicate: (_action, currentState) =>
      isPresent(selectMenuItems(currentState)) &&
      isPresent(selectPerks(currentState)) &&
      !selectIsMenuItemsUpdated(currentState),
    effect: (_action, { dispatch, getState, unsubscribe, subscribe }) => {
      const menu = selectMenuItems(getState());
      const perks = selectPerks(getState());

      if (menu && hasData(perks)) {
        unsubscribe();
        dispatch(
          setUpdatedMenuItems(
            menu.map((item) => {
              if (
                item.action.path === "redirect" &&
                item.action.params === NavigationPath.GiftCard &&
                perks.array.length > 0
              ) {
                dispatch(
                  setHasMenuNotifications({
                    giftCardPageNotificationsCount: perks.array.length,
                  }),
                );
                return {
                  ...item,
                  notificationsCount: perks.array.length,
                };
              }

              return item;
            }),
          ),
        );
        subscribe();
      }
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      selectCurrentLanguage(currentState) !==
      selectCurrentLanguage(previousState),

    effect: (_action, { dispatch }) => {
      dispatch(cmsRefreshing());
    },
  });
};
