import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VerificationAction } from "@api/interfaces/verificationCodeLayout";
import Warning from "@base/components/Error/Warning";
import { Button, H4, Loader, P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import {
  Action,
  ErrorType,
  NavigationPath,
  VerificationCode,
} from "@constants/navigation";
import { verifyVerificationCode } from "@pages/VerificationCodeErrorPage/helper/VerificationCodeHelper";
import { createUseStyles } from "@theme";
import { useUser } from "src/base/components/UserProvider";

const useStyles = createUseStyles(({ spacing }) => ({
  btn: {
    marginTop: spacing.x3l,
  },
  warning: {
    padding: [spacing.x4l, 0],
    alignSelf: "flex-start",
  },
}));

const VerifyEmailPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [codeStatus, setCodeStatus] = useState<"success" | "used" | "failed">();
  const [{ activateContact }] = useUser();

  useEffect(() => {
    const searchVerificationCode = searchParams.get(VerificationCode);
    void verifyVerificationCode(searchVerificationCode).then(
      ({ isCodeValid, isCodeUsed, status }) => {
        if (isCodeUsed) {
          setCodeStatus("used");
        } else if (isCodeValid && searchVerificationCode) {
          void activateContact({
            verificationCode: searchVerificationCode,
          }).then((isContactActive) => {
            setCodeStatus(isContactActive ? "success" : "failed");
          });
        } else {
          navigate({
            pathname: NavigationPath.VerificationCodeErrorPage,
            search: `${VerificationCode}=${searchVerificationCode}&${Action}=${VerificationAction.VERIFY_EMAIL}&${ErrorType}=${status}`,
          });
        }
      },
    );
    setIsLoading(false);
  }, [searchParams]);

  if (isLoading) {
    return <Loader isMinified />;
  }

  return (
    <Row>
      <Col directionColumn>
        {codeStatus === "success" && (
          <>
            <H4 gray1>{t("verify_email_success_title")}</H4>
            <P gray1>{t("verify_email_success_content")}</P>
          </>
        )}
        {codeStatus === "used" && (
          <>
            <H4 gray1>{t("verify_email_code_already_used_title")}</H4>
            <P gray1>{t("verify_email_code_already_used_content")}</P>
          </>
        )}
        {codeStatus === "failed" && (
          <>
            <Col noMargin fullWidth>
              <H4 gray1>{t("verify_email_error_title")}</H4>
            </Col>
            <Col
              noMargin
              className={classes.warning}
              col={12}
              colMd={9}
              fullWidth
            >
              <Warning message={t("verify_email_warning_badge")} />
            </Col>
            <P gray1>{t("verify_email_error_content")}</P>
          </>
        )}
        <Col noMargin fullWidth>
          <Col col={12} colMd={6} noMargin>
            <Button
              action={() => navigate(NavigationPath.Profile)}
              className={classes.btn}
            >
              {t("verify_email_navigate_btn")}
            </Button>
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

export default VerifyEmailPage;
