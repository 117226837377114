import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IPageSection, SectionType } from "@api/interfaces/pageLayout";
import GiftIcon from "@assets/svg/giftcard_orange.svg";
import { H5, P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import { RegionId } from "@base/utils/featureFlagUtil";
import SimpleProgressBar from "@pages/GiftCardPage/components/SimpleProgressBar";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { fetchBenefits } from "@store/user";
import { hasData, shouldLoad } from "@store/utils";

type Props = {
  block: IPageSection;
};

const NextGiftCardBlock: React.FC<Props> = ({ block }) => {
  const { t } = useTranslation();
  const regionId = useAppSelector((state) => state.user.userData?.regionId);
  const dispatch = useAppDispatch();
  const benefits = useAppSelector((state) => state.user.benefits);

  useEffect(() => {
    if (shouldLoad(benefits)) {
      void dispatch(fetchBenefits());
    }
  }, [benefits]);

  const bkk5Benefit =
    hasData(benefits) &&
    benefits.array.find((el) =>
      el.externalIdentifier.endsWith(SectionType.BKK5),
    );

  // TODO: remove region dependency BKK5 enabled for other regions
  if (!bkk5Benefit || regionId !== RegionId.ESTONIAN_REGION_ID) return null;

  return (
    <Row>
      <Col col={12} directionColumn noMarginTop>
        <Col col={12} noHorizontalMargin directionColumn>
          <H5 noMarginBottom prefixIcon={<GiftIcon />}>
            {block.title}
          </H5>
          <P noMarginTop gray3 xSmall>
            {block.subTitle}
          </P>
        </Col>
        <Col col={12} fullWidth noHorizontalMargin>
          <SimpleProgressBar
            collected={bkk5Benefit.currentValue}
            total={bkk5Benefit.targetValue}
            icon={<GiftIcon />}
          />
        </Col>
        <Col col={12} noHorizontalMargin>
          <P>{t("next_gift_card_info")}</P>
        </Col>
      </Col>
    </Row>
  );
};

export default NextGiftCardBlock;
