import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserController from "@api/controller/userController";
import VerificationCodeController from "@api/controller/verificationCodeController";
import { VerificationAction } from "@api/interfaces/verificationCodeLayout";
import { validation } from "@base/components/Form/helpers";
import PasswordField from "@base/components/Form/PasswordField";
import { Button, H5, P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import SimpleModalWrapper from "@base/components/Modal/SimpleModalWrapper";
import { PASSWORD_ICON } from "@constants/blobIcons";
import {
  Action,
  ErrorType,
  NavigationPath,
  VerificationCode,
} from "@constants/navigation";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { verifyVerificationCode } from "@pages/VerificationCodeErrorPage/helper/VerificationCodeHelper";
import { createUseStyles } from "@theme";
import * as yup from "yup";

const useStyles = createUseStyles(({ sizes, spacing }) => ({
  form: {
    width: "100%",
    [sizes.md]: {
      marginTop: spacing.x4l,
    },
  },
}));

const validationSchema = yup
  .object({
    password: validation.password,
    passwordVerify: validation.repeat("password"),
  })
  .required();

type FormType = {
  password: string;
  passwordVerify: string;
};

const ResetPasswordPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentModal, setCurrentModal] = useState<"success" | "error">();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm<FormType>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    setCurrentModal(undefined);
    if (currentModal === "success") {
      navigate(NavigationPath.Home);
    }
  };

  const hasError = (inputName: keyof FormType) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  const onSubmit = async (data: FormType) => {
    setIsSubmitting(true);
    const verificationCode = searchParams.get(VerificationCode);

    if (!verificationCode) {
      setCurrentModal("error");
      return;
    }

    const { isResponseOk } = await UserController.resetPassword({
      body: {
        password: data.password,
        verificationCode: verificationCode,
      },
    });
    if (isResponseOk) {
      await VerificationCodeController.expireCode({
        body: { code: verificationCode },
      });
    }
    setCurrentModal(isResponseOk ? "success" : "error");
    setIsSubmitting(false);
  };

  useEffect(() => {
    const searchVerificationCode = searchParams.get(VerificationCode);
    void verifyVerificationCode(searchVerificationCode).then(
      ({ isCodeValid, isCodeUsed, status }) => {
        if (isCodeUsed) {
          navigate(NavigationPath.Home);
          return;
        }
        if (!isCodeValid) {
          navigate({
            pathname: NavigationPath.VerificationCodeErrorPage,
            search: `${Action}=${VerificationAction.PASSWORD_RESET}&${ErrorType}=${status}`,
          });
        }
      },
    );
  }, [searchParams]);

  return (
    <>
      <Row>
        <Col fullWidth noMargin alignCenter>
          <Col colMd={9} directionColumn noMargin>
            <Col noMarginBottom>
              <H5 prefixIconUrl={PASSWORD_ICON} gray1>
                {t("reset_password_verify_page_title")}
              </H5>
            </Col>
            <Col noMarginTop>
              <P noMarginTop small gray3>
                {t("reset_password_verify_page_subtitle")}
              </P>
            </Col>
            <Col fullWidth>
              <P>{t("reset_password_verify_page_content")}</P>
            </Col>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <Col fullWidth colMd={6} col={12}>
                <PasswordField
                  label={t("field_new_password_placeholder")}
                  id="new-password-input"
                  hasError={hasError("password")}
                  onEnter={{ trigger, name: "passwordVerify" }}
                  placeholder={t("field_new_password_placeholder")}
                  helperText={
                    Boolean(hasError("password")) &&
                    t("field_password_error_not_valid")
                  }
                  {...register("password")}
                />
              </Col>
              <Col fullWidth colMd={6} col={12}>
                <PasswordField
                  label={t("field_verify_new_password_placeholder")}
                  id="verify-password-input"
                  hasError={hasError("passwordVerify")}
                  submitOnEnter
                  placeholder={t("field_verify_new_password_placeholder")}
                  helperText={
                    Boolean(hasError("passwordVerify")) &&
                    t("field_password_match_error")
                  }
                  {...register("passwordVerify")}
                />
              </Col>
              <Col fullWidth colMd={6} col={12}>
                <Button type="submit" isLoading={isSubmitting}>
                  {t("reset_password_verify_page_submit_btn")}
                </Button>
              </Col>
            </form>
          </Col>
        </Col>
      </Row>
      <SimpleModalWrapper
        isOpen={currentModal === "success"}
        title={t("reset_password_verify_page_success_modal_title")}
        onClose={onModalClose}
        content={t("reset_password_verify_page_success_modal_content")}
      />
      <SimpleModalWrapper
        isOpen={currentModal === "error"}
        title={t("reset_password_verify_page_error_modal_title")}
        onClose={onModalClose}
        content={t("reset_password_verify_page_error_modal_content")}
      />
    </>
  );
};

export default ResetPasswordPage;
