import { ApiRequest, ApiRequestOptions, RequestResponse } from "@api/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/slices";

import { selectCurrentLanguage } from "./language/selectors";
import { selectCurrentRegionPrefix } from "./region/selectors";
import {
  ApiGetStateMachine,
  AppAsyncThunkConfig,
  FailedPendingState,
  FailedState,
  IdleState,
  PendingState,
  StalePendingState,
  StaleState,
  StateMachine,
  SucceededState,
} from "./types";

export const hasData = (
  value: StateMachine,
): value is StaleState | StalePendingState | SucceededState => {
  return (
    value.status === "stale" ||
    value.status === "stalePending" ||
    value.status === "succeeded"
  );
};

export const hasFailedData = (
  value: StateMachine,
): value is FailedPendingState | FailedState => {
  return value.status === "failedPending" || value.status === "failed";
};

export const shouldLoad = (
  value: StateMachine,
): value is IdleState | StaleState | FailedState => {
  return (
    value.status === "idle" ||
    value.status === "stale" ||
    (value.status === "failed" && value.retryCount < 3)
  );
};

export const setStateInitial = <TData>(
  state: ApiGetStateMachine<TData>,
  applyNextState: (nextState: StaleState<TData> | IdleState) => void,
  useStale = true,
): void => {
  if (useStale && hasData(state)) {
    applyNextState({
      ...state,
      status: "stale",
    });
  } else {
    applyNextState({
      status: "idle",
    });
  }
};

export const setStatePending = <TData>(
  currentState: ApiGetStateMachine<TData>,
  applyNextState: (
    nextState: FailedPendingState | StalePendingState<TData> | PendingState,
  ) => void,
  useStale = true,
): void => {
  if (useStale && hasFailedData(currentState)) {
    applyNextState({
      ...currentState,
      status: "failedPending",
    });
  } else if (useStale && hasData(currentState)) {
    applyNextState({
      ...currentState,
      status: "stalePending",
    });
  } else {
    applyNextState({
      status: "pending",
    });
  }
};

export const setStateFailed = (
  currentState: StateMachine,
  applyNextState: (nextState: FailedState) => void,
): void => {
  if (hasFailedData(currentState)) {
    applyNextState({
      status: "failed",
      retryCount: currentState.retryCount + 1,
    });
  } else {
    applyNextState({
      status: "failed",
      retryCount: 0,
    });
  }
};

export const isLoading = (
  value: StateMachine,
): value is PendingState | StalePendingState | FailedPendingState => {
  return (
    value.status === "pending" ||
    value.status === "stalePending" ||
    value.status === "failedPending"
  );
};

export const createApiGetAsyncThunk = <T>(
  type: string,
  fetchFunction: ApiRequest<RequestResponse<T>, void>,
  selectFunction: (state: RootState) => StateMachine,
) => {
  return createAsyncThunk<
    T,
    ApiRequestOptions | undefined,
    AppAsyncThunkConfig
  >(
    type,
    async (arg, { rejectWithValue, getState, signal }) => {
      const { language, region } = arg || {};

      const response = await fetchFunction({
        body: undefined,
        signal,
        region: region || selectCurrentRegionPrefix(getState()),
        language: language || selectCurrentLanguage(getState()),
      });

      if (response && response.isResponseOk) {
        return response.response;
      }

      return rejectWithValue(response);
    },
    {
      condition: (_, { getState }) => {
        const stateMachine = selectFunction(getState());
        if (isLoading(stateMachine)) {
          if (process.env.NODE_ENV === "development") {
            console.log(
              "%cCancelled action %s because it was already loading.",
              "color:red",
              type,
              stateMachine,
            );
          }
          return false;
        }

        return;
      },
    },
  );
};
