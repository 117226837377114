import { isPresent } from "@apl-digital/utils";
import {
  clearSessionStorage,
  getSessionStorage,
  SessionStorageKey,
  setSessionStorage,
} from "@base/utils/storage";
import { AppStartListening } from "@store/listenerMiddleware";
import i18n from "@translation";

import { selectCurrentLanguage } from "./selectors";

export const addLanguageListeners = (startListening: AppStartListening) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      currentState.language.languageOverride !==
      previousState.language.languageOverride,

    effect: (_action, { getState }) => {
      const languageOverride = getState().language.languageOverride;

      if (isPresent(languageOverride)) {
        setSessionStorage(
          SessionStorageKey.LANGUAGE_OVERRIDE,
          languageOverride,
        );
      } else {
        clearSessionStorage(SessionStorageKey.LANGUAGE_OVERRIDE);
      }
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      selectCurrentLanguage(previousState) !==
      selectCurrentLanguage(currentState),

    effect: async (_action, { getState }) => {
      const currentLanguage = selectCurrentLanguage(getState());

      if (i18n.language !== currentLanguage) {
        if (i18n.isInitialized) {
          await i18n.changeLanguage(currentLanguage);
        } else {
          i18n.on("initialized", () => {
            void i18n.changeLanguage(currentLanguage);
          });
        }
      }
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      selectCurrentLanguage(previousState) !==
        selectCurrentLanguage(currentState) ||
      (isPresent(selectCurrentLanguage(currentState)) &&
        !isPresent(getSessionStorage(SessionStorageKey.LANGUAGE_CURRENT))),

    effect: (_action, { getState }) => {
      const currentLanguage = selectCurrentLanguage(getState());

      if (isPresent(currentLanguage)) {
        setSessionStorage(SessionStorageKey.LANGUAGE_CURRENT, currentLanguage);
      } else {
        clearSessionStorage(SessionStorageKey.LANGUAGE_CURRENT);
      }
    },
  });
};
