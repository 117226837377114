import React, { useState } from "react";
import BurgerMenu from "@base/components/BurgerMenu";
import TabBar from "@base/components/TabBar";
import { INavigationPath } from "@constants/navigation";
import { selectMenuItems } from "@store/cms";
import { useAppSelector } from "@store/hooks";
import { createUseStyles } from "@theme";
import TopBar from "src/base/components/TopBar";

import Footer from "../Footer";
import { PAGE_MARGIN_BOTTOM } from "../Layout/constants";

const useStyles = createUseStyles({
  appContainer: {
    paddingBottom: PAGE_MARGIN_BOTTOM,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const GenericRouteLayout: React.FC<{
  children: React.ReactNode;
  showTabBar?: boolean;
  showFooter?: boolean;
  showTopNavbar?: boolean;
  showNavigationToHome?: boolean;
  backPage?: INavigationPath;
}> = ({
  children,
  showTabBar = true,
  showFooter = true,
  showTopNavbar = true,
  showNavigationToHome,
  backPage,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);
  const openMenu = () => setIsMenuOpen(true);
  const classes = useStyles();
  const menuItems = useAppSelector(selectMenuItems);

  return (
    <>
      {showTopNavbar && (
        <TopBar
          isMenuOpen={isMenuOpen}
          openMenu={openMenu}
          backPage={backPage}
          showNavigationToHome={showNavigationToHome}
        />
      )}
      <div className={classes.appContainer}>{children}</div>
      {showFooter && <Footer />}
      {showTabBar && !isMenuOpen && (
        <TabBar openMenu={() => setIsMenuOpen(!isMenuOpen)} />
      )}

      <BurgerMenu
        isMenuOpen={isMenuOpen}
        closeMenu={closeMenu}
        openMenu={openMenu}
        menuItems={menuItems}
      />
    </>
  );
};

export default GenericRouteLayout;
