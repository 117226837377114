import React, { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { LayoutWrapper, Loader } from "@base/components/Global";
import MediaQueryProvider from "@base/components/MediaQueryProvider";
import UserProvider from "@base/components/UserProvider";
import {
  addNewError,
  emptyErrors,
  ERROR_MESSAGES,
  getErrors,
} from "@base/utils/errors";
import ErrorPage from "@pages/ErrorPage/ErrorPage";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { fetchRegions } from "@store/region";
import { shouldLoad } from "@store/utils";

const RootProvider: React.FC = () => {
  const dispatch = useAppDispatch();
  const regions = useAppSelector((state) => state.region.regions);
  const { showBoundary } = useErrorBoundary();
  const [hasError, setError] = useState<boolean>(false);

  useEffect(() => {
    emptyErrors();
    window.addEventListener("message", (e) => {
      if (e.data === ERROR_MESSAGES) {
        const errors = getErrors(e);
        if (errors?.length) {
          errors?.map((err: string) => {
            showBoundary(err);
          });
          setError(true);
        }
      }
    });

    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      addNewError(event.reason.message);
    };
  }, []);

  useEffect(() => {
    if (shouldLoad(regions)) {
      void dispatch(fetchRegions());
    }
  }, [regions]);

  if (
    regions.status === "idle" ||
    regions.status === "pending" ||
    regions.status === "failedPending"
  ) {
    return <Loader />;
  }

  if (regions.status === "failed" || hasError) {
    return <ErrorPage />;
  }

  return (
    <>
      <MediaQueryProvider>
        <LayoutWrapper>
          <UserProvider>
            <Outlet />
          </UserProvider>
        </LayoutWrapper>
      </MediaQueryProvider>
      <ScrollRestoration />
    </>
  );
};

export default RootProvider;
