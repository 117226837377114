import React, { useEffect, useRef, useState } from "react";
import { IGiftCard } from "@api/interfaces/giftcardsLayout";
import { IPerk } from "@api/interfaces/perkLayouts";
import { isPresent } from "@apl-digital/utils";
import { Col, Row, RowBreak } from "@base/components/Layout";
import PerkDetails from "@pages/PerkPage/components/PerkDetails";
import PerkScrollButtons from "@pages/PerkPage/components/PerkScrollButtons";
import { createUseStyles } from "@theme";
import classnames from "classnames";

import { fetchPerkByType, getBarcodeField } from "../helpers/perkHelper";

const useStyles = createUseStyles(({ spacing, borderRadius }) => ({
  container: {
    overflowX: "hidden",
    display: "flex",
    alignSelf: "center",
    alignItems: "unset",
  },
  detailsContainer: {
    alignSelf: "start",
    alignItems: "start",
  },
  scrollContainer: {
    overflowX: "hidden",
    paddingTop: "55px",
  },
  imgContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: spacing.xxs,
    overflow: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    scrollSnapType: "x mandatory",
    paddingLeft: "100%",
    paddingRight: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  img: {
    cursor: "pointer",
    scrollSnapAlign: "center",
    boxShadow: "0px 0px 15px rgba(130, 130, 130, 0.5)",
    borderRadius: borderRadius.m,
    margin: "15px",
  },
  activeImg: {
    cursor: "default",
  },
}));

type Props = {
  perks: IPerk[];
};

const PerkGroupDesktopView: React.FC<Props> = ({ perks }) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedPerk, setSelectedPerk] = useState<IGiftCard | IPerk>();
  const [selectedPerkBarcode, setSelectedPerkBarcode] = useState<string>();
  const selectedPerkCardRef = useRef<HTMLImageElement>();

  useEffect(() => {
    if (selectedPerkCardRef.current) {
      selectedPerkCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selectedPerkCardRef.current]);

  useEffect(() => {
    const currentPerk = perks.at(activeIndex);

    if (!isPresent(currentPerk)) {
      return;
    }

    const barcode = getBarcodeField(currentPerk);
    void fetchPerkByType(
      currentPerk.type,
      barcode,
      currentPerk.centralCrmCode,
    ).then((perk) => {
      if (perk) {
        setSelectedPerk(perk);
        setSelectedPerkBarcode(barcode);
      }
    });
  }, [activeIndex, perks]);

  return (
    <Row>
      <Col col={12}>
        <Col col={6} directionColumn className={classes.container}>
          <Row justifyCenter className={classes.scrollContainer}>
            <div className={classes.imgContainer}>
              {perks.map((perk, idx) => (
                <img
                  src={perk.portraitImage.url}
                  height={500}
                  width={300}
                  className={classnames([
                    classes.img,
                    activeIndex === idx && classes.activeImg,
                  ])}
                  key={perk.usageBarcode}
                  id={`perk-img-${idx}`}
                  ref={(el) => {
                    if (el && idx === activeIndex) {
                      selectedPerkCardRef.current = el;
                    }
                  }}
                  onClick={() => {
                    setActiveIndex(idx);
                  }}
                />
              ))}
            </div>
          </Row>

          {perks.length > 1 && (
            <Row>
              <RowBreak />
              <Col alignCenter>
                <PerkScrollButtons
                  activeIndex={activeIndex}
                  buttonCount={perks.length}
                  onChange={(idx) => setActiveIndex(idx)}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col col={6} className={classes.detailsContainer}>
          {selectedPerk && selectedPerkBarcode && (
            <PerkDetails
              perk={selectedPerk}
              perkType={perks[activeIndex].type}
              hideImage={true}
              barcode={selectedPerkBarcode}
            />
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default PerkGroupDesktopView;
