import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddressType, IAddress } from "@api/interfaces/userLayouts";
import EditIcon from "@assets/svg/edit.svg";
import { P } from "@base/components/Global";
import Link from "@base/components/Global/Link";
import { Col, Row } from "@base/components/Layout";
import {
  getBillingAddress,
  getOtherAddresses,
  getShippingAddress,
} from "@base/utils/userUtil";
import { NavigationPath } from "@constants/navigation";
import AddressBlockSingle from "@pages/ProfilePage/components/AddressBlockSingle";
import { useAppSelector } from "@store/hooks";
import { selectUserData } from "@store/user";

const AddressBlock: React.FC = () => {
  const { t } = useTranslation();
  const [billingAddress, setBillingAddress] = useState<IAddress>();
  const [shippingAddress, setShippingAddress] = useState<IAddress>();
  const [otherAddresses, setOtherAddresses] = useState<IAddress[]>();

  const userData = useAppSelector(selectUserData);

  useEffect(() => {
    if (userData) {
      setBillingAddress(getBillingAddress(userData));
      setShippingAddress(getShippingAddress(userData));
      setOtherAddresses(getOtherAddresses(userData));
    }
  }, [userData]);

  return (
    <Row noMargin>
      <AddressBlockSingle address={billingAddress} type={AddressType.BILLING} />
      <AddressBlockSingle
        address={shippingAddress}
        type={AddressType.SHIPPING}
      />
      <Col
        col={12}
        directionColumn
        noMarginTop
        hide={
          !shippingAddress && !billingAddress && otherAddresses?.length === 0
        }
      >
        <Link
          brandColor
          navigateTo={NavigationPath.Addresses}
          suffixIcon={<EditIcon />}
        >
          {t("edit_addresses")}
        </Link>
        <P gray3 noMarginTop>
          {t("address_count").replace(
            "%count%",
            String(
              userData?.addresses.filter((el) => el.types?.length !== 0)
                .length || 0,
            ),
          )}
        </P>
      </Col>
      <Col
        col={12}
        directionColumn
        hide={
          !!shippingAddress || !!billingAddress || otherAddresses?.length !== 0
        }
      >
        <Link brandColor navigateTo={NavigationPath.AddAddress}>
          {t("add_address")}
        </Link>
        {!otherAddresses && (
          <P gray3 noMarginTop>
            {t("no_addresses_on_account")}
          </P>
        )}
      </Col>
    </Row>
  );
};

export default AddressBlock;
