import React from "react";
import { ModalObject } from "@base/components/Form/EditFormPage/types";
import SimpleModalWrapper from "@base/components/Modal/SimpleModalWrapper";

type ModalArrayProps = {
  currentModal?: string | null;
  onClose: (currentModal: string) => void;
  modals: Record<string, ModalObject>;
};

const ModalArray: React.FC<ModalArrayProps> = ({
  currentModal,
  onClose,
  modals,
}) => {
  if (!currentModal || !Object.keys(modals).includes(currentModal)) return null;

  return (
    <SimpleModalWrapper
      isOpen
      onClose={() => onClose(currentModal)}
      {...modals[currentModal]}
    />
  );
};

export default ModalArray;
