import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AddFormProps } from "@base/components/Form/EditFormPage/types";
import { H5, P } from "@base/components/Global";
import { Col } from "@base/components/Layout";
import Row from "@base/components/Layout/Row";
import { NavigationPath } from "@constants/navigation";

const AddFormPage: React.FC<AddFormProps> = ({
  didReachMaxRow,
  form,
  children,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (didReachMaxRow) {
      navigate(NavigationPath.Profile);
    }
  }, []);

  return (
    <Row noMargin>
      <Col col={12} noMargin directionColumn alignCenter>
        <Col col={12} colMd={8} noHorizontalMargin>
          <Row>
            {!!form?.headingTitle && (
              <Col noVerticalMargin col={12}>
                <H5 prefixIconUrl={form.headingIconUrl} gray1>
                  {form.headingTitle}
                </H5>
              </Col>
            )}
            {!!form?.headingSubTitle && (
              <Col noMarginTop col={12}>
                <P noMarginTop small gray3>
                  {form.headingSubTitle}
                </P>
              </Col>
            )}
            {!!form?.content && (
              <Col col={12}>
                <P black>{form.content}</P>
              </Col>
            )}
            {children}
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default AddFormPage;
