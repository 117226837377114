import React from "react";
import { useTranslation } from "react-i18next";
import { ILocationsBlock } from "@api/interfaces/locationPageLayout";
import CloseIcon from "@assets/svg/close-btn.svg";
import { Button, P } from "@base/components/Global";
import H7 from "@base/components/Global/H6";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles(({ spacing }) => ({
  body: {
    paddingTop: spacing.l,
    display: "flex",
    flexDirection: "column",
  },
  closeButton: {
    position: "absolute",
    right: spacing.l,
    cursor: "pointer",
  },
  btn: {
    alignSelf: "flex-end",
    marginTop: spacing.xl,
  },
}));

const UrlCopiedModalContent: React.FC<{
  location: ILocationsBlock;
  onConfirm: () => void;
}> = ({ location, onConfirm }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.closeButton}>
        <CloseIcon onClick={onConfirm} />
      </div>
      <div className={classes.body}>
        <H7>{location.name}</H7>
        <H7
          noMarginTop
          noMarginBottom
        >{`${location.address}, ${location.cityName}`}</H7>
        <P>{t("locations_url_copied_body")}</P>
        <Button action={onConfirm} className={classes.btn}>
          {t("locations_url_copied_button_text")}
        </Button>
      </div>
    </>
  );
};

export default UrlCopiedModalContent;
