import React from "react";
import { Icon } from "@base/components/Global";
import { StyleProps } from "@base/components/Global/P";
import { createUseStyles, getTheme } from "@theme";
import classnames from "classnames";

const useStyles = createUseStyles<StyleProps>(({ spacing }) => ({
  suffixIcon: {
    marginLeft: spacing.s,
  },
}));

type SuffixIconProps = {
  className?: string;
  url?: string;
  icon?: React.ReactNode;
  isActive?: boolean;
};

const SuffixIcon: React.FC<SuffixIconProps> = ({
  url,
  icon,
  className,
  isActive = false,
}) => {
  const classes = useStyles();
  const { color } = getTheme();

  if (icon) {
    return <span className={classes.suffixIcon}>{icon}</span>;
  }

  return (
    <Icon
      url={url}
      className={classnames([classes.suffixIcon, className])}
      stroke={isActive ? color.primaryBrand : ""}
    />
  );
};

export default SuffixIcon;
