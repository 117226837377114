import React from "react";
import commonTheme from "@theme/common";
import { motion, SVGMotionProps } from "framer-motion";

import { draw } from "./common";

type FailureProps = {
  stroke?: SVGMotionProps<unknown>["stroke"];
};

export const Failure: React.FC<FailureProps> = ({
  stroke = commonTheme.color.error,
}) => {
  return (
    <motion.svg
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      initial="hidden"
      animate="visible"
      style={{ width: 150, height: 150 }}
    >
      <motion.circle
        cx={12}
        cy={12}
        r={11}
        stroke={stroke}
        strokeWidth={1}
        variants={draw}
        custom={0}
      />
      <motion.path
        d="M9 9L15 15"
        stroke={stroke}
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={draw}
        custom={1}
      />
      <motion.path
        d="M15 9L9 15"
        stroke={stroke}
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={draw}
        custom={2}
      />
    </motion.svg>
  );
};
