import config from "@constants/config";

const insertGTM = () => {
  const GTMCode = config.apiConfig.google.tagManager?.code;
  if (!GTMCode) return;
  const bodyInsertTag = document.querySelector("#gtm-body");

  const headCodeGTM =
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" +
    GTMCode +
    "');";

  const bodyCodeGTM =
    `
  <!-- Google Tag Manager (noscript) -->
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=` +
    GTMCode +
    `"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->`;

  const newScript = document.querySelector("script");
  const firstScript = document.querySelectorAll("script")[0];
  if (newScript && firstScript) {
    newScript.text = headCodeGTM;
    firstScript?.parentNode?.insertBefore(newScript, firstScript);
  }

  if (bodyInsertTag) {
    bodyInsertTag.innerHTML = bodyCodeGTM;
  }
};

export default insertGTM;
