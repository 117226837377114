import React from "react";
import { INavigationPath, NavigationPath } from "@constants/navigation";
import AddAddressPage from "@pages/Address/AddAddressPage";
import AddressesPage from "@pages/Address/AddressesPage";
import EditAddressPage from "@pages/Address/EditAddressPage";
import ApolloClubPage from "@pages/ApolloClub/ApolloClubPage";
import ApolloClubPromo from "@pages/ApolloClubPromo";
import AuthLandingPage from "@pages/AuthPage/AuthLandingPage";
import AuthStartPage from "@pages/AuthPage/AuthStartPage";
import ChangePasswordPage from "@pages/ChangePasswordPage/ChangePasswordPage";
import CloseAccount from "@pages/CloseAccount/CloseAccount";
import AddEmailPage from "@pages/Email/AddEmailPage";
import EmailsPage from "@pages/Email/EmailsPage";
import VerifyEmailPage from "@pages/Email/VerifyEmailPage";
import EmptyPage from "@pages/ErrorPage/EmptyPage";
import GiftCardPage from "@pages/GiftCardPage";
import GiftCardStatusDetailsPage from "@pages/GiftCardStatusPage/GiftCardStatusDetailsPage";
import GiftCardStatusPage from "@pages/GiftCardStatusPage/GiftCardStatusPage";
import Goodbye from "@pages/Goodbye/Goodbye";
import PrivateHomePage from "@pages/HomePage/components/PrivateHomePage";
import PublicHomePage from "@pages/HomePage/components/PublicHomePage";
import LocationPage from "@pages/LocationsPage";
import NewslettersPage from "@pages/Newsletters/NewslettersPage";
import PerkDetailsPage from "@pages/PerkPage/PerkDetailsPage";
import PerkGroupPage from "@pages/PerkPage/PerkGroupPage";
import EditPersonalData from "@pages/PersonalData/EditPersonalData";
import AddPhonePage from "@pages/Phone/AddPhonePage";
import PhonesPage from "@pages/Phone/PhonesPage";
import ProfilePage from "@pages/ProfilePage";
import EditProfilePage from "@pages/ProfilePage/EditProfilePage";
import RegionPage from "@pages/RegionPage";
import RequestResetPasswordPage from "@pages/ResetPasswordPage/RequestResetPasswordPage";
import ResetPasswordPage from "@pages/ResetPasswordPage/ResetPasswordPage";
import TermsAndConditionsPage from "@pages/TermsAndConditions";
import TransactionsPage from "@pages/Transactions/TransactionsPage";
import SignUpPageStart from "@pages/UserRegistration/BeginUserRegistrationPage";
import SignUpPageComplete from "@pages/UserRegistration/CompleteUserRegistrationPage";
import VerificationCodeErrorPage from "@pages/VerificationCodeErrorPage/VerificationCodeErrorPage";

type NavigationRoute = {
  path: string;
  isProtected?: boolean;
  showProfileBlock?: boolean;
  isProfileBlockMinified?: boolean;
  Component: React.FC;
  layoutProps?: {
    showTabBar?: boolean;
    showTopNavbar?: boolean;
    showFooter?: boolean;
    backPage?: INavigationPath;
    showNavigationToHome?: boolean;
  };
};

type NavigationRoutes = {
  public: NavigationRoute[];
  profile: NavigationRoute[];
  noRegion: NavigationRoute[];
  giftCard: NavigationRoute[];
};

// The AuthComplete route is needed to complete the authentication process, silent sign in included
const commonAuthRoutes: NavigationRoute[] = [
  {
    path: NavigationPath.AuthComplete,
    Component: AuthLandingPage,
    layoutProps: {
      showTabBar: false,
      showFooter: false,
    },
  },
  {
    path: NavigationPath.AuthStart,
    Component: AuthStartPage,
    layoutProps: {
      showTabBar: false,
      showFooter: false,
    },
  },
];

const fallbackRoute: NavigationRoute = {
  path: "*",
  Component: EmptyPage,
};

const passwordResetRoutes: NavigationRoute[] = [
  {
    path: NavigationPath.ResetPassword,
    Component: RequestResetPasswordPage,
    layoutProps: {
      showNavigationToHome: true,
    },
  },
  {
    path: NavigationPath.ResetPasswordVerify,
    Component: ResetPasswordPage,
    layoutProps: {
      showNavigationToHome: true,
    },
  },
];

const routes: NavigationRoutes = {
  public: [
    fallbackRoute,
    ...passwordResetRoutes,
    {
      path: NavigationPath.Home,
      Component: PublicHomePage,
    },
    {
      path: NavigationPath.ApolloClubPromo,
      Component: ApolloClubPromo,
    },
    {
      path: NavigationPath.Locations,
      Component: LocationPage,
    },
    {
      path: NavigationPath.SignUpStart,
      Component: SignUpPageStart,
    },
    {
      path: NavigationPath.SignUpComplete,
      Component: SignUpPageComplete,
    },
    {
      path: NavigationPath.VerificationCodeErrorPage,
      Component: VerificationCodeErrorPage,
    },
    {
      path: NavigationPath.Goodbye,
      Component: Goodbye,
    },
  ],
  profile: [
    ...commonAuthRoutes,
    fallbackRoute,
    {
      path: NavigationPath.ProfileResetPassword,
      Component: RequestResetPasswordPage,
      layoutProps: {
        backPage: "ChangePassword",
      },
    },
    {
      path: NavigationPath.ResetPasswordVerify,
      Component: ResetPasswordPage,
      layoutProps: {
        backPage: "Profile",
      },
    },
    {
      path: NavigationPath.Home,
      Component: PrivateHomePage,
      isProtected: true,
    },
    {
      path: NavigationPath.Locations,
      Component: LocationPage,
      isProtected: true,
    },
    {
      path: NavigationPath.ChangePassword,
      Component: ChangePasswordPage,
      layoutProps: {
        backPage: "Profile",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.Profile,
      Component: ProfilePage,
      showProfileBlock: true,
      isProtected: true,
    },
    {
      path: NavigationPath.EditProfile,
      Component: EditProfilePage,
      layoutProps: {
        backPage: "Profile",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.Terms,
      Component: TermsAndConditionsPage,
      isProtected: true,
      layoutProps: {
        showTabBar: false,
        showFooter: false,
      },
    },
    {
      path: NavigationPath.GiftCard,
      Component: GiftCardPage,
      showProfileBlock: true,
      isProfileBlockMinified: true,
      isProtected: true,
    },
    {
      path: NavigationPath.PerkGroupPage,
      Component: PerkGroupPage,
      showProfileBlock: true,
      isProfileBlockMinified: true,
      isProtected: true,
      layoutProps: {
        backPage: "GiftCard",
      },
    },
    {
      path: NavigationPath.MyApolloClub,
      Component: ApolloClubPage,
      showProfileBlock: true,
      isProfileBlockMinified: true,
      isProtected: true,
    },
    {
      path: NavigationPath.Emails,
      Component: EmailsPage,
      layoutProps: {
        backPage: "Profile",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.VerifyEmail,
      Component: VerifyEmailPage,
      layoutProps: {
        backPage: "Emails",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.AddEmail,
      Component: AddEmailPage,
      layoutProps: {
        backPage: "Emails",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.Phones,
      Component: PhonesPage,
      layoutProps: {
        backPage: "Profile",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.AddPhone,
      Component: AddPhonePage,
      layoutProps: {
        backPage: "Phones",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.Addresses,
      Component: AddressesPage,
      showProfileBlock: true,
      isProfileBlockMinified: true,
      layoutProps: {
        backPage: "Profile",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.EditAddress,
      Component: EditAddressPage,
      layoutProps: {
        backPage: "Addresses",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.AddAddress,
      Component: AddAddressPage,
      layoutProps: {
        backPage: "Addresses",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.CloseAccount,
      Component: CloseAccount,
      isProtected: true,
      layoutProps: {
        backPage: "Profile",
      },
    },
    {
      path: NavigationPath.EditPersonalData,
      Component: EditPersonalData,
      layoutProps: {
        backPage: "Profile",
      },
      isProtected: true,
    },
    {
      path: NavigationPath.Transactions,
      Component: TransactionsPage,
      showProfileBlock: true,
      isProfileBlockMinified: true,
      isProtected: true,
      layoutProps: {
        backPage: "Home",
      },
    },
    {
      path: NavigationPath.PerkDetailsPage,
      Component: PerkDetailsPage,
      isProtected: true,
    },
    {
      path: NavigationPath.Newsletter,
      Component: NewslettersPage,
      showProfileBlock: true,
      isProfileBlockMinified: true,
      isProtected: true,
    },
    {
      path: NavigationPath.VerificationCodeErrorPage,
      Component: VerificationCodeErrorPage,
      isProtected: true,
    },
  ],
  noRegion: [
    fallbackRoute,
    {
      path: NavigationPath.Home,
      Component: RegionPage,
    },
    {
      path: NavigationPath.Locations,
      Component: LocationPage,

      layoutProps: {
        showTabBar: false,
      },
    },
    {
      path: NavigationPath.Goodbye,
      Component: Goodbye,
    },
  ],
  giftCard: [
    ...commonAuthRoutes,
    fallbackRoute,
    {
      path: NavigationPath.GiftCardStatus,
      Component: GiftCardStatusPage,

      layoutProps: {
        showTabBar: false,
      },
    },
    {
      path: NavigationPath.GiftCardStatusDetails,
      Component: GiftCardStatusDetailsPage,

      layoutProps: {
        showTabBar: false,
        backPage: "GiftCardStatus",
      },
    },
  ],
};

export default routes;
