import React, { useState } from "react";
import { IBenefit } from "@api/interfaces/benefitLayouts";
import { Icon, P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import BenefitModal from "@pages/HomePage/components/BenefitsBlock/BenefitModal";
import { createUseStyles, getTheme } from "@theme";
import theme from "@theme/common";

const useStyles = createUseStyles(({ spacing, icon, color, borderRadius }) => ({
  block: {
    cursor: "pointer",
    display: "grid",
    rowGap: spacing.xxs + spacing.xs,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
  },
  iconCol: {
    display: "flex",
    marginRight: spacing.xxs + spacing.xs,
  },
  iconWrapper: {
    height: icon.xl,
    width: icon.xl,
    maxWidth: icon.xl,
    borderRadius: borderRadius.s,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  benefitInfo: {
    background: color.gray5,
    borderRadius: borderRadius.s,
    height: "100%",
    paddingLeft: spacing.l,
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  brandIcon: {
    width: "unset",
    height: "unset",
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
}));

const Benefit: React.FC<IBenefit> = ({
  slogan,
  primaryColor,
  iconURL,
  logoURL,
  description,
  name,
  businessSystemKey,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();
  const { color } = getTheme();

  if (!(slogan || primaryColor || iconURL || logoURL || businessSystemKey))
    return null;

  return (
    <Col col={12} noMargin>
      <Row noMargin>
        {(iconURL || primaryColor) && (
          <div className={classes.iconCol}>
            <span
              className={classes.iconWrapper}
              style={{ backgroundColor: primaryColor }}
            >
              <Icon url={iconURL} stroke={color.white} />
            </span>
          </div>
        )}

        {(slogan || logoURL) && (
          <>
            <BenefitModal
              isOpen={isModalOpen}
              title={name}
              onClose={() => setIsModalOpen(false)}
              description={description}
              color={primaryColor}
              iconURL={iconURL}
              logoURL={logoURL}
              slogan={slogan}
              businessSystemKey={businessSystemKey}
            />
            <Col noMargin>
              <Row
                noMargin
                className={classes.benefitInfo}
                onClick={() => setIsModalOpen(true)}
              >
                {slogan && (
                  <P noMargin small>
                    {slogan}
                  </P>
                )}
                {logoURL && (
                  <Icon
                    url={logoURL}
                    fill={theme.color.gray1}
                    className={classes.brandIcon}
                  />
                )}
              </Row>
            </Col>
          </>
        )}
      </Row>
    </Col>
  );
};

type BenefitsProps = {
  benefits?: IBenefit[];
};

const Benefits: React.FC<BenefitsProps> = ({ benefits }) => {
  const classes = useStyles();

  if (!benefits || benefits.length === 0) return null;

  return (
    <Row className={classes.block} noMargin>
      {benefits.map((benefit) => (
        <Benefit
          key={`benefit-${benefit.slogan}-${benefit.externalID}`}
          {...benefit}
        />
      ))}
    </Row>
  );
};

export default Benefits;
