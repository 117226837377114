import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactStatus } from "@api/interfaces/userLayouts";
import CheckboxRow from "@base/components/Form/CheckboxRow";
import TextField from "@base/components/Form/TextField";
import { Button, P } from "@base/components/Global";
import { Col } from "@base/components/Layout";
import Row from "@base/components/Layout/Row";
import ConfirmationModal from "@base/components/Modal/ConfirmationModal";
import { VerifyEmailButton } from "@base/components/VerifyEmailButton/VerifyEmailButton";
import useOnClickOutside from "@base/hooks/useOnClickOutside";
import { isContactVerified, validateEmail } from "@base/utils/userUtil";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@constants/googleAnalytics";
import { InputAdornment } from "@mui/material";
import { createUseStyles } from "@theme";
import classNames from "classnames";

const useStyles = createUseStyles(({ spacing, color }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  notVerifiedField: {
    "& .MuiInputBase-input": {
      color: `${color.gray3} !important`,
    },
    "& .MuiFormHelperText-root": {
      color: `${color.gray4} !important`,
    },
  },
}));

type EmailFieldType = {
  id: number;
  value: string;
  label: string;
  isDisabled?: boolean;
  saveEmail?: (email: string, isPrimary: boolean) => void;
  deleteEmail?: () => void;
  emailStatus: ContactStatus;
};

const EmailField: React.FC<EmailFieldType> = ({
  id,
  value,
  label,
  isDisabled,
  saveEmail,
  deleteEmail,
  emailStatus,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(value);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [shouldShowErrors, setShowErrors] = useState(false);
  const [shouldShowDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDirty = value !== currentEmail;
  const helperTextCondition = isDisabled
    ? undefined
    : t("field_email_helper_text");

  const hasCurrentEmailError = Boolean(
    shouldShowErrors && !validateEmail(currentEmail),
  );
  const hasConfirmEmailError = Boolean(
    shouldShowErrors && currentEmail !== confirmEmail,
  );

  const onSubmit = () => {
    setShowErrors(true);
    setIsSubmitting(true);
    const isEmailValid = validateEmail(currentEmail);
    const isConfirmEmailValid = isPrimary
      ? true
      : currentEmail === confirmEmail;
    if (isEmailValid && isConfirmEmailValid && saveEmail) {
      setIsActive(false);
      setShowErrors(false);
      saveEmail(currentEmail, isPrimary);
    }
    setIsSubmitting(false);
  };

  useOnClickOutside(inputRef, () => {
    setIsActive(false);
  });

  const shouldShowControls =
    !isDisabled && (isActive || value !== currentEmail || isPrimary);

  const shouldShowCheckbox = value === currentEmail;

  const canSetEmailAsPrimary = emailStatus === ContactStatus.ACTIVE;

  const canVerifyEmail =
    !isContactVerified(emailStatus) &&
    !isDirty &&
    !hasCurrentEmailError &&
    !hasConfirmEmailError;

  const canEditEmail = !isContactVerified(emailStatus);

  const endAdornament = canVerifyEmail ? (
    <InputAdornment position="end">
      <VerifyEmailButton
        email={{
          id,
          email: currentEmail,
          status: emailStatus,
          isPrimary,
        }}
        analyticsAttributes={
          GOOGLE_ANALYTICS_ATTRIBUTES.VERIFY_EMAIL_EMAILS_LIST
        }
      />
    </InputAdornment>
  ) : null;

  return (
    <>
      <ConfirmationModal
        isOpen={shouldShowDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t("email_delete_confirmation_title")}
        content={t("email_delete_confirmation_content")}
        primaryAction={() => {
          if (deleteEmail) {
            deleteEmail();
          }
        }}
        primaryTitle={t("email_delete_button")}
      />
      <div
        onClick={() => setIsActive(true)}
        ref={inputRef}
        className={classes.container}
      >
        <Row>
          <TextField
            label={label}
            id="email-input"
            autoComplete="email"
            isDisabled={isDisabled}
            isReadOnly={!canEditEmail}
            placeholder={t("field_email_placeholder")}
            value={currentEmail}
            onChange={(e) => setCurrentEmail(e.target.value)}
            hasError={hasCurrentEmailError}
            helperText={
              hasCurrentEmailError ? t("email_not_valid") : helperTextCondition
            }
            endAdornment={endAdornament}
            className={classNames([canEditEmail && classes.notVerifiedField])}
          />
        </Row>
        {isDirty && (
          <Row>
            <TextField
              label={t("field_edit_repeat_email_label")}
              id="confirm-email-input"
              autoComplete="email"
              placeholder={t("field_email_placeholder")}
              hasError={hasConfirmEmailError}
              helperText={hasConfirmEmailError && t("emails_dont_match")}
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              className={classNames([canEditEmail && classes.notVerifiedField])}
            />
          </Row>
        )}
        {shouldShowControls && shouldShowCheckbox && canSetEmailAsPrimary && (
          <Col col={12} directionColumn noVerticalMargin>
            <CheckboxRow
              label={t("checkbox_email_use_as_primary")}
              bold={false}
              slim
              small
              noBorder
              checked={isPrimary}
              onChange={() => setIsPrimary(!isPrimary)}
            />
            <P xxSmall gray3 noMargin>
              {t("checkbox_email_helper_text")}
            </P>
          </Col>
        )}
        {shouldShowControls && (
          <Row noMargin>
            <Col col={6}>
              {deleteEmail && (
                <Button
                  transparentBrand
                  action={() => setShowDeleteModal(true)}
                >
                  {t("delete_email_btn")}
                </Button>
              )}
            </Col>
            <Col col={6}>
              <Button
                isLoading={isSubmitting}
                action={onSubmit}
                isDisabled={
                  isPrimary
                    ? false
                    : !isDirty || hasCurrentEmailError || hasConfirmEmailError
                }
                analyticsAttributes={GOOGLE_ANALYTICS_ATTRIBUTES.EMAIL_CHANGE}
              >
                {t("action_save")}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default EmailField;
