import { DEFAULT_TERRITORY_ID } from "@api/constants";
import { IUserBenefit } from "@api/interfaces/userBenefitLayout";

type BenefitSortType = {
  extID: string;
  order: number;
};

export const benefitOrders: BenefitSortType[] = [
  {
    extID: "BenefitPlan.ApolloClub.Global.BKK5",
    order: 1,
  },
  {
    extID: "BenefitPlan.ApolloKino.EE",
    order: 2,
  },
  {
    extID: "BenefitPlan.Vapiano.EE",
    order: 3,
  },
  {
    extID: "BenefitPlan.OLearys.EE",
    order: 4,
  },
  {
    extID: "BenefitPlan.MySushi.EE",
    order: 5,
  },
  {
    extID: "BenefitPlan.ApolloKohvikud.Blender.EE",
    order: 6,
  },
  {
    extID: "BenefitPlan.ApolloKohvikud.IceCafe.EE",
    order: 7,
  },
];

const sortBenefits = (current: IUserBenefit, next: IUserBenefit): 1 | -1 => {
  if (current.territoryId === DEFAULT_TERRITORY_ID) return -1;
  if (next.territoryId === DEFAULT_TERRITORY_ID) return 1;
  if (current.currentValue > next.currentValue) {
    return -1;
  }
  if (next.currentValue === 0) {
    const currentBenefit = benefitOrders.find(
      (e) => e.extID === current.externalIdentifier,
    );
    const nextBenefit = benefitOrders.find(
      (e) => e.extID === next.externalIdentifier,
    );

    if (nextBenefit && currentBenefit) {
      return currentBenefit.order > nextBenefit.order ? 1 : -1;
    }
  }
  return 1;
};

export { sortBenefits };
