import React from "react";
import { PrimarySecondaryFormProps } from "@base/components/Form/EditFormPage/types";
import { P } from "@base/components/Global";
import { Col } from "@base/components/Layout";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles(({ spacing }) => ({
  groupContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xl,
    width: "100%",
  },
}));

const PrimarySecondaryForm: React.FC<PrimarySecondaryFormProps> = ({
  fieldsText,
  fields,
  fieldComponent,
}) => {
  const classes = useStyles();
  const primaryIndex = fields.findIndex((field) => field.isPrimary);

  return (
    <>
      {Boolean(fieldsText?.primaryTitle) && fields.some((f) => f.isPrimary) && (
        <Col col={12}>
          <P noMarginBottom black bold>
            {fieldsText.primaryTitle}
          </P>
        </Col>
      )}
      {primaryIndex !== -1 && (
        <div className={classes.groupContainer}>
          {fieldComponent({
            index: primaryIndex,
            label: fieldsText.primaryLabel,
            isDisabled: true,
          })}
        </div>
      )}

      {fields.length > 1 && !!fieldsText?.secondaryTitle && (
        <Col col={12}>
          <P noVerticalMargin black bold>
            {fieldsText.secondaryTitle}
          </P>
        </Col>
      )}

      <div className={classes.groupContainer}>
        {fields.map(
          (field, index) =>
            !field.isPrimary && (
              <div key={fields[index].id}>
                {fieldComponent({
                  index,
                  label: fieldsText.secondaryLabel,
                  isDisabled: false,
                })}
              </div>
            ),
        )}
      </div>
    </>
  );
};

export default PrimarySecondaryForm;
