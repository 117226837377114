import { IRegionSchema, LanguageSchema } from "@api/interfaces/countriesLayout";
import { YupStorage } from "@apl-digital/yup-storage";
import config from "@constants/config";
import { array, boolean, number, string } from "yup";

export enum LocalStorageKey {
  ERRORS_TIMESTAMPS = "ERRORS_TIMESTAMPS",
  ERRORS = "ERRORS",
  REGIONS = "REGIONS",
  REGION_MAIN_DOMAIN = "REGION_MAIN_DOMAIN",
  REGISTRATION_FIRST_NAME = "REGISTRATION_FIRST_NAME",
  HAS_TRIED_SILENT_AUTH = "HAS_TRIED_SILENT_AUTH",
}

const LocalStorageSchemas = {
  [LocalStorageKey.ERRORS_TIMESTAMPS]: string().required(),

  [LocalStorageKey.ERRORS]: string().required(),
  [LocalStorageKey.REGIONS]: array(IRegionSchema).required(),
  [LocalStorageKey.REGISTRATION_FIRST_NAME]: string().required(),
  [LocalStorageKey.HAS_TRIED_SILENT_AUTH]: boolean().required(),
} as const;

export const { clearStorage, getStorage, setStorage } = new YupStorage(
  LocalStorageSchemas,
  { name: "YupLocalStorage", storage: window.localStorage },
  config.devFlags.storageDebug ? console : undefined,
);

export enum SessionStorageKey {
  RETRY_COUNTER_REFRESH = "RETRY_COUNTER_REFRESH",
  REGION_CURRENT = "REGION_CURRENT",
  REGION_OVERRIDE = "REGION_OVERRIDE",
  LANGUAGE_CURRENT = "LANGUAGE_CURRENT",
  LANGUAGE_OVERRIDE = "LANGUAGE_OVERRIDE",
  AUTH_PREV_PAGE = "AUTH_PREV_PAGE",
  PAGES_HISTORY = "PAGES_HISTORY",
  USER = "loyalty.portal.user", // Format is determined by oidc-client-ts library
  SENTRY_REPLAY_SESSION = "sentryReplaySession", // Managed by sentry
}

const SessionStorageSchemas = {
  [SessionStorageKey.RETRY_COUNTER_REFRESH]: number().required(),
  [SessionStorageKey.REGION_CURRENT]: string().required(),
  [SessionStorageKey.REGION_OVERRIDE]: string().required(),
  [SessionStorageKey.LANGUAGE_CURRENT]: LanguageSchema.required(),
  [SessionStorageKey.LANGUAGE_OVERRIDE]: LanguageSchema.required(),
  [SessionStorageKey.AUTH_PREV_PAGE]: string().required(),
  [SessionStorageKey.PAGES_HISTORY]: array(string()).required(),
};

export const {
  clearStorage: clearSessionStorage,
  getStorage: getSessionStorage,
  setStorage: setSessionStorage,
} = new YupStorage(
  SessionStorageSchemas,
  { name: "YupSessionStorage", storage: window.sessionStorage },
  config.devFlags.storageDebug ? console : undefined,
);
