import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import IMenuItemLayout from "@api/interfaces/menuLayout";
import AccordionListProvider from "@base/components/AccordionList/AccordionListProvider";
import LanguagesMenu from "@base/components/BurgerMenu/components/LanguagesMenu";
import MenuHeader from "@base/components/BurgerMenu/components/MenuHeader";
import MenuItem from "@base/components/BurgerMenu/components/MenuItem";
import RegionsMenu from "@base/components/BurgerMenu/components/RegionsMenu";
import SimpleMenu from "@base/components/BurgerMenu/components/SimpleMenu";
import {
  filterMenuItems,
  isMenuItemDisabled,
} from "@base/components/BurgerMenu/helper/menuHelper";
import { Icon } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import GenericModal from "@base/components/Modal";
import ApolloClubCardModal from "@base/components/Modal/ApolloClubCardModal";
import getBurgerMenuItemNavigationPath from "@base/utils/navigationHelper";
import { MARKUS_BURGER_MENU_LOGO_ICON } from "@constants/blobIcons";
import { useAppSelector } from "@store/hooks";
import { selectUserData } from "@store/user";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles(({ color }) => ({
  container: {
    background: color.gray6,
    position: "relative",
    maxWidth: 400,
    minHeight: "100%",
    height: "100%",
  },
  innerContainer: {
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  markusLogoColumn: {
    justifyContent: "flex-end",
    alignSelf: "center",
  },
  markusLogo: {
    marginTop: 200,
    marginBottom: 60,
  },
}));

type BurgerMenuProps = {
  isMenuOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  menuItems: IMenuItemLayout[] | null;
};

const BurgerMenu: React.FC<BurgerMenuProps> = ({
  isMenuOpen,
  closeMenu,
  openMenu,
  menuItems,
}) => {
  const [shouldShowCardModal, setShouldShowCardModal] = useState(false);
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const userData = useAppSelector(selectUserData);

  if (shouldShowCardModal) {
    return (
      <ApolloClubCardModal
        isOpen={shouldShowCardModal}
        onClose={() => {
          setShouldShowCardModal(false);
          openMenu();
        }}
        onNavigate={() => setShouldShowCardModal(false)}
      />
    );
  }

  if (!menuItems) return null;

  return (
    <GenericModal isOpen={isMenuOpen} closeModal={closeMenu} isMenu>
      <Row className={classes.container} noMargin>
        <div className={classes.innerContainer}>
          <Col col={12} noMargin directionColumn>
            <MenuHeader
              closeMenu={closeMenu}
              setShouldShowCardModal={setShouldShowCardModal}
            />
            <AccordionListProvider>
              {filterMenuItems(menuItems, isAuthenticated).map((item) => {
                if (item.subItems && item.subItems.length > 0) {
                  return (
                    <SimpleMenu
                      item={item}
                      key={item.id}
                      closeMenu={closeMenu}
                      isDisabled={isMenuItemDisabled(
                        item.disabledWhenNotValidate,
                        userData?.termsOfServiceStatus,
                      )}
                    />
                  );
                } else if (item.action.path === "group_languages") {
                  return (
                    <LanguagesMenu
                      item={item}
                      key={item.id}
                      closeMenu={closeMenu}
                    />
                  );
                } else if (item.action.path === "group_region") {
                  return (
                    <RegionsMenu
                      item={item}
                      key={item.id}
                      closeMenu={closeMenu}
                    />
                  );
                } else {
                  return (
                    <MenuItem
                      navigateTo={getBurgerMenuItemNavigationPath(
                        item.action.path,
                        item.action.params,
                      )}
                      iconUrl={item.iconURL}
                      border
                      key={item.id}
                      closeMenu={closeMenu}
                      isDisabled={isMenuItemDisabled(
                        item.disabledWhenNotValidate,
                        userData?.termsOfServiceStatus,
                      )}
                      num={item.notificationsCount}
                    >
                      {item.title}
                    </MenuItem>
                  );
                }
              })}
            </AccordionListProvider>
          </Col>
          <Col col={12} directionColumn className={classes.markusLogoColumn}>
            <Icon
              url={MARKUS_BURGER_MENU_LOGO_ICON}
              height={46}
              width={130}
              className={classes.markusLogo}
            />
          </Col>
        </div>
      </Row>
    </GenericModal>
  );
};

export default BurgerMenu;
