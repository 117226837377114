import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from "@reduxjs/toolkit";
import { AppDispatch } from "@store/store";

import { addCMSListeners } from "./cms/listeners";
import { addLanguageListeners } from "./language/listeners";
import { addPerksListeners } from "./perks/listeners";
import { addRegionListeners } from "./region/listeners";
import { addUserListeners } from "./user/listeners";
import { RootState } from "./slices";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

export default listenerMiddleware.middleware;

addRegionListeners(startAppListening);
addCMSListeners(startAppListening);
addUserListeners(startAppListening);
addPerksListeners(startAppListening);
addLanguageListeners(startAppListening);
