import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { IPhone } from "@api/interfaces/userLayouts";
import EditFormPage from "@base/components/Form/EditFormPage";
import PrimarySecondaryForm from "@base/components/Form/EditFormPage/PrimarySecondaryForm";
import { FieldTypePrimarySecondaryForm } from "@base/components/Form/EditFormPage/types";
import ModalArray from "@base/components/Modal/ModalArray";
import { PHONE_ICON } from "@constants/blobIcons";
import config from "@constants/config";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@constants/googleAnalytics";
import { NavigationPath } from "@constants/navigation";
import EditPhoneField from "@pages/Phone/EditPhoneField";
import { PhoneFormType, PhoneValue } from "@pages/Phone/types";
import { useAppSelector } from "@store/hooks";
import { selectUserData } from "@store/user";
import { useUser } from "src/base/components/UserProvider";

const PhonesPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const [{ removePhone, updatePhone, setPrimaryPhone }] = useUser();
  const [isLoaded, setIsLoaded] = useState(false);
  const { isLoading } = useAuth();
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  const { control } = useForm<PhoneFormType>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "phones",
  });

  useEffect(() => {
    // Initial load when we have no fields
    if (
      fields?.length === 0 &&
      userData?.phones &&
      userData.phones.length > 0 &&
      !isLoaded
    ) {
      append(
        userData.phones.map((phone) => ({
          // Using key here as ID is a reserved name and get's and autogenerated value
          key: phone.id,
          value: phone.number,
          isPrimary: phone.isPrimary,
        })),
      );
      setIsLoaded(true);
    }

    if (!isLoading && userData?.phones && userData.phones.length === 0) {
      // Not loading & have no data to edit
      navigate(NavigationPath.Profile);
    }
  }, [userData]);

  const updateValue = async (
    index: number,
    newValue: string,
    isPrimary: boolean,
  ) => {
    const id = fields[index].key;

    if (!id) return;

    const phone: IPhone = {
      id,
      number: newValue,
      isPrimary,
    };
    const isSuccess = await updatePhone(phone);
    const isSuccessPrimary = isPrimary ? await setPrimaryPhone(id) : true;
    if (isSuccess && isSuccessPrimary) {
      if (isPrimary) {
        for (const [idx, field] of fields.entries()) {
          if (field.isPrimary) {
            update(idx, {
              ...field,
              isPrimary: false,
            });
          }
        }
      }

      update(index, {
        id,
        key: id,
        value: newValue,
        isPrimary,
      });

      setCurrentModal(isPrimary ? "primarySuccessModal" : "editSuccessModal");
    } else {
      setCurrentModal("errorModal");
    }
  };

  const deleteValue = async (index: number) => {
    const phone: PhoneValue = fields[index];
    if (!phone.key) return;
    const isSuccess = await removePhone(phone.key);
    if (isSuccess) {
      remove(index);
    }
  };

  const fieldsArray = [...fields] as FieldTypePrimarySecondaryForm[];

  return (
    <EditFormPage
      isLoading={!fields || fields.length === 0}
      form={{
        headingIconUrl: PHONE_ICON,
        headingTitle: t("edit_phone_header"),
        headingSubTitle: t("edit_phone_subtitle"),
        content: t("edit_phone_helper_text"),
        addRow: t("add_phone"),
        addRowAction: () => navigate(NavigationPath.AddPhone),
        hasMaxRowsReached: fieldsArray.length >= config.appConfig.maxPhoneCount,
        maxRowsReachedText: t("max_phones_reached"),
        goBackText: t("go_to_profile"),
        goBackAction: () => navigate(NavigationPath.Profile),
      }}
    >
      <ModalArray
        modals={{
          primarySuccessModal: {
            title: t("modal_primary_phone_update_title"),
            subTitle: t("modal_primary_phone_update_subtitle"),
            content: t("modal_primary_phone_update_content"),
          },
          editSuccessModal: {
            title: t("modal_phone_edited_title"),
            subTitle: t("modal_phone_edited_subtitle"),
            content: t("modal_phone_edited_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PHONE_CHANGE_SUCCESS,
          },
          errorModal: {
            title: t("modal_phone_error_title"),
            subTitle: t("modal_phone_error_subtitle"),
            content: t("modal_phone_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PHONE_CHANGE_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={() => setCurrentModal(null)}
      />
      <PrimarySecondaryForm
        fields={fieldsArray}
        fieldsText={{
          primaryTitle: t("edit_phone_primary_address"),
          primaryLabel: t("field_edit_primary_phone_label"),
          secondaryTitle: t("edit_phone_secondary_address"),
          secondaryLabel: t("field_edit_secondary_phone_label"),
        }}
        fieldComponent={({ index, label, isDisabled }) => (
          <EditPhoneField
            label={label}
            value={fields[index].value}
            isDisabled={isDisabled}
            savePhone={(newValue, isPrimary) =>
              updateValue(index, newValue, isPrimary)
            }
            deletePhone={() => deleteValue(index)}
          />
        )}
      />
    </EditFormPage>
  );
};

export default PhonesPage;
