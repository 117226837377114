import React from "react";
import WarningIcon from "@assets/svg/warning.svg";
import Icon from "@base/components/Global/Icon";
import P, { StyleProps } from "@base/components/Global/P";
import { Row } from "@base/components/Layout";
import Col from "@base/components/Layout/Col";
import { createUseStyles } from "@theme";
import classnames from "classnames";

const useStyles = createUseStyles(({ spacing, borderRadius }) => ({
  container: {
    backgroundColor: "rgba(242, 153, 74, 0.2)",
    borderRadius: borderRadius.s,
    padding: spacing.s,
  },
  row: {
    gap: spacing.m,
  },
}));

type WarningProps = StyleProps & {
  message: string;
  classNames?: string;
};

const Warning: React.FC<WarningProps> = ({
  message,
  classNames,
  noMargin,
  ...props
}) => {
  const classes = useStyles();

  if (!message) return null;

  return (
    <Col
      className={classnames([classes.container, classNames])}
      noMargin={noMargin}
    >
      <Row noMargin={noMargin}>
        <Col col={12} className={classes.row} noMargin={noMargin}>
          <Icon icon={<WarningIcon />} />
          <P {...props} cmsContent={message} noMargin={noMargin} />
        </Col>
      </Row>
    </Col>
  );
};

export default Warning;
