import React from "react";
import { useTranslation } from "react-i18next";
import { ITransaction } from "@api/interfaces/transactionLayout";
import { H5, P } from "@base/components/Global";
import { Col } from "@base/components/Layout";
import { TRANSACTION_ICON } from "@constants/blobIcons";
import TransactionsList from "@pages/Transactions/components/TransactionsList";

type Props = {
  transactions: ITransaction[];
  id?: string;
};

const PerkTransactions: React.FC<Props> = ({ transactions, id }) => {
  const { t } = useTranslation();

  return (
    <Col col={12} directionColumn fullWidth noHorizontalMargin id={id}>
      <Col col={12} directionColumn noMargin>
        <H5 prefixIconUrl={TRANSACTION_ICON} gray1>
          {t("gift_card_transactions_title")}
        </H5>
        <P gray3 small noMarginTop>
          {t("gift_card_transactions_subtitle")}
        </P>
      </Col>
      <Col col={12} fullWidth noMargin>
        <TransactionsList transactions={transactions} noMargin />
      </Col>
    </Col>
  );
};

export default PerkTransactions;
