import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ILocationsBlock } from "@api/interfaces/locationPageLayout";
import LocationIcon from "@assets/svg/location-with-star.svg";
import { H5, Icon, Link, P } from "@base/components/Global";
import GoogleMaps from "@base/components/GoogleMaps";
import { PinProps } from "@base/components/GoogleMaps/types";
import { Col, Row } from "@base/components/Layout";
import { COPY_SMALL_ICON, LOCATION_CLUB_ICON } from "@constants/blobIcons";
import { getTheme } from "@theme";
import { useMediaQuery } from "src/base/components/MediaQueryProvider";

import LocationInBuildingImage from "./LocationInBuildingImage";

const MAP_HEIGHT = 450;

const getPins = (
  locationBlocks?: ILocationsBlock[] | null,
  currentLocation?: string | null,
) => {
  if (currentLocation) {
    const locationObject = locationBlocks?.find(
      (location) => location.name === currentLocation,
    );
    if (locationObject) {
      return [
        {
          lat: Number(locationObject.latitude),
          lng: Number(locationObject.longitude),
          text: locationObject.name,
          icon: locationObject.mapPinIconURL,
          brandSystemKey: locationObject.brandSystemKey,
        },
      ] as PinProps[];
    }
  }
  return locationBlocks?.map((location) => ({
    lat: Number(location.latitude),
    lng: Number(location.longitude),
    text: location.name,
    icon: location.mapPinIconURL,
    brandSystemKey: location.brandSystemKey,
  })) as PinProps[];
};

const AllLocationsMap: React.FC<{
  locations: ILocationsBlock[];
  onCopyUrl: () => void;
}> = ({ locations, onCopyUrl }) => {
  const { t } = useTranslation();
  const [groupedLocations, setGroupedLocations] = useState<ILocationsBlock[]>(
    [],
  );

  const [searchParams] = useSearchParams();
  const currentLocation = searchParams.get("location");
  const currentBrand = searchParams.get("brand");
  const { color } = getTheme();

  useEffect(() => {
    if (locations.length > 0) {
      const newGroupedLocationsObject: Record<string, ILocationsBlock[]> = {};

      for (const location of locations) {
        const locationName = location.locationName;
        if (locationName) {
          newGroupedLocationsObject[locationName] =
            newGroupedLocationsObject.hasOwnProperty(locationName)
              ? [...newGroupedLocationsObject[locationName], location]
              : [location];
        }
      }

      const newGroupedLocationsList: ILocationsBlock[] = [];

      for (const key of Object.keys(newGroupedLocationsObject)) {
        const pinList = newGroupedLocationsObject[key];
        if (pinList.length > 1) {
          // Create general location pin when multiple locations
          newGroupedLocationsList.push({
            ...pinList[0],
            mapPinIconURL: LOCATION_CLUB_ICON,
          });
        } else {
          // Use specific location pin
          newGroupedLocationsList.push(pinList[0]);
        }
      }

      setGroupedLocations(newGroupedLocationsList);
    }
  }, [locations]);

  const [{ isCurrentSizeMd }] = useMediaQuery();
  if (!isCurrentSizeMd && currentLocation) {
    return null;
  }

  const pins = currentBrand ? getPins(locations) : getPins(groupedLocations);
  const focusedPins = getPins(locations, currentLocation);

  const isMobile =
    isCurrentSizeMd !== undefined && !isCurrentSizeMd && currentLocation;

  const locationObject = locations?.find(
    (location) => location.name === currentLocation,
  );

  return (
    <Row id="all-business-locations">
      <Col alignCenter col={12}>
        <GoogleMaps
          height={MAP_HEIGHT}
          pins={pins}
          filteredPins={focusedPins}
          currentBrand={currentBrand}
        />
      </Col>
      {!isMobile && locationObject && (
        <>
          <Row>
            <Col col={12}>
              <Link
                gray3
                flex
                action={() => {
                  void navigator.clipboard
                    .writeText(window.location.href)
                    .then(onCopyUrl);
                }}
                suffixIcon={
                  <Icon url={COPY_SMALL_ICON} stroke={color.neutralGray} />
                }
              >
                {`${locationObject.name}, ${locationObject.address}, ${locationObject.cityName}`}
              </Link>
            </Col>

            {(locationObject?.floorImageData || locationObject?.floorInfo) && (
              <Col col={12}>
                <H5 prefixIcon={<LocationIcon />}>
                  {t("location_in_building")}
                </H5>
              </Col>
            )}
          </Row>
          {locationObject?.floorImageData && (
            <LocationInBuildingImage
              floorImageData={locationObject?.floorImageData}
            />
          )}
          {locationObject?.floorInfo && (
            <Row>
              <Col col={12} noMargin>
                <P gray3 thin small>
                  {locationObject?.floorInfo}
                </P>
              </Col>
            </Row>
          )}
        </>
      )}
    </Row>
  );
};

export default AllLocationsMap;
