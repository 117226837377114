import React from "react";
import { Link } from "@base/components/Global";
import { createUseStyles } from "@theme";
import classNames from "classnames";

const useStyles = createUseStyles(({ color, spacing }) => ({
  container: {
    padding: 0,
    width: "100%",
    display: "flex",
    borderBottom: `1px solid ${color.gray4}`,
  },
  activeLink: {
    borderBottom: `3px solid ${color.primaryBrand}`,
    "& span": {
      color: color.gray2,
    },
  },
  link: {
    padding: [0, spacing.m],
    textAlign: "center",
    paddingBottom: spacing.s,
    flexGrow: 1,
    wordBreak: "keep-all",
    display: "flex",
    justifyContent: "space-around",
  },
}));

export type TabItem =
  | {
      label: string;
      value: string;
      action?: () => void;
      href?: never;
    }
  | {
      label: string;
      value: string;
      action?: never;
      href?: string;
    };

const Tabs: React.FC<{ items: TabItem[]; value: string | undefined }> = ({
  items,
  value,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {items.map((item) => (
        <Link
          key={item.value}
          gray2
          block
          className={classNames(
            classes.link,
            item.value === value && classes.activeLink,
          )}
          action={item.action}
          href={item.href}
          isActive={item.value === value}
        >
          {item.label}
        </Link>
      ))}
    </div>
  );
};

export default Tabs;
