import { isPresent } from "@apl-digital/utils";
import config from "@constants/config";

export const getSSOClientId = (regionPrefix: string | null) => {
  if (!isPresent(regionPrefix)) {
    return config.ssoConfig.defaultClientId;
  }

  const ssoRegionConfig = config.ssoConfig.region[regionPrefix];

  if (isPresent(ssoRegionConfig)) {
    return config.ssoConfig.region[regionPrefix].clientId;
  }

  return config.ssoConfig.defaultClientId;
};
