import React from "react";
import { IBenefitBlock } from "@api/interfaces/benefitLayouts";
import { Col, Row } from "@base/components/Layout";
import ApolloClubBlock from "@pages/HomePage/components/BenefitsBlock/ApolloClubBlock";
import Benefits from "@pages/HomePage/components/BenefitsBlock/Benefits";
import CardBlock from "@pages/HomePage/components/BenefitsBlock/CardBlock";
import DescriptionBlock from "@pages/HomePage/components/BenefitsBlock/DescriptionBlock";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles(({ spacing, sizes }) => ({
  container: {
    paddingBottom: spacing.x4l,
    [sizes.md]: {
      gap: spacing.xxl,
      paddingBottom: 0,
    },
  },
}));

type BenefitsBlockProps = {
  block?: IBenefitBlock;
};

const BenefitsBlock: React.FC<BenefitsBlockProps> = ({ block }) => {
  const classes = useStyles();

  if (!block) return null;

  const { benefits, description, card, apolloClub } = block;

  if (!(benefits || description || card || apolloClub)) return null;

  return (
    <Row noMargin>
      {(benefits || description || card) && (
        <Col
          noMargin
          col={12}
          colMd={6}
          directionColumn
          className={classes.container}
        >
          <Benefits benefits={benefits} />
          <DescriptionBlock description={description} />
          <CardBlock card={card} />
        </Col>
      )}
      {apolloClub && (
        <Col noMargin col={12} colMd={6} directionColumn>
          <ApolloClubBlock apolloClub={apolloClub} />
        </Col>
      )}
    </Row>
  );
};
export default BenefitsBlock;
