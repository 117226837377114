import React from "react";
import { H5, Icon, P } from "@base/components/Global";
import { createUseStyles } from "@theme";
import theme from "@theme/common";
import classnames from "classnames";

const SLOGAN_MIN_WIDTH = 123;
const SLOGAN_MAX_WIDTH = 266;

const useStyles = createUseStyles(({ color, borderRadius, spacing, font }) => ({
  slogan: {
    position: "absolute",
    bottom: 24,
    minWidth: SLOGAN_MIN_WIDTH,
    maxWidth: SLOGAN_MAX_WIDTH,
    backgroundColor: color.primaryBrand,
    borderRadius: borderRadius.s,
    padding: spacing.s,
  },
  sloganRight: {
    right: 0,
  },
  sloganLeft: {
    left: 0,
  },
  giftIcon: {
    display: "flex",
    backgroundColor: color.white,
    borderRadius: borderRadius.s,
    padding: spacing.s,
    marginRight: spacing.xs,
  },
  iconField: {
    display: "flex",
    alignItems: "flex-end",
    gap: spacing.xs,
  },
  sloganTitle: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
    color: color.white,
  },
  sloganText: {
    "& p": {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 3,
      color: color.white,
      fontSize: font.size.x3s,
      lineHeight: font.lineHeight.xxs,
    },
  },
}));

type GiftSLoganProps = {
  icon?: string;
  slogan?: string;
  rule?: string;
  isLocatedRight?: boolean;
};

// Divide slogan into 2 lines when has 4-6 words, last word on new line
const getFormattedSlogan = (newSlogan: string | undefined) => {
  if (!newSlogan) return newSlogan;
  const sloganWords = newSlogan.split(" ");
  if (sloganWords.length >= 4 && sloganWords.length <= 6) {
    return `${sloganWords.slice(0, -1).join(" ")}\n${sloganWords.at(-1)}`;
  }
  return newSlogan;
};

const GiftSlogan: React.FC<GiftSLoganProps> = ({
  icon,
  slogan,
  rule,
  isLocatedRight,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classnames([
        classes.slogan,
        isLocatedRight ? classes.sloganRight : classes.sloganLeft,
      ])}
    >
      <div className={classes.iconField}>
        <div className={classes.giftIcon}>
          <Icon url={icon} stroke={theme.color.primaryBrand} />
        </div>
        <P noMargin className={classes.sloganText}>
          {getFormattedSlogan(slogan)}
        </P>
      </div>
      <H5 noMargin className={classes.sloganTitle}>
        {rule}
      </H5>
    </div>
  );
};

export default GiftSlogan;
