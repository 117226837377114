import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import Menu from "@assets/svg/menu.svg";
import { Link } from "@base/components/Global";
import Icon from "@base/components/Global/Icon";
import { Col, Row } from "@base/components/Layout";
import LogoutModal from "@base/components/Modal/LogoutModal";
import { useSharedTopBarStyles } from "@base/components/TopBar";
import { isEnabledForGiftCardSoftLive } from "@base/utils/featureFlagUtil";
import { getApolloLogoURLByLanguage } from "@base/utils/iconUtil";
import {
  APOLLO_STAR_ICON,
  BURGER_NOTIFICATION_ICON,
  LOGIN_ICON,
  LOGOUT_ICON,
  PROFILE_ICON,
} from "@constants/blobIcons";
import { NavigationPath } from "@constants/navigation";
import { selectHasMenuNotifications } from "@store/cms";
import { useAppSelector } from "@store/hooks";
import { selectUserData } from "@store/user";
import { getTheme } from "@theme";
import classnames from "classnames";
import { useUser } from "src/base/components/UserProvider";

export type DesktopTopBarProps = {
  shouldHide: boolean;
  shouldReveal: boolean;
  openMenu: () => void;
  businessLogoAction: () => void;
};

const DesktopTopBar: React.FC<DesktopTopBarProps> = ({
  shouldHide,
  shouldReveal,
  openMenu,
  businessLogoAction,
}) => {
  const classes = useSharedTopBarStyles();
  const theme = getTheme();

  const [{ logIn }] = useUser();
  const { isAuthenticated } = useAuth();
  const { i18n } = useTranslation();

  const userData = useAppSelector(selectUserData);
  const hasMenuNotifications = useAppSelector(selectHasMenuNotifications);

  const [shouldShowLogoutModal, setShouldShowLogoutModal] = useState(false);

  return (
    <div
      className={classnames([
        classes.headerContainer,
        shouldHide && classes.hide,
        shouldReveal && classes.reveal,
      ])}
    >
      {shouldShowLogoutModal && (
        <LogoutModal
          isOpen={shouldShowLogoutModal}
          onClose={() => setShouldShowLogoutModal(false)}
        />
      )}
      <Row noMargin>
        <Col noVerticalMargin>
          <Link flex action={businessLogoAction}>
            <Icon
              url={APOLLO_STAR_ICON}
              className={classes.startIcon}
              width={37}
              height={37}
            />
            <Icon url={getApolloLogoURLByLanguage(i18n.language)} />
          </Link>
        </Col>

        <Col alignRight col={6} className={classes.userActions}>
          {isAuthenticated && userData && (
            <Link
              flex
              brandColor
              className={classes.headerLink}
              navigateTo={NavigationPath.Profile}
              prefixIcon={
                <Icon url={PROFILE_ICON} stroke={theme.color.primaryBrand} />
              }
            >
              <span className={classes.userFullName}>
                {`${userData.firstName} ${userData.lastName}`}
              </span>
            </Link>
          )}
          {isEnabledForGiftCardSoftLive && (
            <Link
              flex
              brandColor
              className={classes.headerLink}
              action={() =>
                isAuthenticated ? setShouldShowLogoutModal(true) : logIn()
              }
            >
              <Icon
                url={isAuthenticated ? LOGOUT_ICON : LOGIN_ICON}
                stroke={theme.color.primaryBrand}
              />
            </Link>
          )}
        </Col>
        <Col alignRight col={3} colMd={1} noVerticalMargin>
          <Link flex action={openMenu}>
            {hasMenuNotifications ? (
              <Icon width={48} height={48} url={BURGER_NOTIFICATION_ICON} />
            ) : (
              <Menu />
            )}
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default DesktopTopBar;
