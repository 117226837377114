import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LinkArrowIcon from "@assets/svg/link-arrow.svg";
import { FooterLinkProps } from "@base/components/Footer/helpers";
import { Icon, Link, P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles(({ color }) => ({
  link: {
    borderBottom: `1px solid ${color.gray1}`,
    width: "100%",
  },
}));

const FooterLink: React.FC<FooterLinkProps> = ({ name, path, isExternal }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openLink = () =>
    // @ts-expect-error: waits for translations to accept dynamic keys
    isExternal ? window.open(t(path), "_self") : navigate(path);

  return (
    <Link flex bold white action={openLink} className={classes.link}>
      <Col col={12}>
        <Row>
          <Col>
            {/* @ts-expect-error: waits for translations to accept dynamic keys */}
            <P white>{t(name)}</P>
          </Col>
          <Col col={2} alignRight>
            <Icon icon={<LinkArrowIcon />} />
          </Col>
        </Row>
      </Col>
    </Link>
  );
};

export default FooterLink;
