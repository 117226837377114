import { IImage } from "@api/interfaces/common";
import { ITransaction } from "@api/interfaces/transactionLayout";

export enum PerkType {
  GIFT_CARD = "GIFT_CARD",
  GIFT_CERTIFICATE = "GIFT_CERTIFICATE",
  TICKET_VOUCHER = "VOUCHER",
  PRODUCT_VOUCHER = "PRODUCT_VOUCHER",
}

export type IPerk = {
  usageBarcode: string;
  barcodeWithoutChecksum: string;
  type: PerkType;
  name: string;
  balance: number;
  validFrom: Date;
  validUntil: Date;
  landscapeImage: IImage;
  portraitImage: IImage;
  count: number;
  centralCrmCode: string;
  transactions?: ITransaction[];
};

export type FetchPerkRequest = {
  perkId: string;
  perkCrmCode: string;
};
