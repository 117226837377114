import React from "react";
import { LOADING_SPINNER_ICON } from "@constants/blobIcons";
import { createUseStyles, getTheme } from "@theme";

import Icon from "./Icon";

const useStyles = createUseStyles({
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  spin: {
    animation: "$spin 1s infinite linear",
  },
});

type Props = {
  isLight?: boolean;
  isSmall?: boolean;
};

const LoaderTiny: React.FC<Props> = ({ isLight = false, isSmall = false }) => {
  const classes = useStyles();
  const { color } = getTheme();

  return (
    <Icon
      className={classes.spin}
      stroke={isLight ? color.white : color.primaryBrand}
      url={LOADING_SPINNER_ICON}
      width={isSmall ? 16 : 24}
      height={isSmall ? 16 : 24}
    />
  );
};

export default LoaderTiny;
