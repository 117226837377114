import { isGiftCardDomain } from "@base/utils/domainHelper";
import { isExternalURI } from "@base/utils/uriHelper";
import config from "@constants/config";

// When on GC domain, all navigations should direct user to Profile domain if they aren't already defined as valid URL-s.
const getBurgerMenuItemNavigationPath = (action: string, params?: string) => {
  if (isGiftCardDomain && params && !isExternalURI(params)) {
    return `${config.appConfig.loyaltyProfileUri}${params}`;
  }
  if (action === "redirect") {
    return params;
  }
  // NB! Legacy. Action should always be redirect. If not the update configuration in Strapi.
  return action;
};

export default getBurgerMenuItemNavigationPath;
