import React from "react";
import { UseFormTrigger } from "react-hook-form";
import { mergeRefs } from "react-merge-refs";
import { TextField as MaterialTextField } from "@mui/material";
import { createUseStyles } from "@theme";
import classnames from "classnames";

export const useStyles = createUseStyles(
  ({ color, spacing, borderRadius, font }) => ({
    container: {
      width: "100%",
      position: "relative",
      alignSelf: "flex-start",
      paddingTop: spacing.m,

      "& > .MuiFormControl-root": {
        width: "100%",
        "& > .MuiInputLabel-root": {
          top: -3,
          height: 24,
          lineHeight: font.lineHeight.m,
          color: color.gray4,
          fontWeight: font.weight.s,
          fontSize: font.size.s,
          "&.Mui-disabled": {
            color: `${color.gray5} !important`,
          },

          "&.MuiInputLabel-shrink": {
            top: 0,
            paddingRight: spacing.xs,
            backgroundColor: color.white,
            color: color.gray3,
            "&.Mui-focused": {
              color: color.primaryBrand,
              "&.Mui-error": {
                color: color.error,
              },
            },
            "&.Mui-error": {
              color: color.error,
            },
          },
        },
        "& > .MuiOutlinedInput-root": {
          borderRadius: borderRadius.s,

          "& > .MuiOutlinedInput-input": {
            height: 24,
            lineHeight: font.lineHeight.m,
            color: color.gray1,
            fontWeight: font.weight.s,
            fontSize: font.size.s,
            paddingTop: spacing.m,
            paddingBottom: spacing.m,
            "&.Mui-disabled": {
              color: `${color.gray5} !important`,
            },
            "&::placeholder": {
              color: color.gray4,
            },
          },

          "&.Mui-disabled > .MuiOutlinedInput-notchedOutline": {
            borderColor: `${color.gray5} !important`,
          },

          "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
            borderColor: color.primaryBrand,
          },

          "&.Mui-focused.Mui-error > .MuiOutlinedInput-notchedOutline": {
            borderColor: color.error,
          },

          "&.Mui-error > .MuiOutlinedInput-notchedOutline": {
            borderColor: color.error,
          },

          "& > .MuiOutlinedInput-notchedOutline": {
            borderColor: color.gray4,
          },
        },
        "& .MuiFormHelperText-root": {
          color: color.gray3,
          fontWeight: font.weight.m,
          fontSize: font.size.xxs,
          lineHeight: font.lineHeight.xs,
          fontFamily: font.family,

          "&.Mui-error": {
            color: color.error,
          },
        },
      },
    },
  }),
);

export type TextFieldProps = {
  label?: string;
  value?: string;
  submitOnEnter?: boolean;
  type?: string;
  placeholder?: string;
  helperText?: string | boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  autoFocus?: boolean;
  hasError?: boolean;
  className?: string;
  handleKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
  "data-testid"?: string;
  inputMode?:
    | "none"
    | "text"
    | "search"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal";
  autoComplete?: string;
  onEnter?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trigger: UseFormTrigger<any>;
    name: string;
  };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  rows?: number;
  maxLength?: number;
  onPaste?: (e: React.ClipboardEvent<HTMLDivElement> | undefined) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  innerRef?:
    | React.RefCallback<HTMLInputElement>
    | React.RefObject<HTMLInputElement>
    | null;
};

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  function TextField(
    {
      type = "text",
      label = "",
      value,
      submitOnEnter = false,
      placeholder = "",
      helperText,
      isDisabled = false,
      isReadOnly = false,
      autoFocus = false,
      hasError = false,
      className,
      handleKeyUp,
      id = "input",
      autoComplete,
      inputMode,
      onEnter,
      rows = 1,
      maxLength,
      "data-testid": dataTestId,
      onPaste,
      startAdornment,
      endAdornment,
      innerRef,
      ...props
    }: TextFieldProps,
    ref,
  ) {
    const classes = useStyles();

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (!submitOnEnter) e.preventDefault();
        if (onEnter) {
          void onEnter.trigger(onEnter.name, { shouldFocus: true });
        }
      }
    };

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!handleKeyUp) return;
      handleKeyUp(e);
    };

    return (
      <div className={classnames([classes.container])}>
        <MaterialTextField
          variant="outlined"
          ref={mergeRefs([ref, innerRef])}
          InputProps={{
            startAdornment,
            endAdornment,
            readOnly: isReadOnly,
            inputProps: {
              inputMode,
              maxLength,
              ...(dataTestId && {
                "data-testid": dataTestId,
              }),
            },
            rows: rows,
            multiline: rows !== 1,
            style:
              rows === 1
                ? {}
                : {
                    padding: 0,
                  },
          }}
          placeholder={placeholder}
          label={label}
          value={value}
          name={autoComplete}
          autoComplete="off"
          type={type}
          error={hasError}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          disabled={isDisabled}
          autoFocus={autoFocus}
          helperText={helperText}
          className={classnames([className])}
          id={id}
          onPaste={onPaste}
          onFocus={(e) => e.target.select()}
          {...props}
        />
      </div>
    );
  },
);

export default TextField;
