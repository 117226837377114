import React from "react";
import { Icon } from "@base/components/Global";
import { StyleProps } from "@base/components/Global/P";
import { createUseStyles, getTheme } from "@theme";
import classnames from "classnames";

const useStyles = createUseStyles<StyleProps>(({ spacing }) => ({
  prefixIcon: {
    marginRight: spacing.s,
  },
  prefixSpanIcon: {
    display: "flex",
    marginRight: spacing.s,
  },
}));

type PrefixIconProps = {
  className?: string;
  url?: string;
  icon?: React.ReactNode;
  isActive: boolean;
};

const PrefixIcon: React.FC<PrefixIconProps> = ({
  url,
  icon,
  className,
  isActive = false,
}) => {
  const classes = useStyles();
  const { color } = getTheme();

  if (icon) {
    return <div className={classes.prefixSpanIcon}>{icon}</div>;
  }

  return (
    <Icon
      url={url}
      className={classnames([classes.prefixIcon, className])}
      stroke={isActive ? color.primaryBrand : ""}
    />
  );
};

export default PrefixIcon;
