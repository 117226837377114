import { HEADER_HEIGHT } from "@base/components/TopBar";

export const scrollToWithOffset = (targetId: string) => {
  const element = document.querySelector(`#${targetId}`);
  const elementPosition = element?.getBoundingClientRect().top || 0;
  const offsetPosition = elementPosition + window.scrollY - HEADER_HEIGHT;
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const handleScrollToTop = () => {
  if (window.scrollY !== 0) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};
