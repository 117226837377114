import { HttpStatus } from "@api/constants";

import { ResponseFailed, ResponseSucceeded } from "./types";

const SUCCESS_STATUSES = [HttpStatus.OK, HttpStatus.CREATED];

function getGenericAPIError(
  request: ResponseFailed["request"],
  response: Response,
  reason: string,
): ResponseFailed<undefined>;
function getGenericAPIError<T>(
  request: ResponseFailed["request"],
  response: Response,
  reason: string,
  responseData: T,
): ResponseFailed<T>;
function getGenericAPIError<T>(
  request: ResponseFailed["request"],
  response: Response,
  reason: string,
  responseData?: T,
): ResponseFailed<T | undefined> {
  return {
    request: request,
    response: responseData as T,
    responseStatus: response.status,
    isResponseOk: false,
    reason,
  };
}

const getResponse = <T>(
  responseData: T,
  response: Response,
): ResponseSucceeded<T> => ({
  response: responseData,
  responseStatus: response.status,
  isResponseOk: true,
});

export { getGenericAPIError, getResponse, SUCCESS_STATUSES };
