const CMS_BASE_PATH = "/cms";
const CMS_PAGE_BASE_PATH = `${CMS_BASE_PATH}/pages`;
const FORMS_BASE_PATH = "/forms";
const CUSTOMER_BASE_PATH = "/customers";
const CUSTOMER_CONTACTS_BASE_PATH = `${CUSTOMER_BASE_PATH}/contacts`;
const GIFT_CARDS_BASE_PATH = "/gift-cards";
const VERIFICATION_CODE_BASE_PATH = "/verification-code";

const ApiPath = {
  User: {
    base: CUSTOMER_BASE_PATH,
    beginRegistration: `${CUSTOMER_BASE_PATH}/begin-registration`,
    completeRegistration: `${CUSTOMER_BASE_PATH}/complete-registration`,
    changePassword: `${CUSTOMER_BASE_PATH}/change-password`,
    addresses: `${CUSTOMER_BASE_PATH}/addresses`,
    setPrimaryAddress: `${CUSTOMER_BASE_PATH}/addresses/set-primary`,
    profile: `${CUSTOMER_BASE_PATH}/profile`,
    transactions: `${CUSTOMER_BASE_PATH}/transactions`,
    transactionsLatest: `${CUSTOMER_BASE_PATH}/transactions/latest`,
    loyaltyPrograms: `${CUSTOMER_BASE_PATH}/loyalty-programs`,
    benefits: `${CUSTOMER_BASE_PATH}/benefits`,
    perks: `${CUSTOMER_BASE_PATH}/perks`,
    giftCards: `${CUSTOMER_BASE_PATH}/gift-cards`,
    perkDetails: `${CUSTOMER_BASE_PATH}/perks/details`,
    newsletters: `${CUSTOMER_BASE_PATH}/newsletters`,
    terms: `${CUSTOMER_BASE_PATH}/terms`,
    requestPasswordReset: `${CUSTOMER_BASE_PATH}/request-password-reset`,
    resetPassword: `${CUSTOMER_BASE_PATH}/password-reset`,
    contacts: {
      emails: `${CUSTOMER_CONTACTS_BASE_PATH}/emails`,
      phones: `${CUSTOMER_CONTACTS_BASE_PATH}/phones`,
      setPrimaryContact: `${CUSTOMER_CONTACTS_BASE_PATH}/set-primary`,
      activate: `${CUSTOMER_CONTACTS_BASE_PATH}/activate`,
    },
    closePersonAccount: `${CUSTOMER_BASE_PATH}/close-person-account`,
  },
  Regions: {
    base: "/get-regions",
  },
  Form: {
    countries: `${FORMS_BASE_PATH}/countries`,
    giftCardStatus: `${FORMS_BASE_PATH}/gift-card-status`,
    support: `${FORMS_BASE_PATH}/support`,
    beginRegistration: `${FORMS_BASE_PATH}/begin-registration`,
    completeRegistration: `${FORMS_BASE_PATH}/complete-registration`,
  },
  GiftCard: {
    base: GIFT_CARDS_BASE_PATH,
    validate: `${GIFT_CARDS_BASE_PATH}/validate`,
    add: `${GIFT_CARDS_BASE_PATH}/add`,
  },
  Newsletter: {
    base: "/newsletters",
  },
  CMS: {
    pages: {
      newsletters: `${CMS_PAGE_BASE_PATH}/newsletters`,
      terms: `${CMS_PAGE_BASE_PATH}/terms`,
      privateHome: `${CMS_PAGE_BASE_PATH}/private-home`,
      publicHome: `${CMS_PAGE_BASE_PATH}/public-home`,
      myInfo: `${CMS_PAGE_BASE_PATH}/my-info`,
      myClub: `${CMS_PAGE_BASE_PATH}/my-club`,
      giftCard: `${CMS_PAGE_BASE_PATH}/gift-card`,
      closeAccount: `${CMS_PAGE_BASE_PATH}/close-account`,
      goodbye: `${CMS_PAGE_BASE_PATH}/goodbye`,
    },
    entities: {
      businessNewsletters: `${CMS_BASE_PATH}/business-newsletters`,
      businesses: `${CMS_BASE_PATH}/businesses`,
      locations: `${CMS_BASE_PATH}/locations`,
      burgerMenu: `${CMS_BASE_PATH}/burger-menu`,
    },
  },
  VerificationCode: {
    base: VERIFICATION_CODE_BASE_PATH,
    validate: `${VERIFICATION_CODE_BASE_PATH}/validate`,
    expire: `${VERIFICATION_CODE_BASE_PATH}/expire`,
    newRegistrationCode: `${VERIFICATION_CODE_BASE_PATH}/new-registration-code`,
  },
};

export { ApiPath };
