import PerkController from "@api/controller/perkController";
import { createApiGetAsyncThunk } from "@store/utils";

import { selectPerks } from "./slice";

export const fetchPerks = createApiGetAsyncThunk(
  "perks/getPerks",
  PerkController.fetchPerks,
  selectPerks,
);
