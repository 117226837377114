import React from "react";
import { Button, ButtonProps } from "@base/components/Global";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles(({ spacing, font, color, sizes }) => ({
  buttonTile: {
    backgroundColor: color.white,
    border: `1px solid ${color.gray4}`,
    flexDirection: "column",
    width: "100%",
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    fontSize: font.size.m,
    lineHeight: font.lineHeight.m,
    height: 96,
    [sizes.md]: {
      paddingLeft: spacing.xl,
      paddingRight: spacing.xl,
    },
  },
  children: {
    color: color.primaryBrand,
    fontSize: font.size.l,
    fontWeight: font.weight.m,
    lineHeight: font.lineHeight.m,
    textAlign: "center",
    wordBreak: "normal",
    paddingTop: spacing.xs,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 140,
  },
}));

type ButtonTileProps = ButtonProps & {
  icon: React.ReactNode;
  children?: React.ReactNode;
};

const ButtonTile: React.FC<ButtonTileProps> = ({
  icon,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button className={classes.buttonTile} {...props}>
      {icon}
      <div className={classes.children}>{children}</div>
    </Button>
  );
};

export default ButtonTile;
