import { LanguageQP } from "@constants/navigation";

export const isExternalURI = (uri: string): boolean => {
  try {
    new URL(uri);
    return true;
  } catch {
    return false;
  }
};

export const addLocaleParam = (
  uri: string,
  language: string,
  region?: string | null,
): string => {
  if (uri.includes(LanguageQP)) {
    return uri;
  }
  // Use uppercase region to ensure we are ISO 3166-1 compliant
  const locale = region ? `${language}-${region.toUpperCase()}` : language;

  return uri.includes("?")
    ? `${uri}&${LanguageQP}=${locale}`
    : `${uri}?${LanguageQP}=${locale}`;
};
