import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "react-jss";
import { Provider } from "react-redux";
import AppLoader from "@App";
import Polyfills from "@base/utils/Polyfills";
import ErrorPage from "@pages/ErrorPage/ErrorPage";
import { store } from "@store";
import { getTheme } from "@theme";

import "./styles/styles.scss";
import "./styles/fonts.scss";
import "./index.css";

import "./sentry";
import "./translation";

const rootElement = document.querySelector("#root");

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <Provider store={store}>
      <ThemeProvider theme={getTheme()}>
        <ErrorBoundary fallback={<ErrorPage />}>
          <Polyfills />
          <AppLoader />
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>,
  );
}
