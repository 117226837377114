import { isGiftCardDomain } from "@base/utils/domainHelper";
import config from "@constants/config";

export const isEnabledForGiftCardSoftLive =
  config.featureFlags.giftCardLive || !isGiftCardDomain;

// Region ID-s stay same in every env.
export enum RegionId {
  ESTONIAN_REGION_ID = "1001",
  LATVIAN_REGION_ID = "1002",
  LITHUANIAN_REGION_ID = "1003",
  FINLAND_REGION_ID = "1004",
}

export const LaunchedTerritories: Record<RegionId, boolean> = {
  [RegionId.ESTONIAN_REGION_ID]: true,
  [RegionId.LATVIAN_REGION_ID]: config.featureFlags.latRegionUnlocked,
  [RegionId.LITHUANIAN_REGION_ID]: config.featureFlags.litRegionUnlocked,
  [RegionId.FINLAND_REGION_ID]: config.featureFlags.finRegionUnlocked,
};

export const isRegionEnabled = (regionId: string): boolean =>
  Object.values(RegionId).includes(regionId as RegionId) &&
  LaunchedTerritories[regionId as RegionId];
