import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IAddress } from "@api/interfaces/userLayouts";
import AddFormPage from "@base/components/Form/EditFormPage/AddFormPage";
import ModalArray from "@base/components/Modal/ModalArray";
import { ADDRESS_ICON } from "@constants/blobIcons";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@constants/googleAnalytics";
import { NavigationPath } from "@constants/navigation";
import AddressField from "@pages/Address/AddressField";
import { useUser } from "src/base/components/UserProvider";

const AddAddressPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [{ addAddress }] = useUser();

  const save = async (newAddress: IAddress) => {
    const isSuccess = await addAddress(newAddress);
    if (isSuccess) {
      setCurrentModal("successModal");
    } else {
      setCurrentModal("errorModal");
    }
  };

  return (
    <AddFormPage
      form={{
        headingIconUrl: ADDRESS_ICON,
        headingTitle: t("add_address_header"),
        headingSubTitle: t("add_address_subtitle"),
        content: t("add_address_helper_text"),
      }}
    >
      <ModalArray
        modals={{
          successModal: {
            title: t("modal_address_added_title"),
            subTitle: t("modal_address_added_subtitle"),
            content: t("modal_address_added_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.ADD_ADDRESS_SUCCESS,
          },
          errorModal: {
            title: t("modal_address_added_error_title"),
            subTitle: t("modal_address_added_error_subtitle"),
            content: t("modal_address_added_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.ADD_ADDRESS_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={() => {
          if (currentModal !== "errorModal") {
            setCurrentModal(null);
            navigate(NavigationPath.Addresses);
          }
          setCurrentModal(null);
        }}
      />
      <AddressField save={save} />
    </AddFormPage>
  );
};

export default AddAddressPage;
