import React from "react";
import {
  TextComponent,
  TextStyleProps,
  TextWithIcon,
} from "@base/components/Global";
import { MarginProps } from "@base/components/Layout/constants";
import { createUseStyles } from "@theme";
import classnames from "classnames";

type StyleProps = TextStyleProps &
  MarginProps & {
    thin?: boolean;
  };

const useStyles = createUseStyles<StyleProps>(({ color, font, spacing }) => ({
  h5: {
    display: "flex",
    margin: 0,
    paddingTop: ({ noMargin, noVerticalMargin, noMarginTop }) =>
      noMargin || noVerticalMargin || noMarginTop ? 0 : spacing.s,
    paddingBottom: ({ noMargin, noVerticalMargin, noMarginBottom }) =>
      noMargin || noVerticalMargin || noMarginBottom ? 0 : spacing.s,
    fontSize: font.size.m,
    lineHeight: font.lineHeight.l,
    color: color.black,
  },
}));

type H5Props = StyleProps & {
  children?: React.ReactNode | string;
  className?: string | number | boolean | null;
  prefixIconUrl?: string;
  prefixIcon?: React.ReactNode;
  suffixIconUrl?: string;
  suffixIcon?: React.ReactNode;
};

const H5: React.FC<H5Props> = ({
  children,
  className,
  prefixIconUrl,
  prefixIcon,
  suffixIconUrl,
  suffixIcon,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <h5 className={classnames([classes.h5, className])}>
      <TextComponent {...props}>
        <TextWithIcon
          prefixIconUrl={prefixIconUrl}
          prefixIcon={prefixIcon}
          suffixIconUrl={suffixIconUrl}
          suffixIcon={suffixIcon}
        >
          {children}
        </TextWithIcon>
      </TextComponent>
    </h5>
  );
};

export default H5;
