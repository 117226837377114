import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { IPageSection } from "@api/interfaces/pageLayout";
import { H5, Link, Loader, P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import { TRANSACTION_ICON } from "@constants/blobIcons";
import { NavigationPath } from "@constants/navigation";
import TransactionsList from "@pages/Transactions/components/TransactionsList";
import { useAppDispatch, useAppSelector } from "@store";
import {
  fetchLatestTransactions,
  selectUserLatestTransactions,
} from "@store/user";
import { isLoading, shouldLoad } from "@store/utils";

type Props = {
  block: IPageSection;
};

const TransactionsBlock: React.FC<Props> = ({ block }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const latestTransactions = useAppSelector(selectUserLatestTransactions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated && shouldLoad(latestTransactions)) {
      void dispatch(fetchLatestTransactions());
    }
  }, [isAuthenticated, latestTransactions]);

  if (!isAuthenticated) return null;

  if (isLoading(latestTransactions) || shouldLoad(latestTransactions))
    return <Loader isMinified />;

  return (
    <Row>
      <Col col={12}>
        <Col col={12} directionColumn noMargin>
          <H5
            prefixIconUrl={TRANSACTION_ICON}
            gray1
            noMarginBottom={!!block.subTitle}
          >
            {block.title}
          </H5>
          {block.subTitle && (
            <P gray3 small noMarginTop>
              {block.subTitle}
            </P>
          )}
        </Col>
      </Col>
      <TransactionsList transactions={latestTransactions.array} />
      <Col col={12} hide={latestTransactions.array.length > 0}>
        <P>{t("no_transactions_found")}</P>
      </Col>
      {latestTransactions && latestTransactions.array.length > 0 && (
        <Col col={12} directionColumn>
          <Link navigateTo={NavigationPath.Transactions} brandColor>
            {t("view_transactions")}
          </Link>
          <P gray3 small>
            {t("view_transactions_details")}
          </P>
        </Col>
      )}
    </Row>
  );
};

export default TransactionsBlock;
