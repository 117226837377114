import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "@base/components/Modal/ConfirmationModal";
import { useUser } from "src/base/components/UserProvider";

type LogoutModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const LogoutModal: React.FC<LogoutModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [{ logOut }] = useUser();

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("modal_logout_title")}
      content={t("modal_logout_msg")}
      primaryAction={logOut}
      primaryTitle={t("btn_logout")}
    />
  );
};

export default LogoutModal;
