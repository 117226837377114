import IMenuItemLayout from "@api/interfaces/menuLayout";
import { TermsOfServiceStatus } from "@api/interfaces/userLayouts";
import { isGiftCardDomain } from "@base/utils/domainHelper";
import { hasAcceptedTerms } from "@base/utils/userUtil";

const filterMenuItems = (
  items: IMenuItemLayout[],
  isAuthenticated: boolean,
): IMenuItemLayout[] => {
  if (isAuthenticated)
    return items.filter((item) => item.visibleForAuthenticated);
  if (isGiftCardDomain)
    return items.filter((item) => item.visibleInGiftCardDomain);
  return items.filter((item) => item.visibleForAnonymous);
};

const isMenuItemDisabled = (
  shouldDisableWhenNotValidated: boolean,
  termsStatus?: TermsOfServiceStatus,
): boolean => {
  if (termsStatus) {
    return shouldDisableWhenNotValidated && !hasAcceptedTerms(termsStatus);
  }
  return false;
};

export { filterMenuItems, isMenuItemDisabled };
