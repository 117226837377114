import { isPresent } from "@apl-digital/utils";
import { getSessionStorage, SessionStorageKey } from "@base/utils/storage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLanguageCode } from "countries-list";

import { LanguageState } from "./types";

const getInitialState = (loadFromStorage: boolean): LanguageState => {
  const languageOverride = getSessionStorage(
    SessionStorageKey.LANGUAGE_OVERRIDE,
  );

  return {
    selectedLanguage: null,
    languageOverride: loadFromStorage ? languageOverride : null,
  };
};

export const languageSlice = createSlice({
  name: "language",
  initialState: getInitialState(true),
  reducers: {
    overrideLanguage: (state, action: PayloadAction<TLanguageCode>) => {
      state.languageOverride = action.payload;
    },
    changeSelectedLanguage: (state, action: PayloadAction<TLanguageCode>) => {
      state.selectedLanguage = action.payload;
      state.languageOverride = null;
    },
  },
  selectors: {
    selectHasLanguageOverride: (state) => isPresent(state.languageOverride),
  },
});

export const { overrideLanguage, changeSelectedLanguage } =
  languageSlice.actions;

export const { selectHasLanguageOverride } = languageSlice.selectors;

export default languageSlice.reducer;
