import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckboxRow from "@base/components/Form/CheckboxRow";
import { Button, P } from "@base/components/Global";
import { Col } from "@base/components/Layout";
import Row from "@base/components/Layout/Row";
import useOnClickOutside from "@base/hooks/useOnClickOutside";
import { validatePhone } from "@base/utils/userUtil";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@constants/googleAnalytics";
import { PhoneFieldType } from "@pages/Phone/types";
import { createUseStyles } from "@theme";
import PhoneField from "src/base/components/Form/PhoneField";

const useStyles = createUseStyles(({ spacing }) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
  btnRow: {
    paddingTop: spacing.s,
  },
}));

const EditPhoneField: React.FC<PhoneFieldType> = ({
  value,
  label,
  isDisabled,
  savePhone,
  deletePhone,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isActive, setIsActive] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const [currentPhone, setCurrentPhone] = useState(value);
  const [confirmPhone, setConfirmPhone] = useState("");
  const [shouldShowErrors, setShouldShowErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDirty = value !== currentPhone;
  const helperTextCondition =
    !isDisabled && !isDirty ? t("field_phone_helper_text") : undefined;

  const hasCurrentPhoneError = Boolean(
    shouldShowErrors && !validatePhone(currentPhone),
  );
  const hasConfirmPhoneError = Boolean(
    shouldShowErrors && currentPhone !== confirmPhone,
  );

  const onSubmit = () => {
    setShouldShowErrors(true);
    const isPhoneValid = validatePhone(currentPhone);
    const isConfirmPhoneValid = isPrimary
      ? true
      : currentPhone === confirmPhone;
    if (isPhoneValid && isConfirmPhoneValid && savePhone) {
      setIsActive(false);
      setShouldShowErrors(false);
      setIsSubmitting(true);
      savePhone(currentPhone, isPrimary);
      setIsSubmitting(false);
    }
  };

  useOnClickOutside(inputRef, () => {
    setIsActive(false);
  });

  return (
    <div
      onClick={() => setIsActive(true)}
      ref={inputRef}
      className={classes.form}
    >
      <Col col={12} style={{ paddingBottom: 10 }}>
        <PhoneField
          disabled={isDisabled}
          label={label}
          helperText={
            hasCurrentPhoneError ? t("phone_not_valid") : helperTextCondition
          }
          hasError={hasCurrentPhoneError}
          id="phone-input"
          value={currentPhone}
          onChange={(e: { target: { value: string } }) => {
            setCurrentPhone(e.target.value);
          }}
        />
      </Col>
      {isDirty && !isDisabled && (
        <Col col={12} noMarginBottom>
          <PhoneField
            disabled={isDisabled}
            label={t("field_edit_repeat_phone_label")}
            helperText={
              hasConfirmPhoneError ? t("phones_dont_match") : undefined
            }
            hasError={hasConfirmPhoneError}
            id="confirm-phone-input"
            value={confirmPhone}
            onChange={(e: { target: { value: string } }) =>
              setConfirmPhone(e.target.value)
            }
          />
        </Col>
      )}
      {!isDisabled && isActive && currentPhone && value === currentPhone && (
        <Col col={12} directionColumn noVerticalMargin>
          <CheckboxRow
            label={t("checkbox_phone_use_as_primary")}
            slim
            bold={false}
            small
            noBorder
            checked={isPrimary}
            onChange={() => setIsPrimary(!isPrimary)}
          />
          <P xxSmall gray3 noMargin>
            {t("checkbox_phone_helper_text")}
          </P>
        </Col>
      )}
      {!isDisabled && isActive && (
        <Row className={classes.btnRow}>
          <Col noMarginLeft col={6}>
            {deletePhone && (
              <Button
                transparentBrand
                action={deletePhone}
                analyticsAttributes={GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_PHONE}
              >
                {t("delete_phone_btn")}
              </Button>
            )}
          </Col>
          <Col noMarginRight col={6}>
            <Button
              action={onSubmit}
              isLoading={isSubmitting}
              analyticsAttributes={GOOGLE_ANALYTICS_ATTRIBUTES.PHONE_CHANGE}
              isDisabled={
                isPrimary
                  ? false
                  : !isDirty || hasCurrentPhoneError || hasConfirmPhoneError
              }
            >
              {t("action_save")}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EditPhoneField;
