import { useEffect, useState } from "react";
import { isApolloMainDomain, isGiftCardDomain } from "@base/utils/domainHelper";
import { LanguageQP } from "@constants/navigation";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { overrideLanguage, selectHasLanguageOverride } from "@store/language";
import { isLanguageValid } from "@store/language/utils";
import { overrideRegion } from "@store/region";

type UseLocaleOverrideResults = {
  isLocaleLoaded: boolean;
};

const useLocaleOverride = (): UseLocaleOverrideResults => {
  const searchParams = new URLSearchParams(window.location.search);
  const [isLocaleLoaded, setIsLocaleLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const hasLanguageOverride = useAppSelector(selectHasLanguageOverride);

  // General domain has no region identifier in its origin (e.g. ".ee" or ".fi")
  const isGeneralDomain = isGiftCardDomain || isApolloMainDomain;

  const handleSearchParamLanguage = () => {
    const paramLanguage = searchParams.get(LanguageQP);

    if (!paramLanguage) return;

    const languageRegion = paramLanguage.split("-");

    if (languageRegion.length > 1) {
      if (isLanguageValid(languageRegion[0])) {
        dispatch(overrideLanguage(languageRegion[0]));
      }

      dispatch(overrideRegion(languageRegion[1].toLowerCase()));
    } else {
      if (isLanguageValid(paramLanguage)) {
        dispatch(overrideLanguage(paramLanguage));
      }

      // When scanning a gift card QR code, we only get the language, so we need to guess the region based on that.
      switch (paramLanguage) {
        case "fi":
          dispatch(overrideRegion("fi"));
          break;
        case "et":
          dispatch(overrideRegion("ee"));
          break;
        case "lv":
          dispatch(overrideRegion("lv"));
          break;
        case "lt":
          dispatch(overrideRegion("lt"));
          break;
      }
    }

    searchParams.delete(LanguageQP);
    window.history.replaceState(
      null,
      "",
      searchParams.size > 0
        ? `${window.location.pathname}?${searchParams.toString()}`
        : window.location.pathname,
    );
  };

  useEffect(() => {
    const lng = window.navigator.language.split("-");
    const locale = lng.at(0);
    if (searchParams?.has(LanguageQP)) {
      handleSearchParamLanguage();
    } else if (
      isGeneralDomain &&
      !hasLanguageOverride &&
      locale &&
      isLanguageValid(locale)
    ) {
      dispatch(overrideLanguage(locale));
    }

    setIsLocaleLoaded(true);
  }, []);

  return {
    isLocaleLoaded,
  };
};
export default useLocaleOverride;
