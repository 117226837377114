import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EditIcon from "@assets/svg/edit.svg";
import MyGiftCardIcon from "@assets/svg/gift_cards_icon.svg";
import {
  ButtonTile,
  H5,
  Icon,
  Link,
  P,
  Section,
} from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import { getApolloLogoWithStartURLByLanguage } from "@base/utils/iconUtil";
import { getPrimaryEmailValue } from "@base/utils/userUtil";
import { PROFILE_ICON, QR_ICON } from "@constants/blobIcons";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@constants/googleAnalytics";
import { NavigationPath } from "@constants/navigation";
import MyCard from "@pages/HomePage/components/MyApolloClubCard/MyCard";
import Avatar from "@pages/ProfilePage/components/Avatar";
import LoyaltyPrograms from "@pages/ProfilePage/components/LoyaltyPrograms";
import {
  MyInfoBlockProps,
  useMyInfoBlockStyles,
} from "@pages/ProfilePage/components/MyInfoBlock/index";
import { useAppSelector } from "@store/hooks";
import { selectUserData } from "@store/user";
import { getTheme } from "@theme";

const MyInfoBlockMobile: React.FC<MyInfoBlockProps> = ({
  title,
  subTitle,
  showCard = false,
  fullProfile = false,
  minimal = false,
  openCardModal,
}) => {
  const classes = useMyInfoBlockStyles();
  const { color } = getTheme();
  const { t } = useTranslation();
  const userData = useAppSelector(selectUserData);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  if (showCard) {
    return (
      <>
        <MyCard />
        <Col col={12} colMd={6}>
          <Row noMargin>
            <Col col={6} alignCenter noMarginTop>
              <ButtonTile
                icon={<MyGiftCardIcon style={{ height: 28 }} />}
                action={() => navigate(NavigationPath.GiftCard)}
              >
                {t("my_gift_cards")}
              </ButtonTile>
            </Col>
            <Col col={6} alignCenter noMarginTop>
              <ButtonTile
                icon={
                  <Icon
                    url={getApolloLogoWithStartURLByLanguage(i18n.language)}
                    width={98}
                    height={52}
                  />
                }
                action={() => navigate(NavigationPath.MyApolloClub)}
              />
            </Col>
          </Row>
        </Col>
      </>
    );
  }

  if (minimal) {
    return null;
  }

  return (
    <Section iconURL={PROFILE_ICON} title={title} subTitle={subTitle}>
      <Row noMargin className={classes.wrapperMobile}>
        <Col col={12} noMargin>
          <Col col={7} directionColumn noMargin>
            <Col
              col={12}
              directionColumn
              noMargin
              className={classes.nameWrapper}
            >
              <H5 gray2 noMargin>
                {userData?.firstName} {userData?.lastName}
              </H5>
              {!fullProfile && (
                <Link
                  brandColor
                  navigateTo={NavigationPath.EditProfile}
                  suffixIcon={<EditIcon />}
                >
                  {t("action_edit")}
                </Link>
              )}
            </Col>
            <LoyaltyPrograms />
          </Col>
          <Avatar />
        </Col>
        {fullProfile && (
          <>
            <Col col={12} directionColumn>
              <P noMargin gray3 xSmall>
                {t("primary_email")}
              </P>
              <P noMargin gray2>
                {getPrimaryEmailValue(userData)}
              </P>
            </Col>
            <Col col={12}>
              <Link navigateTo={NavigationPath.Profile} brandColor>
                {t("link_full_profile")}
              </Link>
            </Col>
          </>
        )}
        {!fullProfile && (
          <Col col={12} directionColumn noMargin>
            <Col col={12} noMargin>
              <div
                className={classes.qrImageContainer}
                onClick={openCardModal}
                {...GOOGLE_ANALYTICS_ATTRIBUTES.QR_CORE}
              >
                <Icon
                  url={QR_ICON}
                  width={60}
                  height={60}
                  stroke={color.gray2}
                />
              </div>
            </Col>
          </Col>
        )}
      </Row>
    </Section>
  );
};

export default MyInfoBlockMobile;
