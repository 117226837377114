import React, { CSSProperties } from "react";
import { TextComponent, TextStyleProps } from "@base/components/Global";
import { MarginProps } from "@base/components/Layout/constants";
import { createUseStyles } from "@theme";
import classnames from "classnames";

export type StyleProps = TextStyleProps &
  MarginProps & {
    xxSmall?: boolean;
    xSmall?: boolean;
    small?: boolean;
  };

const useStyles = createUseStyles<StyleProps>(({ color, font, spacing }) => ({
  p: {
    margin: 0,
    padding: 0,
    "& p, ul": {
      margin: 0,
      paddingTop: ({ noMargin, noVerticalMargin, noMarginTop }) =>
        noMargin || noVerticalMargin || noMarginTop ? 0 : spacing.s,
      paddingBottom: ({ noMargin, noVerticalMargin, noMarginBottom }) =>
        noMargin || noVerticalMargin || noMarginBottom ? 0 : spacing.s,
      display: "inline-block",
      fontSize: ({ small, xSmall, xxSmall }) => {
        if (xxSmall) return font.size.x3s;
        if (xSmall) return font.size.xxs;
        if (small) return font.size.xs;
        return font.size.s;
      },
      lineHeight: ({ small, xSmall, xxSmall }) => {
        if (xxSmall) return font.lineHeight.xxs;
        if (xSmall) return font.lineHeight.xs;
        if (small) return font.lineHeight.s;
        return font.lineHeight.m;
      },
      color: color.gray2,
    },
  },
}));

type PProps = StyleProps & {
  children?: React.ReactNode | string;
  cmsContent?: string;
  className?: string;
  style?: CSSProperties;
};

const P: React.FC<PProps> = ({
  children,
  cmsContent,
  className,
  style,
  ...props
}) => {
  const classes = useStyles(props);

  if (cmsContent) {
    return (
      <div className={classnames([classes.p, className])} style={style}>
        <TextComponent cmsContent={cmsContent} {...props} />
      </div>
    );
  }

  return (
    <div className={classnames([classes.p, className])} style={style}>
      <TextComponent {...props}>
        <p>{children}</p>
      </TextComponent>
    </div>
  );
};

export default P;
