import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { H5, Loader, P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import { TRANSACTION_ICON } from "@constants/blobIcons";
import TransactionsList from "@pages/Transactions/components/TransactionsList";
import { useAppDispatch, useAppSelector } from "@store";
import { fetchTransactions, selectUserTransactions } from "@store/user";
import { isLoading, shouldLoad } from "@store/utils";

const TransactionsPage: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const transactions = useAppSelector(selectUserTransactions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated && shouldLoad(transactions)) {
      void dispatch(fetchTransactions());
    }
  }, [isAuthenticated, transactions]);

  if (!isAuthenticated) return null;

  if (isLoading(transactions) || shouldLoad(transactions))
    return <Loader isMinified />;

  return (
    <Row>
      <Col directionColumn>
        <Col noMargin directionColumn>
          <H5 prefixIconUrl={TRANSACTION_ICON} gray1>
            {t("transaction_page_title")}
          </H5>
          <P gray3 small noMarginTop>
            {t("transaction_page_subtitle")}
          </P>
        </Col>
        <TransactionsList transactions={transactions.array} noMargin />
      </Col>
    </Row>
  );
};

export default TransactionsPage;
