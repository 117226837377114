import React from "react";
import { IApolloIntroductionBlocks } from "@api/interfaces/apolloIntroductionLayout";
import { H5 } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import { APOLLO_STAR_ICON } from "@constants/blobIcons";
import IntroductionBlock from "@pages/HomePage/components/IntroductionBlocks/IntroductionBlock";

export const INTRO_BLOCK_ID = "apollo-club-introduction-container";

type IntroductionContents = {
  block?: IApolloIntroductionBlocks;
};

const IntroductionBlocks: React.FC<IntroductionContents> = ({ block }) =>
  block ? (
    <>
      {block?.title && (
        <Row id={INTRO_BLOCK_ID}>
          <Col col={12}>
            <H5 prefixIconUrl={APOLLO_STAR_ICON}>{block.title}</H5>
          </Col>
        </Row>
      )}
      {block?.introductionBlocks?.map((club, idx) => (
        <IntroductionBlock idx={idx} key={club.infoTitle} {...club} />
      ))}
    </>
  ) : null;

export default IntroductionBlocks;
