import { createUseStyles as ogCreateUseStyles } from "react-jss";
import commonTheme from "@theme/common";
import { Styles } from "jss";

import { BaseTheme } from "./baseTheme";

export const getTheme = (): BaseTheme => {
  return commonTheme;
};

export const createUseStyles = <Props = unknown, C extends string = string>(
  styles:
    | Styles<C, Props, BaseTheme>
    | ((theme: BaseTheme) => Styles<C, Props, undefined>),
) => ogCreateUseStyles<C, Props, BaseTheme>(styles);
