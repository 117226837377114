import { selectCurrentLanguage } from "@store/language";
import { AppStartListening } from "@store/listenerMiddleware";

import { perksRefreshing } from "./slice";

export const addPerksListeners = (startListening: AppStartListening) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      selectCurrentLanguage(currentState) !==
      selectCurrentLanguage(previousState),

    effect: (_action, { dispatch }) => {
      dispatch(perksRefreshing());
    },
  });
};
