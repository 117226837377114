import CMSController from "@api/controller/cmsController";
import { createApiGetAsyncThunk } from "@store/utils";

import {
  selectBusinesses,
  selectBusinessNewsletters,
  selectCloseAccountPage,
  selectClubPage,
  selectGoodbyePage,
  selectMenu,
  selectMyGiftCardPage,
  selectNewslettersPage,
  selectPrivateHomePage,
  selectProfilePage,
  selectPublicHomePage,
  selectTermsPage,
} from "./selectors";

export const fetchPublicHomePage = createApiGetAsyncThunk(
  "CMS/fetchPublicHomePage",
  CMSController.fetchPublicHomePage,
  selectPublicHomePage,
);

export const fetchPrivateHomePage = createApiGetAsyncThunk(
  "CMS/fetchPrivateHomePage",
  CMSController.fetchPrivateHomePage,
  selectPrivateHomePage,
);

export const fetchProfilePage = createApiGetAsyncThunk(
  "CMS/fetchProfilePage",
  CMSController.fetchMyInfoPage,
  selectProfilePage,
);

export const fetchMenu = createApiGetAsyncThunk(
  "CMS/fetchMenu",
  CMSController.fetchMenu,
  selectMenu,
);

export const fetchClubPage = createApiGetAsyncThunk(
  "CMS/fetchClubPage",
  CMSController.fetchMyApolloClubPage,
  selectClubPage,
);

export const fetchBusinesses = createApiGetAsyncThunk(
  "CMS/fetchBusinesses",
  CMSController.fetchBusinesses,
  selectBusinesses,
);

export const fetchMyGiftCardPage = createApiGetAsyncThunk(
  "CMS/fetchMyGiftCardPage",
  CMSController.fetchMyGiftCardsPage,
  selectMyGiftCardPage,
);

export const fetchTermsPage = createApiGetAsyncThunk(
  "CMS/fetchTermsPage",
  CMSController.fetchTermsPage,
  selectTermsPage,
);

export const fetchNewslettersPage = createApiGetAsyncThunk(
  "CMS/fetchNewslettersPage",
  CMSController.fetchNewslettersPage,
  selectNewslettersPage,
);

export const fetchCloseAccountPage = createApiGetAsyncThunk(
  "CMS/fetchCloseAccountPage",
  CMSController.fetchCloseAccountPage,
  selectCloseAccountPage,
);

export const fetchGoodbyePage = createApiGetAsyncThunk(
  "CMS/fetchGoodbyePage",
  CMSController.fetchGoodbyePage,
  selectGoodbyePage,
);

export const fetchBusinessNewsletters = createApiGetAsyncThunk(
  "CMS/fetchBusinessNewsletters",
  CMSController.fetchBusinessNewsletters,
  selectBusinessNewsletters,
);
