import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddressType, IAddress } from "@api/interfaces/userLayouts";
import { H5, P } from "@base/components/Global";
import Button from "@base/components/Global/Button";
import { Col } from "@base/components/Layout";
import { isAddressOfType } from "@base/utils/userUtil";
import AddressText from "@pages/Address/AddressText";
import { getAddressLabels } from "@pages/Address/helpers";
import { createUseStyles } from "@theme";
import { useUser } from "src/base/components/UserProvider";

const useStyles = createUseStyles(({ spacing }) => ({
  btn: {
    padding: [spacing.xxl, 0],
    gap: spacing.s,
  },
}));

type Props = {
  type: AddressType;
  address?: IAddress;
};

const AddressBlockSingle: React.FC<Props> = ({ address, type }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { title, subTitle } = getAddressLabels(type, address);
  const [{ setAddressPrimaryBilling, setAddressPrimaryShipping }] = useUser();
  const [isLoadingSetShippingAddress, setIsLoadingSetShippingAddress] =
    useState(false);
  const [isLoadingSetBillingAddress, setIsLoadingSetBillingAddress] =
    useState(false);

  if (!address) return null;

  const isPrimaryBilling = isAddressOfType(AddressType.BILLING, address);
  const isPrimaryShipping = isAddressOfType(AddressType.SHIPPING, address);

  return (
    <>
      <Col noVerticalMargin col={12}>
        {/* @ts-expect-error: waits for translations to accept dynamic keys */}
        <H5 gray1>{t(title)}</H5>
      </Col>
      <Col noMarginTop col={12}>
        <P noMarginTop small gray3>
          {/* @ts-expect-error: waits for translations to accept dynamic keys */}
          {t(subTitle)}
        </P>
      </Col>
      <AddressText {...address} />
      <Col col={12} colMd={8} className={classes.btn} directionColumn>
        {!isPrimaryBilling && (
          <Button
            transparentBrand
            isLoading={isLoadingSetBillingAddress}
            action={async () => {
              setIsLoadingSetBillingAddress(true);
              await setAddressPrimaryBilling(address);
              setIsLoadingSetBillingAddress(true);
            }}
          >
            {t("make_primary_billing_address")}
          </Button>
        )}
        {!isPrimaryShipping && (
          <Button
            transparentBrand
            isLoading={isLoadingSetShippingAddress}
            action={async () => {
              setIsLoadingSetShippingAddress(true);
              await setAddressPrimaryShipping(address);
              setIsLoadingSetShippingAddress(false);
            }}
          >
            {t("make_primary_shipping_address")}
          </Button>
        )}
      </Col>
    </>
  );
};

export default AddressBlockSingle;
