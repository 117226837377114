import React from "react";
import CheckedCheckboxIcon from "@assets/svg/checked_checkbox.svg";
import EmptyCheckboxIcon from "@assets/svg/empty_checkbox.svg";
import CheckboxMaterial from "@mui/material/Checkbox";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles({
  checkbox: {
    left: -9, // custom px from @mui library
  },
});

type CheckboxType = {
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxType> = React.forwardRef<
  HTMLButtonElement,
  CheckboxType
>(function Checkbox(props: CheckboxType, ref) {
  const classes = useStyles();

  return (
    <CheckboxMaterial
      ref={ref}
      icon={<EmptyCheckboxIcon />}
      checkedIcon={<CheckedCheckboxIcon />}
      className={classes.checkbox}
      id={props.name}
      {...props}
    />
  );
});

export default Checkbox;
