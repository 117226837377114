import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserController from "@api/controller/userController";
import TextField from "@base/components/Form/TextField";
import { Button, H5, P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import SimpleModalWrapper from "@base/components/Modal/SimpleModalWrapper";
import { validateEmail } from "@base/utils/userUtil";
import { BACK_CIRCLE_ICON } from "@constants/blobIcons";
import { NavigationPath } from "@constants/navigation";
import { createUseStyles } from "@theme";
import { useMediaQuery } from "src/base/components/MediaQueryProvider";

const useStyles = createUseStyles(({ spacing, sizes }) => ({
  btnContainer: {
    alignSelf: "flex-start",
    [sizes.md]: {
      marginTop: spacing.m,
    },
  },
  emailContainer: {
    [sizes.md]: {
      marginTop: spacing.x4l,
    },
  },
}));

const RequestResetPasswordPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentModal, setCurrentModal] = useState<"success" | "error">();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState<string>();
  const [{ isCurrentSizeMd }] = useMediaQuery();

  const onModalClose = () => {
    setCurrentModal(undefined);
    if (currentModal === "success") {
      navigate(NavigationPath.Home);
    }
  };

  const onSubmit = async () => {
    if (!email) return;
    setIsSubmitting(true);
    const { isResponseOk } = await UserController.requestPasswordReset({
      body: { email },
    });
    setCurrentModal(isResponseOk ? "success" : "error");
    setIsSubmitting(false);
  };

  return (
    <>
      <Row>
        <Col fullWidth noMargin alignCenter>
          <Col directionColumn fullWidth noMargin colMd={9}>
            <Col noMarginBottom>
              <H5 prefixIconUrl={BACK_CIRCLE_ICON} gray1>
                {t("reset_password_page_title")}
              </H5>
            </Col>
            <Col noMarginTop>
              <P noMarginTop small gray3>
                {t("reset_password_page_subtitle")}
              </P>
            </Col>
            <Col fullWidth>
              <P>{t("reset_password_page_content")}</P>
            </Col>
            <Col
              noMargin
              fullWidth
              directionColumn={!isCurrentSizeMd}
              className={classes.emailContainer}
            >
              <Col noMargin directionColumn col={12} colMd={6} fullWidth>
                <Col fullWidth>
                  <TextField
                    label={t("field_enter_email_label")}
                    id="email-input-bar"
                    autoComplete="email"
                    type="email"
                    submitOnEnter
                    placeholder={t("field_email_placeholder")}
                    onChange={(e) => setEmail(e.target.value)}
                    isDisabled={isSubmitting}
                  />
                </Col>
                <Col fullWidth>
                  <P gray3 xSmall>
                    {t("reset_password_page_email_description")}
                  </P>
                </Col>
              </Col>
              <Col
                fullWidth
                colMd={6}
                col={12}
                className={classes.btnContainer}
              >
                <Button
                  action={onSubmit}
                  isDisabled={!validateEmail(email ?? "")}
                  isLoading={isSubmitting}
                >
                  {t("reset_password_page_recover_btn")}
                </Button>
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>
      <SimpleModalWrapper
        isOpen={currentModal === "success"}
        title={t("reset_password_page_success_modal_title")}
        onClose={onModalClose}
        content={t("reset_password_page_success_modal_content")}
      />
      <SimpleModalWrapper
        isOpen={currentModal === "error"}
        title={t("reset_password_page_error_modal_title")}
        onClose={onModalClose}
        content={t("reset_password_page_error_modal_content")}
      />
    </>
  );
};

export default RequestResetPasswordPage;
