import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LeftArrow from "@assets/svg/left-arrow.svg";
import Menu from "@assets/svg/menu.svg";
import { Icon, Link } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import { useSharedTopBarStyles } from "@base/components/TopBar";
import {
  APOLLO_STAR_ICON,
  BURGER_NOTIFICATION_ICON,
} from "@constants/blobIcons";
import { NavigationPath } from "@constants/navigation";
import { useAppSelector } from "@store";
import { selectHasMenuNotifications } from "@store/cms";
import classnames from "classnames";

export type BlackTopBarProps = {
  businessLogoAction: () => void;
  shouldHide: boolean;
  shouldReveal: boolean;
  openMenu: () => void;
};

const MobileBlackTopBar: React.FC<BlackTopBarProps> = ({
  businessLogoAction,
  shouldHide,
  shouldReveal,
  openMenu,
}) => {
  const classes = useSharedTopBarStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const hasMenuNotifications = useAppSelector(selectHasMenuNotifications);

  const [shouldShowBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    setShowBackButton(
      pathname !== NavigationPath.Home &&
        pathname !== NavigationPath.AuthComplete,
    );
  }, [pathname]);

  return (
    <div
      className={classnames([
        classes.headerContainer,
        shouldHide && classes.hide,
        shouldReveal && classes.reveal,
      ])}
    >
      <Row noMargin>
        <Col col={3} noVerticalMargin>
          {shouldShowBackButton ? (
            <Link white flex action={() => navigate(-1)}>
              <LeftArrow />
            </Link>
          ) : (
            <Link flex action={businessLogoAction}>
              <Icon
                url={APOLLO_STAR_ICON}
                className={classes.startIcon}
                width={22}
                height={22}
              />
            </Link>
          )}
        </Col>

        <Col alignCenter className={classes.pageTitle} noVerticalMargin>
          {t("global_title")}
        </Col>

        <Col alignRight col={3} colMd={1} noVerticalMargin>
          <Link flex action={openMenu}>
            {hasMenuNotifications ? (
              <Icon width={48} height={48} url={BURGER_NOTIFICATION_ICON} />
            ) : (
              <Menu />
            )}
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default MobileBlackTopBar;
