import { IPerk } from "@api/interfaces/perkLayouts";
import { createSelector } from "@reduxjs/toolkit";
import { hasData } from "@store/utils";

import { selectPerks } from "./slice";
import { groupPerks } from "./utils";

export const selectGroupedPerks = createSelector(
  selectPerks,
  (perks): IPerk[] | null => (hasData(perks) ? groupPerks(perks.array) : null),
);
