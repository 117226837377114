import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IApolloIntroductionBlock } from "@api/interfaces/apolloIntroductionLayout";
import ArrowDownIcon from "@assets/svg/full-arrow-down.svg";
import { Button, H4, H5, P } from "@base/components/Global";
import { Col, Row, RowBreak } from "@base/components/Layout";
import PictureWithSlogan from "@pages/HomePage/components/IntroductionBlocks/PictureWithSlogan";
import { createUseStyles } from "@theme";

type ApolloClubItemProps = IApolloIntroductionBlock & {
  idx: number;
};

const useStyles = createUseStyles(({ spacing, sizes }) => ({
  customTextStyles: {
    "& p": {
      paddingTop: spacing.m,
      paddingBottom: spacing.m,
    },
  },
  left: {
    paddingRight: spacing.s,
  },
  right: {
    paddingLeft: spacing.s,
  },
  [sizes.lg]: {
    left: {
      paddingRight: spacing.l,
    },
    right: {
      paddingLeft: spacing.l,
    },
  },
}));

const IntroductionBlock: React.FC<ApolloClubItemProps> = ({
  idx,
  slogan,
  rule,
  iconURL,
  imageURL,
  infoTitle,
  shortInfo,
  detailInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const isIndexEven = !(idx % 2);

  if (
    !(
      slogan ||
      rule ||
      iconURL ||
      imageURL ||
      infoTitle ||
      shortInfo ||
      detailInfo
    )
  ) {
    return null;
  }

  return (
    <>
      <Row id={`gift-container-${idx}`}>
        <Col
          col={12}
          colMd={6}
          orderMd={isIndexEven ? 1 : 2}
          className={isIndexEven ? classes.left : classes.right}
        >
          <PictureWithSlogan
            slogan={slogan}
            rule={rule}
            iconURL={iconURL}
            imageURL={imageURL}
            isLocatedRight={isIndexEven}
          />
        </Col>
        <Col
          col={12}
          colMd={6}
          orderMd={isIndexEven ? 2 : 1}
          directionColumn
          className={isIndexEven ? classes.right : classes.left}
        >
          <Row noMargin>
            <Col col={12} noHorizontalMargin>
              <H4>{infoTitle}</H4>
            </Col>
            <Col
              col={12}
              noHorizontalMargin
              className={classes.customTextStyles}
            >
              <P black cmsContent={shortInfo} />
            </Col>
            <Col col={12} noHorizontalMargin hide={!detailInfo}>
              <Button
                transparentBlack
                action={toggleIsOpen}
                id="gift-info-btn"
                suffixIcon={
                  isOpen ? (
                    <ArrowDownIcon style={{ transform: "rotate(180deg)" }} />
                  ) : (
                    <ArrowDownIcon />
                  )
                }
              >
                {t("gift_info")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col hide={!isOpen} noMargin col={12} orderMd={3}>
          <Row noMargin id="gift-information">
            <Col col={12} noMarginBottom>
              <H5 noMarginBottom>{slogan}</H5>
            </Col>
            <Col col={12} directionColumn noMarginTop>
              <P black cmsContent={detailInfo} />
            </Col>
          </Row>
        </Col>
      </Row>
      <RowBreak />
    </>
  );
};

export default IntroductionBlock;
