import { ILocationsBlock } from "@api/interfaces/locationPageLayout";

export const getUniqueBusinessTypes = (locationsBlocks: ILocationsBlock[]) => {
  const uniqueBusinessTypes: { name: string; key: string }[] = [];

  for (const locationsBlock of locationsBlocks) {
    if (
      !uniqueBusinessTypes.some(
        (i) => i.key === locationsBlock.businessSystemKey,
      ) &&
      locationsBlock.businessSystemKey &&
      locationsBlock.businessTypeName
    ) {
      uniqueBusinessTypes.push({
        name: locationsBlock.businessTypeName,
        key: locationsBlock.businessSystemKey,
      });
    }
  }

  return uniqueBusinessTypes;
};

export const getUniqueRegionsForBusinessType = (
  locationsBlocks: ILocationsBlock[],
) => {
  const uniqueRegions: { name: string; key: string }[] = [];

  for (const locationsBlock of locationsBlocks) {
    if (
      !uniqueRegions.some((i) => i.key === locationsBlock.regionSystemKey) &&
      locationsBlock.regionSystemKey &&
      locationsBlock.regionName
    ) {
      uniqueRegions.push({
        name: locationsBlock.regionName,
        key: locationsBlock.regionSystemKey,
      });
    }
  }

  return uniqueRegions;
};

export const getUniqueCitiesForRegionAndBusinessType = (
  locationsBlocks: ILocationsBlock[],
  region: string | null,
) => {
  const uniqueCities: { name: string; key: string }[] = [];

  const filteredLocationsBlocks = locationsBlocks.filter((loc) => {
    if (!region) {
      return true;
    }

    return loc.regionSystemKey === region;
  });
  for (const locationsBlock of filteredLocationsBlocks) {
    if (
      !uniqueCities.some((i) => i.key === locationsBlock.citySystemKey) &&
      locationsBlock.citySystemKey &&
      locationsBlock.cityName
    ) {
      uniqueCities.push({
        name: locationsBlock.cityName,
        key: locationsBlock.citySystemKey,
      });
    }
  }

  return uniqueCities;
};
