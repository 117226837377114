import React from "react";
import { PrefixIcon, SuffixIcon } from "@base/components/Global";

type TextWithIconProps = {
  children?: React.ReactNode;
  prefixIconUrl?: string;
  prefixIcon?: React.ReactNode;
  suffixIconUrl?: string;
  suffixIcon?: React.ReactNode;
  isActive?: boolean;
};

const TextWithIcon: React.FC<TextWithIconProps> = ({
  children,
  prefixIconUrl,
  prefixIcon,
  suffixIconUrl,
  suffixIcon,
  isActive = false,
}) => (
  <>
    <PrefixIcon url={prefixIconUrl} icon={prefixIcon} isActive={isActive} />
    {children}
    <SuffixIcon url={suffixIconUrl} icon={suffixIcon} isActive={isActive} />
  </>
);

export default TextWithIcon;
