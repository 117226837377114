import { MutableRefObject, useEffect } from "react";

const useOnClickOutside = (
  ref: MutableRefObject<HTMLDivElement | null>,
  handler: (event: Event) => void,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node | null)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mouseup", listener);
    document.addEventListener("touchsend", listener);
    return () => {
      document.removeEventListener("mouseup", listener);
      document.removeEventListener("touchend", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
