import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { ContactStatus, IEmail } from "@api/interfaces/userLayouts";
import EditFormPage from "@base/components/Form/EditFormPage";
import PrimarySecondaryForm from "@base/components/Form/EditFormPage/PrimarySecondaryForm";
import { FieldTypePrimarySecondaryForm } from "@base/components/Form/EditFormPage/types";
import ModalArray from "@base/components/Modal/ModalArray";
import { EMAIL_ICON } from "@constants/blobIcons";
import config from "@constants/config";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@constants/googleAnalytics";
import { NavigationPath } from "@constants/navigation";
import EmailField from "@pages/Email/EmailField";
import { EmailsForm, EmailValue } from "@pages/Email/types";
import { useAppSelector } from "@store/hooks";
import { selectUserData } from "@store/user";
import { useUser } from "src/base/components/UserProvider";

const EmailsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const [{ removeEmail, updateEmail, setPrimaryEmail }] = useUser();
  const { isLoading } = useAuth();

  const [isLoaded, setIsLoaded] = useState(false);
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const { control, getValues } = useForm<EmailsForm>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "emails",
  });

  useEffect(() => {
    // Initial load when we have no fields
    if (
      fields?.length === 0 &&
      userData?.emails &&
      userData.emails.length > 0 &&
      !isLoaded
    ) {
      append(
        userData.emails.map((email) => ({
          // Using key here as ID is a reserved name and gets an autogenerated value
          key: email.id || 0,
          value: email.email,
          isPrimary: email.isPrimary,
          status: email.status || ContactStatus.UNSPECIFIED,
        })),
      );
      setIsLoaded(true);
    }

    if (!isLoading && userData?.emails && userData.emails.length === 0) {
      // Not loading & have no data to edit
      navigate(NavigationPath.Profile);
    }
  }, [userData]);

  const updateValue = async (
    index: number,
    newValue: string,
    isPrimary: boolean,
  ) => {
    const id = getValues().emails[index].key;

    const email: IEmail = {
      id,
      email: newValue,
      isPrimary,
    };
    const isSuccess = await updateEmail(email);
    const isSuccessPrimary = isPrimary ? await setPrimaryEmail(id) : true;

    if (isSuccess && isSuccessPrimary) {
      if (isPrimary) {
        for (const [idx, field] of fields.entries()) {
          if (field.isPrimary) {
            update(idx, {
              ...field,
              isPrimary: false,
            });
          }
        }
      }

      update(index, {
        id,
        key: id,
        value: newValue,
        isPrimary,
        status: getValues().emails[index].status,
      });
      setCurrentModal(isPrimary ? "primarySuccessModal" : "editSuccessModal");
    } else {
      setCurrentModal("errorModal");
    }
  };

  const deleteValue = async (index: number) => {
    const email: EmailValue = getValues().emails[index];
    const isSuccess = await removeEmail(email.key);
    if (isSuccess) {
      remove(index);
      setCurrentModal("removeSuccessModal");
    } else {
      setCurrentModal("removeErrorModal");
    }
  };

  const fieldsArray = [...fields] as FieldTypePrimarySecondaryForm[];

  return (
    <EditFormPage
      isLoading={!fieldsArray || Object.keys(fieldsArray).length === 0}
      form={{
        headingIconUrl: EMAIL_ICON,
        headingTitle: t("edit_email_header"),
        headingSubTitle: t("edit_email_subtitle"),
        content: t("edit_email_helper_text"),
        addRow: t("add_email_address"),
        addRowAction: () => navigate(NavigationPath.AddEmail),
        hasMaxRowsReached: fieldsArray.length >= config.appConfig.maxEmailCount,
        maxRowsReachedText: t("max_emails_reached"),
        goBackText: t("go_to_profile"),
        goBackAction: () => navigate(NavigationPath.Profile),
      }}
    >
      <ModalArray
        modals={{
          primarySuccessModal: {
            title: t("modal_primary_email_update_title"),
            subTitle: t("modal_primary_email_update_subtitle"),
            content: t("modal_primary_email_update_content"),
          },
          editSuccessModal: {
            title: t("modal_email_edited_title"),
            subTitle: t("modal_email_edited_subtitle"),
            content: t("modal_email_edited_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.EMAIL_CHANGE_SUCCESS,
          },
          errorModal: {
            title: t("modal_email_error_title"),
            subTitle: t("modal_email_error_subtitle"),
            content: t("modal_email_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.EMAIL_CHANGE_DECLINE,
          },
          removeSuccessModal: {
            title: t("modal_email_removed_title"),
            subTitle: t("modal_email_removed_subtitle"),
            content: t("modal_email_removed_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_EMAIL_SUCCESS,
          },
          removeErrorModal: {
            title: t("modal_email_remove_error_title"),
            subTitle: t("modal_email_remove_error_subtitle"),
            content: t("modal_email_remove_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_EMAIL_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={() => {
          setCurrentModal(null);
        }}
      />
      <PrimarySecondaryForm
        fields={fieldsArray}
        fieldsText={{
          primaryTitle: t("edit_email_primary_address"),
          primaryLabel: t("field_edit_primary_email_label"),
          secondaryTitle: t("edit_email_secondary_address"),
          secondaryLabel: t("field_edit_secondary_email_label"),
        }}
        fieldComponent={({ index, label, isDisabled }) => (
          <EmailField
            id={getValues().emails[index].key}
            label={label}
            value={getValues().emails[index].value}
            isDisabled={isDisabled}
            saveEmail={(newValue, isPrimary) =>
              updateValue(index, newValue, isPrimary)
            }
            deleteEmail={() => deleteValue(index)}
            emailStatus={getValues().emails[index].status}
          />
        )}
      />
    </EditFormPage>
  );
};

export default EmailsPage;
