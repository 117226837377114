import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CountryData,
  CountryIso2,
  parseCountry,
} from "react-international-phone";
import { DROPDOWN_BUTTON_ID } from "@base/components/Form/PhoneField";
import DropdownItem from "@base/components/Form/PhoneField/DropdownItem";
import { getCountrySearchResult } from "@base/components/Form/PhoneField/getCountrySearchResult";
import useOnClickOutside from "@base/hooks/useOnClickOutside";
import { createUseStyles } from "@theme";
import clasNames from "classnames";

const useStyles = createUseStyles(
  ({ color, zIndex, spacing, borderRadius }) => ({
    container: {
      display: "flex",
      position: "relative",
    },
    countryContainer: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      width: 50,
    },
    svg: {
      height: "100%",
      border: `1px solid ${color.gray3}`,
    },
    arrow: {
      width: 0,
      height: 0,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
    },
    arrowUp: {
      borderBottom: `5px solid ${color.black}`,
    },
    arrowDown: {
      borderTop: `5px solid ${color.black}`,
    },
    dropdown: {
      display: "none",
      zIndex: zIndex.dropdown,
      position: "absolute",
      top: "calc(100% - 26px)",
      width: "100%",
      height: 300,
      maxHeight: "25vh",
      backgroundColor: color.white,
      border: `2px solid ${color.primaryBrand}`,
      borderTop: "none",
      overflow: "scroll",
      borderBottomLeftRadius: borderRadius.s,
      borderBottomRightRadius: borderRadius.s,
    },
    open: {
      display: "block",
    },
    searchInput: {
      border: "none",
      borderTop: `1px solid ${color.primaryBrand}`,
      borderBottom: `1px solid ${color.primaryBrand}`,
      marginTop: spacing.xs,
      marginBottom: spacing.xs,
      width: "100%",
      paddingTop: spacing.l,
      paddingBottom: spacing.l,
      paddingLeft: spacing.m,
      paddingRight: spacing.m,
      outline: "none",
    },
  }),
);

type DropdownBlockProps = {
  countries: CountryData[];
  setCountry: (country: CountryIso2) => void;
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
};

const DropdownBlock: React.FC<DropdownBlockProps> = ({
  countries,
  setCountry,
  setOpen,
  isOpen,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useOnClickOutside(dropdownRef, (event: Event) => {
    let shouldSkip = false;
    const pathElements = event.composedPath() as HTMLElement[];
    for (const pathElement of pathElements) {
      if (pathElement.id === DROPDOWN_BUTTON_ID) shouldSkip = true;
    }
    if (!shouldSkip) {
      setOpen(false);
    }
  });

  const [search, setSearch] = useState("");
  const [currentCountryCodes, setCurrentCountryCodes] =
    useState<CountryData[]>(countries);

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    }
  }, [isOpen]);

  useEffect(() => {
    const filteredCountryCodes = getCountrySearchResult(search, countries);
    if (filteredCountryCodes.length === 1) {
      const { iso2 } = parseCountry(filteredCountryCodes[0]);
      setCountry(iso2);
      setOpen(false);
    } else {
      setCurrentCountryCodes(filteredCountryCodes);
    }
  }, [search]);

  return (
    <div
      className={clasNames([classes.dropdown, isOpen && classes.open])}
      ref={dropdownRef}
    >
      <input
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        type="text"
        value={search}
        autoFocus
        placeholder={t("field_country_code_search")}
        className={classes.searchInput}
      />
      {currentCountryCodes.map((country) => (
        <DropdownItem
          key={country[1]}
          country={country}
          setCountry={setCountry}
        />
      ))}
    </div>
  );
};

export default DropdownBlock;
