import React from "react";
import { ReactSVG } from "react-svg";
import { createUseStyles } from "@theme";
import classnames from "classnames";

type StyleProps = {
  url?: string;
  stroke?: string;
  width?: number | string;
  height?: number | string;
};

const useStyles = createUseStyles<StyleProps>({
  svgIcon: {
    "& svg": {
      stroke: ({ stroke }) => (stroke?.length ? stroke : undefined),
    },
    "& svg path": {
      stroke: ({ stroke }) => (stroke?.length ? stroke : undefined),
    },
  },
  iconContainer: {
    width: ({ width }) => width || 24,
    height: ({ height }) => height || 24,
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
    lineHeight: 0,
  },
});

const applyStylesToElement = (
  element: Element,
  attributeName: string,
  value: string,
) => {
  let child = element.firstElementChild;
  while (child != null) {
    child.setAttribute(attributeName, value);
    if (child.hasChildNodes()) {
      applyStylesToElement(child, attributeName, value);
    }
    child = child.nextElementSibling;
  }
};

// If element has noFill id, do not apply styles.
// This is an override. Otherwise, all the parents and siblings get a fill/stroke.
const NO_FILL_ID = "NOFILL";

const applyStylesToSVG = (
  svg: SVGSVGElement,
  attributeName: string,
  value: string,
) => {
  svg.setAttribute(attributeName, value);
  let child = svg.firstElementChild;
  while (child != null) {
    child.setAttribute(attributeName, value);
    const id = child.id;
    if (child.hasChildNodes() && id !== NO_FILL_ID) {
      applyStylesToElement(child, attributeName, value);
    }
    child = child.nextElementSibling;
  }
};

type IconProps = StyleProps & {
  className?: string;
  icon?: React.ReactNode;
  stroke?: string;
  fill?: string;
  onClick?: () => void;
};

const Icon: React.FC<IconProps> = ({
  url,
  icon,
  stroke,
  fill,
  width,
  height,
  className,
  onClick,
}) => {
  const classes = useStyles({ url, stroke, width, height });

  if (icon) {
    return (
      <span
        className={classnames([
          classes.iconContainer,
          classes.svgIcon,
          className,
        ])}
        onClick={onClick}
      >
        {icon}
      </span>
    );
  }

  if (url) {
    return (
      <ReactSVG
        beforeInjection={(svg) => {
          if (stroke) applyStylesToSVG(svg, "stroke", stroke);
          if (fill) applyStylesToSVG(svg, "fill", fill);
          if (width) svg.setAttribute("width", `${width}`);
          if (height) svg.setAttribute("height", `${height}`);
        }}
        className={classnames([classes.iconContainer, className])}
        evalScripts="always"
        httpRequestWithCredentials={false}
        renumerateIRIElements={false}
        src={url}
        useRequestCache={true}
        onClick={onClick}
      />
    );
  }

  return null;
};

export default Icon;
