import React from "react";
import { VisibilityBlock } from "@base/components/Layout";
import {
  CMS_BREAK_ROW,
  CMS_BREAK_ROW_MOBILE,
  VERTICAL_GUTTER,
} from "@base/components/Layout/constants";
import { VisibilityProps } from "@base/components/Layout/VisibilityBlock";
import { createUseStyles } from "@theme";
import classNames from "classnames";

const useStyles = createUseStyles(({ sizes }) => ({
  cmsRowBreak: {
    width: "100%",
    height: CMS_BREAK_ROW_MOBILE - VERTICAL_GUTTER,
    display: "flex",
    [sizes.md]: {
      height: CMS_BREAK_ROW - VERTICAL_GUTTER,
    },
  },
  small: {
    height: CMS_BREAK_ROW_MOBILE - VERTICAL_GUTTER * 3,
  },
}));

type CMSRowBreakProps = VisibilityProps & {
  className?: string | number | boolean | null;
  isSmall?: boolean;
};

const CmsRowBreak: React.FC<CMSRowBreakProps> = ({
  className,
  isSmall = false,
  hide,
  belowSm,
  belowMd,
  belowLg,
  aboveSm,
  aboveMd,
  aboveLg,
  aboveXl,
}) => {
  const classes = useStyles();

  return (
    <VisibilityBlock
      hide={hide}
      belowSm={belowSm}
      belowMd={belowMd}
      belowLg={belowLg}
      aboveSm={aboveSm}
      aboveMd={aboveMd}
      aboveLg={aboveLg}
      aboveXl={aboveXl}
    >
      <div
        className={classNames([
          classes.cmsRowBreak,
          isSmall && classes.small,
          className,
        ])}
      />
    </VisibilityBlock>
  );
};

export default CmsRowBreak;
