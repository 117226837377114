import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TerritoryController from "@api/controller/territoryController";
import {
  AddressType,
  IAddress,
  IAddressType,
} from "@api/interfaces/userLayouts";
import CheckboxRow from "@base/components/Form/CheckboxRow";
import CountrySelect from "@base/components/Form/CountrySelect";
import { validation } from "@base/components/Form/helpers";
import TextField from "@base/components/Form/TextField";
import { Button, Icon, P } from "@base/components/Global";
import { Col } from "@base/components/Layout";
import Row from "@base/components/Layout/Row";
import ConfirmationModal from "@base/components/Modal/ConfirmationModal";
import { isAddressOfType } from "@base/utils/userUtil";
import { CLOSE_SMALL_ICON } from "@constants/blobIcons";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@constants/googleAnalytics";
import { yupResolver } from "@hookform/resolvers/yup";
import { getTheme } from "@theme";
import PhoneField from "src/base/components/Form/PhoneField";
import * as yup from "yup";

type AddressFieldType = {
  address?: IAddress;
  save?: (newAddress: IAddress) => Promise<void>;
  remove?: () => Promise<void>;
};

const validationSchema = yup
  .object({
    addressLine1: validation.required,
    postalCode: validation.required,
    country: validation.required,
    region: validation.required,
    addressLine2: yup.string(),
    city: yup.string(),
    countryId: yup.string(),
    contactName: yup.string(),
    contactPhone: yup.string(),
    contactEmail: yup.string().email(),
    cityDistrict: yup.string(),
    types: yup
      .array()
      .of(
        yup.object({
          isPrimary: yup.boolean().required(),
          type: yup.string().oneOf(Object.values(AddressType)).required(),
        }),
      )
      .required(),
    alias: yup.string(),
  })
  .required();

const AddressField: React.FC<AddressFieldType> = ({
  address,
  save,
  remove,
}) => {
  const { t } = useTranslation();
  const [isBilling, setIsBilling] = useState(
    isAddressOfType(AddressType.BILLING, address),
  );
  const [isShipping, setIsShipping] = useState(
    isAddressOfType(AddressType.SHIPPING, address),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { color } = getTheme();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitted }, // isValid, isDirty
  } = useForm<IAddress>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      addressLine1: address?.addressLine1 ?? "",
      addressLine2: address?.addressLine2 ?? "",
      city: address?.city ?? "",
      region: address?.region ?? "",
      postalCode: address?.postalCode ?? "",
      country: address?.country ?? "",
      countryId: address?.countryId ?? "0",
      contactName: address?.contactName ?? "",
      contactPhone: address?.contactPhone ?? "",
      contactEmail: address?.contactEmail ?? "",
      alias: address?.alias ?? t("my_address_alias"),
      types: [],
    },
  });
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);

  const hasError = (inputName: keyof IAddress) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  const onSubmit = async (data: IAddress) => {
    const addressTypes = [] as IAddressType[];
    if (isShipping) {
      addressTypes.push({
        isPrimary: true,
        type: AddressType.SHIPPING,
      });
    }
    if (isBilling) {
      addressTypes.push({
        isPrimary: true,
        type: AddressType.BILLING,
      });
    }
    if (!isBilling && !isShipping) {
      addressTypes.push({
        isPrimary: true,
        type: AddressType.MAILING,
      });
    }

    const newAddress = {
      ...address,
      ...data,
      types: addressTypes,
    } as IAddress;

    if (save) {
      setIsSubmitting(true);
      await save(newAddress);
      setIsSubmitting(false);
    }
  };

  const values = getValues();
  const { ref: contactPhoneRef, ...contactPhoneProps } =
    register("contactPhone");

  return (
    <>
      <ConfirmationModal
        isOpen={shouldShowDeleteModal}
        onClose={() => setShouldShowDeleteModal(false)}
        title={t("address_delete_confirmation_title")}
        content={t("address_delete_confirmation_content")}
        primaryAction={() => {
          if (remove) void remove();
        }}
        primaryTitle={t("address_delete_button")}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row noMarginRight noMarginLeft>
          <Col col={12}>
            <TextField
              label={t("field_addressLine1_label")}
              id="addressLine1-input"
              placeholder={t("field_addressLine1_placeholder")}
              helperText={
                hasError("addressLine1") && t("field_addressLine1_error")
              }
              hasError={hasError("addressLine1")}
              {...register("addressLine1")}
            />
          </Col>
          <Col col={12}>
            <TextField
              label={t("field_addressLine2_label")}
              id="addressLine2-input"
              placeholder={t("field_addressLine2_placeholder")}
              helperText={
                hasError("addressLine2") && t("field_addressLine2_error")
              }
              hasError={hasError("addressLine2")}
              {...register("addressLine2")}
            />
          </Col>
          <Col col={6}>
            <TextField
              label={t("field_postalCode_label")}
              id="postalCode-input"
              placeholder={t("field_postalCode_placeholder")}
              helperText={hasError("postalCode") && t("field_postalCode_error")}
              hasError={hasError("postalCode")}
              {...register("postalCode")}
            />
          </Col>
          <Col col={6}>
            <TextField
              label={t("field_city_label")}
              id="city-input"
              placeholder={t("field_city_placeholder")}
              helperText={hasError("city") && t("field_city_error")}
              hasError={hasError("city")}
              {...register("city")}
            />
          </Col>
          <Col col={12}>
            <TextField
              label={t("field_region_label")}
              id="region-input"
              placeholder={t("field_region_placeholder")}
              helperText={hasError("region") && t("field_region_error")}
              hasError={hasError("region")}
              {...register("region")}
            />
          </Col>
          <Col col={12}>
            <CountrySelect
              api={TerritoryController.getClientCountries}
              label={t("field_country_label")}
              id="country-input"
              helperText={hasError("country") && t("field_country_error")}
              hasError={hasError("country")}
              currentValue={values.countryId}
              setCurrentValue={({
                value,
                label,
              }: {
                value: string;
                label: string;
              }) => {
                setValue("countryId", value, { shouldDirty: true });
                setValue("country", label, { shouldDirty: true });
              }}
            />
          </Col>
          <Col col={12}>
            <TextField
              label={t("field_contactName_label")}
              id="contactName-input"
              placeholder={t("field_contactName_placeholder")}
              helperText={
                hasError("contactName") && t("field_contactName_error")
              }
              hasError={hasError("contactName")}
              {...register("contactName")}
            />
          </Col>
          <Col col={12}>
            <PhoneField
              value={values.contactPhone}
              label={t("field_contactPhone_label")}
              helperText={
                hasError("contactPhone") && t("field_contactPhone_error")
              }
              hasError={hasError("contactPhone")}
              innerRef={contactPhoneRef}
              {...contactPhoneProps}
            />
          </Col>
          <Col col={12}>
            <TextField
              label={t("field_contactEmail_label")}
              id="contactEmail-input"
              type="email"
              autoComplete="email"
              placeholder={t("field_contactEmail_placeholder")}
              helperText={
                hasError("contactEmail") && t("field_contactEmail_error")
              }
              hasError={hasError("contactEmail")}
              {...register("contactEmail")}
            />
          </Col>
          <Col col={12}>
            <TextField
              label={t("field_contactAlias_label")}
              id="contactAlias-input"
              placeholder={t("field_contactAlias_placeHolder")}
              helperText={hasError("alias") && t("field_contactAlias_error")}
              hasError={hasError("alias")}
              {...register("alias")}
            />
          </Col>
          {!remove && (
            <Col col={12}>
              <P>{t("address_form_helper")}</P>
            </Col>
          )}
          <Col col={12} directionColumn noVerticalMargin>
            <CheckboxRow
              label={t("checkbox_address_use_as_billing")}
              bold={false}
              slim
              small
              noBorder
              checked={isBilling}
              onChange={() => setIsBilling(!isBilling)}
            />
          </Col>
          <Col col={12} directionColumn noVerticalMargin>
            <CheckboxRow
              label={t("checkbox_address_use_as_shipping")}
              bold={false}
              slim
              small
              noBorder
              checked={isShipping}
              onChange={() => setIsShipping(!isShipping)}
            />
          </Col>
          {!remove && (
            <Col col={12}>
              <P>{t("address_explanation_billing_or_shipping")}</P>
            </Col>
          )}
          <Col noMargin>
            {remove && (
              <Col col={12}>
                <Button
                  transparentBrand
                  action={() => setShouldShowDeleteModal(true)}
                  prefixIcon={
                    <Icon url={CLOSE_SMALL_ICON} stroke={color.primaryBrand} />
                  }
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_ADDRESS
                  }
                >
                  {t("delete_address_button")}
                </Button>
              </Col>
            )}
            <Col col={12} colMd={6}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                analyticsAttributes={
                  remove
                    ? GOOGLE_ANALYTICS_ATTRIBUTES.ADDRESS_CHANGE
                    : GOOGLE_ANALYTICS_ATTRIBUTES.ADD_ADDRESS
                }
              >
                {t(remove ? "save_address_button" : "add_new_address_button")}
              </Button>
            </Col>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AddressField;
