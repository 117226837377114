import VerificationCodeController from "@api/controller/verificationCodeController";
import { VerificationCodeStatus } from "@api/interfaces/verificationCodeLayout";

export type VerificationCodeValidation = {
  isCodeValid: boolean;
  isCodeUsed: boolean;
  status?: VerificationCodeStatus;
};

const isOkCodeStatus = (status: VerificationCodeStatus): boolean =>
  status === VerificationCodeStatus.VALID ||
  status === VerificationCodeStatus.ALREADY_VALIDATED ||
  status === VerificationCodeStatus.ALREADY_VALIDATING;

const isCodeUsed = (status: VerificationCodeStatus): boolean =>
  status === VerificationCodeStatus.ALREADY_VALIDATED;

const verifyVerificationCode = async (
  code: string | null,
): Promise<VerificationCodeValidation> => {
  if (!code) {
    return {
      isCodeValid: false,
      isCodeUsed: false,
      status: VerificationCodeStatus.UNKNOWN,
    };
  }

  const { isResponseOk, response } =
    await VerificationCodeController.validateCode({ body: { code } });
  if (isResponseOk && response) {
    return {
      isCodeValid: isOkCodeStatus(response.status),
      isCodeUsed: isCodeUsed(response.status),
      status: response.status,
    };
  }

  return {
    isCodeValid: false,
    isCodeUsed: false,
    status: VerificationCodeStatus.ERROR,
  };
};

export { verifyVerificationCode };
