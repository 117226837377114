import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { H4, P } from "@base/components/Global";
import Icon from "@base/components/Global/Icon";
import { getApolloLogoWithStartURLByLanguage } from "@base/utils/iconUtil";
import { getPrimaryEmailValue, getQRCodeURL } from "@base/utils/userUtil";
import { APOLLO_CARD_BG, INFO_ICON } from "@constants/blobIcons";
import { NavigationPath } from "@constants/navigation";
import { useAppSelector } from "@store/hooks";
import { selectUserData } from "@store/user";
import { createUseStyles, getTheme } from "@theme";

const useStyles = createUseStyles(({ spacing, borderRadius, sizes, card }) => ({
  container: {
    display: "flex",
    backgroundImage: `url(${APOLLO_CARD_BG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: borderRadius.m,
    aspectRatio: card.ratio,
    minWidth: card.minWidth,
    width: "100%",
    padding: spacing.l,
    marginRight: spacing.l,
    marginLeft: spacing.l,
    marginTop: spacing.xxl,

    [sizes.md]: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 343,
    },
  },
  columns: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    flex: [1, 1, "auto"],
    alignItems: "flex-start",
    marginBottom: spacing.xl,
  },
  nameRow: {
    textTransform: "capitalize",
  },
  emailRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: [0, 1, "auto"],
  },
  qr: {
    borderRadius: borderRadius.s,
    maxWidth: "33%",
    aspectRatio: 1,
  },
  infoIcon: {
    cursor: "pointer",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    marginRight: spacing.l,
  },
  brandIcon: {
    maxWidth: "50%",
    alignSelf: "unset",
  },
}));

type Props = {
  onNavigate?: () => void;
};

const MyCard: React.FC<Props> = ({ onNavigate }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const theme = getTheme();
  const { i18n } = useTranslation();

  const onClick = (): void => {
    if (onNavigate) {
      onNavigate();
    }
    navigate(NavigationPath.Profile);
  };

  if (!userData) return null;

  return (
    <div className={classes.container}>
      <div className={classes.columns}>
        <div className={classes.content}>
          <Icon
            url={getApolloLogoWithStartURLByLanguage(i18n.language)}
            className={classes.brandIcon}
            width="100%"
            height="100%"
          />
          <H4 white bold className={classes.nameRow} noMargin>
            {userData?.firstName.split(" ").slice(0, 2).join(" ") +
              " " +
              userData?.lastName}
          </H4>
        </div>
        <img
          src={getQRCodeURL(550, userData?.guid)}
          className={classes.qr}
          draggable={false}
        />
      </div>

      <div className={classes.emailRow}>
        <P xSmall gray3 noMargin>
          {getPrimaryEmailValue(userData)}
        </P>
        <Icon
          url={INFO_ICON}
          onClick={onClick}
          stroke={theme.color.gray4}
          width={16}
          height={16}
          className={classes.infoIcon}
        />
      </div>
    </div>
  );
};

export default MyCard;
