import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { Loader } from "@base/components/Global";
import { useUser } from "@base/components/UserProvider";
import { NavigationPath } from "@constants/navigation";

const AuthStartPage: React.FC = () => {
  const { isAuthenticated, isLoading, error, signinRedirect } = useAuth();
  const [_, { hasTriedSilentSignin }] = useUser();
  const navigate = useNavigate();

  const shouldShowAuthError = error && !isLoading;

  useEffect(() => {
    if (!isAuthenticated && !isLoading && hasTriedSilentSignin) {
      void signinRedirect();
    } else if (isAuthenticated) {
      navigate(NavigationPath.Home);
    }
  }, [isAuthenticated, isLoading, hasTriedSilentSignin]);

  if (shouldShowAuthError) {
    console.error(error);

    if (
      process.env.NODE_ENV === "development" &&
      error.message === "login_required"
    ) {
      console.warn("Failed silent login, redirecting to SSO manually");
    }
  }

  return <Loader />;
};

export default AuthStartPage;
