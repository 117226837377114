import React from "react";
import { useTranslation } from "react-i18next";
import { H5, P } from "@base/components/Global";
import { Col } from "@base/components/Layout";
import { GIFT_CARD_ICON } from "@constants/blobIcons";

const GiftCardDomainTitle = () => {
  const { t } = useTranslation();

  return (
    <Col noMargin directionColumn>
      <Col noVerticalMargin col={12}>
        <H5 prefixIconUrl={GIFT_CARD_ICON} gray1>
          {t("gift_card_status_page_title")}
        </H5>
      </Col>
      <Col noMarginTop col={12}>
        <P noMarginTop small gray3>
          {t("gift_card_status_page_subtitle")}
        </P>
      </Col>
    </Col>
  );
};

export default GiftCardDomainTitle;
