import { MediaQueryContextState } from "@base/components/MediaQueryProvider/types";
import { createAction, createReducer } from "@reduxjs/toolkit";

const DEFAULT_STATE: MediaQueryContextState = {
  isCurrentSizeXl: false,
  isCurrentSizeLg: false,
  isCurrentSizeMd: false,
  isCurrentSizeSm: false,
  isMobile: false,
  isLandscape: false,
};

const MediaQueryActions = {
  setMediaQuery: createAction<Record<string, boolean>>("SET_MEDIA_QUERY"),
  setIsMobile: createAction<boolean>("SET_IS_MOBILE"),
  setIsLandscape: createAction<boolean>("SET_IS_LANDSCAPE"),
};

const mediaQueryReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(MediaQueryActions.setMediaQuery, (state, { payload }) => {
      state.isCurrentSizeXl = payload.isCurrentSizeXl;
      state.isCurrentSizeLg = payload.isCurrentSizeLg;
      state.isCurrentSizeMd = payload.isCurrentSizeMd;
      state.isCurrentSizeSm = payload.isCurrentSizeSm;
    })
    .addCase(MediaQueryActions.setIsMobile, (state, { payload }) => {
      state.isMobile = payload;
    })
    .addCase(MediaQueryActions.setIsLandscape, (state, { payload }) => {
      state.isLandscape = payload;
    });
});

export { DEFAULT_STATE, MediaQueryActions, mediaQueryReducer };
