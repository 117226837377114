import { isPresent } from "@apl-digital/utils";
import {
  clearSessionStorage,
  clearStorage,
  getSessionStorage,
  LocalStorageKey,
  SessionStorageKey,
  setSessionStorage,
  setStorage,
} from "@base/utils/storage";
import config from "@constants/config";
import { selectCurrentLanguage } from "@store/language";
import { AppStartListening } from "@store/listenerMiddleware";
import { add } from "date-fns";
import { deepEqual } from "fast-equals";

import { fetchRegions } from "./actions";
import { selectCurrentRegion, selectRegions } from "./selectors";

export const addRegionListeners = (startListening: AppStartListening) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      selectCurrentLanguage(currentState) !==
      selectCurrentLanguage(previousState),

    effect: (_action, { dispatch }) => {
      void dispatch(fetchRegions());
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      !deepEqual(currentState.region.regions, previousState.region.regions),

    effect: (_action, { getState }) => {
      const regions = selectRegions(getState());

      if (isPresent(regions)) {
        setStorage(LocalStorageKey.REGIONS, regions, {
          type: "expiringRecord",
          expiresAt: add(Date.now(), {
            seconds: config.appConfig.regionsTimeout,
          }).getTime(),
        });
      } else {
        clearStorage(LocalStorageKey.REGIONS);
      }
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      currentState.region.regionOverride !==
      previousState.region.regionOverride,

    effect: (_action, { getState }) => {
      const regionOverride = getState().region.regionOverride;

      if (isPresent(regionOverride)) {
        setSessionStorage(SessionStorageKey.REGION_OVERRIDE, regionOverride);
      } else {
        clearSessionStorage(SessionStorageKey.REGION_OVERRIDE);
      }
    },
  });

  startListening({
    predicate: (_action, currentState, previousState) =>
      selectCurrentRegion(previousState)?.prefix !==
        selectCurrentRegion(currentState)?.prefix ||
      (isPresent(selectCurrentRegion(currentState)?.prefix) &&
        !isPresent(getSessionStorage(SessionStorageKey.REGION_CURRENT))),

    effect: (_action, { getState }) => {
      const currentRegion = selectCurrentRegion(getState());

      if (isPresent(currentRegion)) {
        setSessionStorage(
          SessionStorageKey.REGION_CURRENT,
          currentRegion.prefix,
        );
      } else {
        clearSessionStorage(SessionStorageKey.REGION_CURRENT);
      }
    },
  });
};
