import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MIN_REQUIRED_AGE } from "@api/constants";
import { PersonalCodeCategory } from "@api/interfaces/userLayouts";
import { isPresent } from "@apl-digital/utils";
import DateField from "@base/components/Form/DateField";
import EditFormPage from "@base/components/Form/EditFormPage";
import TextField from "@base/components/Form/TextField";
import Button from "@base/components/Global/Button";
import P from "@base/components/Global/P";
import { Col, Row } from "@base/components/Layout";
import ModalArray from "@base/components/Modal/ModalArray";
import { formatDate } from "@base/utils/formatting";
import { PROFILE_ICON } from "@constants/blobIcons";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@constants/googleAnalytics";
import { NavigationPath } from "@constants/navigation";
import PersonalCodeSelect from "@pages/UserRegistration/components/PersonalCodeSelect";
import { useAppSelector } from "@store/hooks";
import { selectHasPersonalCodeCategories } from "@store/region";
import { selectUserData } from "@store/user";
import { sub } from "date-fns";
import { useUser } from "src/base/components/UserProvider";

type FormType = {
  birthDay: Date;
  personalCode: string;
  personalCodeCategory: PersonalCodeCategory;
};

const EditPersonalData: React.FC = () => {
  const userData = useAppSelector(selectUserData);
  const [{ updateProfile }] = useUser();
  const { t } = useTranslation();
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const navigate = useNavigate();

  const hasPersonalCodeCategories = useAppSelector(
    selectHasPersonalCodeCategories,
  );

  const personalCodeField = userData?.personalCode
    ? `${userData?.personalCode.slice(0, 2)}**********`
    : undefined;

  const defaultValues = {
    birthDay:
      isPresent(userData) && isPresent(userData.birthDay)
        ? new Date(userData.birthDay)
        : undefined,
    personalCodeCategory: PersonalCodeCategory.None,
    personalCode: personalCodeField,
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm<FormType>({
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: FormType) => {
    let birthDate = formatDate(data.birthDay, false, "/");
    if (!data.birthDay && userData?.birthDay) {
      birthDate = formatDate(userData.birthDay, false, "/");
    }

    const isSuccess = await updateProfile({
      birthDay: birthDate,
      personalCode: data.personalCode,
      personalCodeCategory: data.personalCodeCategory,
    });
    if (isSuccess) {
      setCurrentModal("editSuccessModal");
    } else {
      setCurrentModal("editErrorModal");
    }
  };

  const currentPersonalCodeCountry = watch("personalCodeCategory");

  const hasError = (inputName: keyof FormType) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  return (
    <EditFormPage
      form={{
        headingIconUrl: PROFILE_ICON,
        headingTitle: t("personal_data_title"),
        headingSubTitle: t("personal_data_subtitle"),
      }}
    >
      <ModalArray
        modals={{
          editSuccessModal: {
            title: t("modal_personal_data_edited_title"),
            subTitle: t("modal_personal_data_edited_subtitle"),
            content: t("modal_personal_data_edited_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PERSONAL_DATA_CHANGE_SUCCESS,
          },
          editErrorModal: {
            title: t("modal_profile_edit_error_title"),
            subTitle: t("modal_profile_edit_error_subtitle"),
            content: t("modal_profile_edit_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PERSONAL_DATA_CHANGE_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={(modal) => {
          setCurrentModal(null);
          if (modal === "editSuccessModal") navigate(NavigationPath.Profile);
        }}
      />
      <Col col={12} noMargin directionColumn alignCenter>
        <Row noMargin style={{ alignItems: "center" }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <Col col={12} hide={isPresent(defaultValues.birthDay)}>
              <DateField
                disableFuture
                label={t("birth_date")}
                initialValue={defaultValues.birthDay}
                maxDate={sub(new Date(), { years: MIN_REQUIRED_AGE })}
                disabled={!!defaultValues.birthDay}
                {...register("birthDay")}
              />
            </Col>
            <Col col={12} hide={!isPresent(userData?.birthDay)} directionColumn>
              <P gray1>{t("birth_date")}</P>
              {isPresent(userData?.birthDay) && (
                <P gray1>{formatDate(userData?.birthDay, false, " / ")}</P>
              )}
              <P gray3 xSmall>
                {t("why_is_birth_date_asked")}
              </P>
            </Col>
            {hasPersonalCodeCategories && (
              <Col col={12} directionColumn>
                <Col col={12} noHorizontalMargin>
                  <P gray1>{t("personal_id")}</P>
                </Col>
                {!personalCodeField && (
                  <>
                    <PersonalCodeSelect
                      id="personal-code-country-select"
                      label={t("personal_code_country")}
                      helperText={
                        hasError("personalCodeCategory") &&
                        t("field_personalCodeCountry_error")
                      }
                      hasError={hasError("personalCodeCategory")}
                      currentCountryId={userData?.regionId}
                      setCurrentValue={({ value }) => {
                        setValue(
                          "personalCodeCategory",
                          value as PersonalCodeCategory,
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          },
                        );
                      }}
                    />
                  </>
                )}
                {personalCodeField ||
                  (isPresent(currentPersonalCodeCountry) &&
                    currentPersonalCodeCountry.toLowerCase() !== "none" && (
                      <TextField
                        label={t("field_personalCode")}
                        isDisabled={!!personalCodeField}
                        value={personalCodeField}
                        {...register("personalCode")}
                      />
                    ))}

                <Col col={12} noHorizontalMargin>
                  {personalCodeField && <P>{t("personal_code_already_set")}</P>}
                </Col>
              </Col>
            )}
            <Col col={12}>
              <Button
                type="submit"
                transparentBrand
                isDisabled={!isValid || !isDirty}
                analyticsAttributes={
                  GOOGLE_ANALYTICS_ATTRIBUTES.PERSONAL_DATA_CHANGE
                }
              >
                {t("action_confirm")}
              </Button>
            </Col>
          </form>
        </Row>
      </Col>
    </EditFormPage>
  );
};

export default EditPersonalData;
