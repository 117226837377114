import { CSSProperties, ReactNode } from "react";
import { AnalyticsAttributes } from "@constants/googleAnalytics";

export const VERTICAL_GUTTER = 16; // column
export const HORIZONTAL_GUTTER = 16; // column

export const PAGE_MARGIN_BOTTOM = 96;
export const ROW_MARGIN = 16; // horizontal global
export const ROW_MARGIN_MOBILE = 16; // horizontal global

export const COL_NUMBER = 12;

export const CMS_BREAK_ROW_MOBILE = 72;
export const BREAK_ROW_MOBILE = 32;
export const CMS_BREAK_ROW = 64;
export const BREAK_ROW = 64;

export const BLOCK_BREAK_MOBILE = 72;
export const BLOCK_BREAK = 96;

export type BlockProps = {
  id?: string;
  children?: ReactNode | string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  analyticsAttributes?: AnalyticsAttributes;
};

export type MarginProps = {
  noMargin?: boolean;
  noVerticalMargin?: boolean;
  noHorizontalMargin?: boolean;
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
  noMarginLeft?: boolean;
  noMarginRight?: boolean;
};
