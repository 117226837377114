import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import GiftCardController from "@api/controller/giftCardController";
import { IValidateGiftCardRequest } from "@api/interfaces/giftcardsLayout";
import { ApiPath } from "@api/paths";
import TextField from "@base/components/Form/TextField";
import { H5, P } from "@base/components/Global";
import Button from "@base/components/Global/Button";
import H6 from "@base/components/Global/H6";
import Icon from "@base/components/Global/Icon";
import { Col, Row } from "@base/components/Layout";
import { useMediaQuery } from "@base/components/MediaQueryProvider";
import { CHECK_SMALL_ICON } from "@constants/blobIcons";
import { GiftCardNumberQP, NavigationPath } from "@constants/navigation";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import GiftCardDomainTitle from "@pages/GiftCardStatusPage/components/GiftCardDomainTitle";
import { createUseStyles, getTheme } from "@theme";
import FormProvider, {
  useFormProvider,
} from "src/base/components/FormProvider";

type FormType = {
  usageBarcode: string;
  pin1: string;
  pin2: string;
  pin3: string;
  pin4: string;
};

type pinsFieldsNames = "pin1" | "pin2" | "pin3" | "pin4";

const useStyles = createUseStyles(({ color }) => ({
  pin: {
    "&  .MuiFormControl-root": {
      width: 48,
      height: 48,
    },
    "& input": {
      textAlign: "center",
      textTransform: "uppercase",
    },
  },
  error: {
    "& p": {
      color: color.error,
    },
  },
  number: {
    "& input": {
      textTransform: "uppercase",
    },
  },
  description: {
    alignSelf: "flex-start",
  },
}));

const NEXT_PIN_REGEX = /^(?:[\dA-Za-z]|ArrowRight)$/;
const PREV_PIN_REGEX = /^(?:Backspace|ArrowLeft)$/;

const pinFieldOnFocusHandler = (e: React.FocusEvent<HTMLInputElement>) =>
  e.target.select();

const GiftCardStatusPageContent = () => {
  const classes = useStyles();
  const [hasResponseError, setHasResponseError] = useState(false);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { color } = getTheme();
  const [{ schema }] = useFormProvider();
  const navigate = useNavigate();
  const [searchQuery] = useSearchParams();
  const [{ isMobile }] = useMediaQuery();

  const resolver =
    schema && Object.keys(schema).length > 0 ? yupResolver(schema) : undefined;

  const {
    register,
    handleSubmit,
    trigger,
    setFocus,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<FormType>({
    mode: "onChange",
    resolver: resolver,
    defaultValues: {
      usageBarcode: searchQuery?.get(GiftCardNumberQP) ?? undefined,
    },
  });

  const onSubmit = async (data: FormType) => {
    setHasResponseError(false);
    const request: IValidateGiftCardRequest = {
      usageBarcode: data.usageBarcode,
      pin: `${data.pin1}${data.pin2}${data.pin3}${data.pin4}`,
    };
    setIsSubmitting(true);
    const { isResponseOk, response } =
      await GiftCardController.validateGiftCard({
        body: request,
      });
    setIsSubmitting(false);
    if (isResponseOk && response) {
      navigate(NavigationPath.GiftCardStatusDetails, {
        state: { validationCard: response },
      });
    } else {
      setHasResponseError(true);
      setShouldDisplayError(true);
    }
  };

  const hasError = (inputName: keyof FormType) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (hasResponseError) {
      timer = setTimeout(() => {
        setShouldDisplayError(false);
      }, 4000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [hasResponseError]);

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement> | undefined) => {
    if (!e) return;

    e.preventDefault();

    const pasteData = e.clipboardData.getData("Text")?.split("");
    if (pasteData.length === 4) {
      setValue("pin1", pasteData[0]);
      setValue("pin2", pasteData[1]);
      setValue("pin3", pasteData[2]);
      setValue("pin4", pasteData[3]);
      setFocus("pin4");
    }
  };

  const pinShift = (
    e: React.ChangeEvent<HTMLInputElement>,
    next?: pinsFieldsNames,
    prev?: pinsFieldsNames,
  ) => {
    if (!isMobile) return;

    if (next && e.target.value) {
      setFocus(next);
    } else if (prev && !e.target.value) {
      setFocus(prev);
    }
  };

  const onKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    next?: pinsFieldsNames,
    prev?: pinsFieldsNames,
  ) => {
    if (isMobile) return;

    if (next && Boolean(NEXT_PIN_REGEX.test(e.key))) {
      setFocus(next);
    } else if (prev && Boolean(PREV_PIN_REGEX?.test(e.key))) {
      setFocus(prev);
    }
  };

  return (
    <Row>
      <Col fullWidth alignCenter>
        <Col col={12} colMd={6} noMargin directionColumn fullWidth>
          <GiftCardDomainTitle />
          <Col col={12} noMargin directionColumn alignCenter fullWidth>
            <Row noMargin>
              <Col col={12}>
                <H5 gray1>{t("gift_card_status_page_card_number")}</H5>
              </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <Col col={12} className={classes.number}>
                <TextField
                  label={t("field_card_number")}
                  id="usageBarcode"
                  data-testid="field-usageBarcode"
                  onEnter={{ trigger, name: "pin1" }}
                  onFocus={(e) => e.target.select()}
                  autoFocus={!searchQuery?.get(GiftCardNumberQP)}
                  placeholder={t("field_card_number_placeholder")}
                  helperText={
                    hasError("usageBarcode") &&
                    t("gift_card_status_card_number_format_error")
                  }
                  hasError={hasError("usageBarcode") || hasResponseError}
                  {...register("usageBarcode")}
                />
              </Col>
              <Row noMargin>
                <Col col={12}>
                  <H6 gray1>{t("gift_card_status_page_pin")}</H6>
                </Col>
              </Row>
              <Row noMargin style={{ justifyContent: "center" }}>
                <Col col={2} className={classes.pin} noHorizontalMargin>
                  <TextField
                    id="pin1"
                    data-testid="field-pin1"
                    autoFocus={!!searchQuery?.get(GiftCardNumberQP)}
                    onFocus={pinFieldOnFocusHandler}
                    maxLength={1}
                    hasError={hasError("pin1") || hasResponseError}
                    handleKeyUp={(e) => onKeyUp(e, "pin2")}
                    onPaste={onPaste}
                    {...register("pin1", {
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        pinShift(e, "pin2"),
                    })}
                  />
                </Col>
                <Col col={2} className={classes.pin} noHorizontalMargin>
                  <TextField
                    id="pin2"
                    data-testid="field-pin2"
                    maxLength={1}
                    handleKeyUp={(e) => onKeyUp(e, "pin3", "pin1")}
                    onFocus={pinFieldOnFocusHandler}
                    hasError={hasError("pin2") || hasResponseError}
                    {...register("pin2", {
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        pinShift(e, "pin3", "pin1"),
                    })}
                  />
                </Col>
                <Col col={2} className={classes.pin} noHorizontalMargin>
                  <TextField
                    id="pin3"
                    data-testid="field-pin3"
                    maxLength={1}
                    handleKeyUp={(e) => onKeyUp(e, "pin4", "pin2")}
                    onFocus={pinFieldOnFocusHandler}
                    hasError={hasError("pin3") || hasResponseError}
                    {...register("pin3", {
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        pinShift(e, "pin4", "pin2"),
                    })}
                  />
                </Col>
                <Col col={2} className={classes.pin} noHorizontalMargin>
                  <TextField
                    id="pin4"
                    data-testid="field-pin4"
                    maxLength={1}
                    handleKeyUp={(e) => onKeyUp(e, undefined, "pin3")}
                    onFocus={pinFieldOnFocusHandler}
                    hasError={hasError("pin4") || hasResponseError}
                    submitOnEnter
                    {...register("pin4", {
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        pinShift(e, undefined, "pin3"),
                    })}
                  />
                </Col>
              </Row>
              <Col col={12} hide={!shouldDisplayError}>
                <P className={classes.error}>
                  {t("gift_card_number_or_pin_error")}
                </P>
              </Col>
              <Col col={12}>
                <Button
                  type="submit"
                  data-testid="status-check-submit-btn"
                  isLoading={isSubmitting}
                  prefixIcon={
                    <Icon url={CHECK_SMALL_ICON} stroke={color.white} />
                  }
                >
                  {t("action_check_card_status")}
                </Button>
              </Col>
            </form>
          </Col>
        </Col>
        <Col aboveMd col={1} />
        <Col
          colMd={5}
          aboveMd
          directionColumn
          fullWidth
          className={classes.description}
          alignCenter
        >
          <Col fullWidth>
            <H5>{t("gift_card_status_description_title")}</H5>
          </Col>
          <Col fullWidth noMarginTop>
            <P>{t("gift_card_status_description_text")}</P>
          </Col>
        </Col>
      </Col>
    </Row>
  );
};

const GiftCardStatusPage = () => {
  return (
    <FormProvider url={ApiPath.Form.giftCardStatus}>
      <GiftCardStatusPageContent />
    </FormProvider>
  );
};

export default GiftCardStatusPage;
