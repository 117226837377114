import { isPresent } from "@apl-digital/utils";
import config from "@constants/config";

export const isGiftCardDomain = config.appConfig.loyaltyGiftCardUri.includes(
  window.origin,
);

export const isApolloMainDomain = config.appConfig.loyaltyHubUri.includes(
  window.origin,
);

export const isRegionalDomain = isPresent(config.appConfig.regionPrefix);

export const isProfileDomain = config.appConfig.loyaltyProfileUri.includes(
  window.origin,
);

export const isPublicDomain =
  !isGiftCardDomain &&
  !isProfileDomain &&
  !isApolloMainDomain &&
  !isRegionalDomain;
