import React from "react";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles(({ color, spacing, font, borderRadius }) => ({
  badge: {
    background: color.primaryBrand,
    borderRadius: borderRadius.l,
    display: "flex",
    alignItems: "center",
    minWidth: 24,
    height: 24,
    lineHeight: font.lineHeight.m,
    fontSize: font.size.xs,
    fontWeight: font.weight.m,
    marginLeft: spacing.s,
    color: color.white,
    padding: [spacing.xs, spacing.s],
  },
}));

const Badge: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const classes = useStyles();

  if (!children) return null;
  return <span className={classes.badge}>{children}</span>;
};

export default Badge;
