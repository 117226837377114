import React from "react";
import {
  CountryData,
  CountryIso2,
  parseCountry,
} from "react-international-phone";
import { Icon } from "@base/components/Global";
import { createUseStyles } from "@theme";

const useStyles = createUseStyles(({ color, spacing, font }) => ({
  container: {
    position: "relative",
    display: "flex",
    height: 36,
    padding: [spacing.s, spacing.l],
    cursor: "pointer",
    width: "100%",
    background: "unset",
    border: "none",
    fontSize: "inherit",
    alignItems: "center",
  },
  icon: {
    display: "flex",
    marginRight: spacing.xs,
  },
  svg: {
    width: 20,
    height: "unset",
  },
  code: {
    color: color.black,
    fontWeight: font.weight.m,
    display: "flex",
    minWidth: 50,
    marginLeft: "auto",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  title: {
    color: color.black,
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
    fontWeight: font.weight.s,
  },
}));

type DropdownItemProps = {
  country: CountryData;
  setCountry: (country: CountryIso2) => void;
};

const DropdownItem: React.FC<DropdownItemProps> = ({ country, setCountry }) => {
  const classes = useStyles();
  const { iso2, name, dialCode } = parseCountry(country);

  return (
    <button className={classes.container} onClick={() => setCountry(iso2)}>
      <div className={classes.icon}>
        <Icon
          url={`../../assets/flags/${iso2.toUpperCase()}.svg`}
          className={classes.svg}
        />
      </div>
      <div className={classes.title}>{name}</div>
      <div className={classes.code}>{`+${dialCode}`}</div>
    </button>
  );
};

export default DropdownItem;
