import UserController from "@api/controller/userController";
import { IUserData } from "@api/interfaces/userLayouts";
import { ResponseFailed } from "@api/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppAsyncThunkConfig } from "@store/types";
import { createApiGetAsyncThunk } from "@store/utils";

export const fetchUserData = createAsyncThunk<
  IUserData,
  void,
  AppAsyncThunkConfig<ResponseFailed>
>("user/fetchUserData", async (_, { rejectWithValue }) => {
  const response = await UserController.getUserData();
  if (response && response.isResponseOk) {
    return response.response;
  }
  return rejectWithValue(response);
});

export const fetchLoyaltyPrograms = createApiGetAsyncThunk(
  "user/fetchLoyaltyPrograms",
  UserController.fetchLoyaltyPrograms,
  (state) => state.user.loyaltyPrograms,
);

export const fetchBenefits = createApiGetAsyncThunk(
  "user/fetchBenefits",
  UserController.fetchBenefits,
  (state) => state.user.benefits,
);

export const fetchTransactions = createApiGetAsyncThunk(
  "user/fetchTransactions",
  UserController.fetchTransactions,
  (state) => state.user.transactions,
);

export const fetchLatestTransactions = createApiGetAsyncThunk(
  "user/fetchLatestTransactions",
  UserController.fetchLatestTransactions,
  (state) => state.user.latestTransactions,
);
