import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isGiftCardDomain } from "@base/utils/domainHelper";
import { NavigationPath } from "@constants/navigation";

const EmptyPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isGiftCardDomain) {
      navigate(NavigationPath.GiftCardStatus, { replace: true });
    } else {
      navigate(NavigationPath.Home, { replace: true });
    }
  });

  return null;
};

export default EmptyPage;
