import { loadCurrentConfig, validateConfigs } from "@apl-digital/tenant-config";
import { z } from "zod";

import rawConfig from "../../config.yaml";

const AppConfigSchema = z
  .object({
    maxEmailCount: z.number().positive().int(),
    maxPhoneCount: z.number().positive().int(),
    maxAddressCount: z.number().positive().int(),
    loyaltyHubUri: z.string().url(),
    loyaltyProfileUri: z.string().url(),
    loyaltyGiftCardUri: z.string().url(),
    regionsTimeout: z.number().positive().int(),
    regionPrefix: z.string().nullable(),
    noIndex: z.boolean(),
    manifest: z.string(),
    errorPageMaxRetryCount: z.number().positive().int().nullable(),
  })
  .strict();

const SSOConfigSchema = z
  .object({
    authorityUrl: z.string().url(),
    defaultClientId: z.string(),
    region: z.record(z.string(), z.object({ clientId: z.string() }).strict()),
  })
  .strict();

const AssetConfigSchema = z
  .object({
    translations: z
      .object({
        url: z.string().url(),
        overrideI18nLanguage: z.string().nullable(),
      })
      .strict(),
    images: z
      .object({
        url: z.string().url(),
      })
      .strict(),
  })
  .strict();

const ApiConfigSchema = z
  .object({
    loyaltyProxy: z
      .object({
        url: z.string().url(),
      })
      .strict(),
    azure: z
      .object({
        applicationInsight: z
          .object({
            instrumentationKey: z.string(),
          })
          .strict(),
      })
      .strict(),
    google: z
      .object({
        maps: z
          .object({
            apiKey: z.string(),
          })
          .strict(),
        tagManager: z
          .object({
            code: z.string(),
          })
          .strict()
          .nullable(),
      })
      .strict(),
  })
  .strict();

const FeatureFlagsSchema = z
  .object({
    giftCardLive: z.boolean(),
    finRegionUnlocked: z.boolean(),
    latRegionUnlocked: z.boolean(),
    litRegionUnlocked: z.boolean(),
    shouldFallbackLvLanguage: z.boolean(),
  })
  .strict();

const DevFlagsSchema = z
  .object({
    translationsDebug: z.boolean(),
    storageDebug: z.boolean(),
  })
  .strict();

const SiteConfigSchema = z
  .object({
    appConfig: AppConfigSchema,
    ssoConfig: SSOConfigSchema,
    assetConfig: AssetConfigSchema,
    apiConfig: ApiConfigSchema,
    featureFlags: FeatureFlagsSchema,
    devFlags: DevFlagsSchema,
  })
  .strict();

const tenantKey = window.location.hostname.split(".").reverse();

if (process.env.NODE_ENV === "development") {
  validateConfigs(SiteConfigSchema, rawConfig, tenantKey);
}

const config = loadCurrentConfig(SiteConfigSchema, rawConfig, tenantKey);
export default config;
