import React, { useMemo } from "react";
import { isPresent } from "@apl-digital/utils";
import SelectList, {
  SelectListProps,
  Value,
} from "@base/components/Form/SelectList";
import { useAppSelector } from "@store/hooks";
import { selectRegions } from "@store/region/selectors";

type PersonalCodeSelectProps = SelectListProps & {
  currentCountryId?: string;
  currentValue?: string;
  setCurrentValue: (data: Value) => void;
};

const PersonalCodeSelect: React.FC<PersonalCodeSelectProps> = ({
  currentCountryId,
  currentValue,
  setCurrentValue,
  ...props
}) => {
  const regions = useAppSelector(selectRegions);

  const personalCodeValues = useMemo(() => {
    const currentRegion = regions?.find(
      (region) => region.id === currentCountryId,
    );
    if (currentRegion?.personalCodeCategories?.length) {
      return currentRegion.personalCodeCategories.map((category) => ({
        value: category.personalCodeCategory,
        label: category.personalCodeCategoryDisplayName,
      }));
    }

    return [];
  }, [currentCountryId]);

  const value = useMemo(() => {
    if (
      isPresent(currentValue) &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      personalCodeValues.some((i) => i.value === currentValue)
    ) {
      return currentValue;
    }

    const noneValue = personalCodeValues.find(
      (i) => i.label.toLowerCase() === "none",
    );
    if (isPresent(noneValue)) {
      return noneValue.value as string;
    }

    return null;
  }, [personalCodeValues, currentValue]);

  if (!personalCodeValues || personalCodeValues.length === 0) return null;

  return (
    <SelectList
      {...props}
      onChange={(e) => {
        if (
          personalCodeValues &&
          personalCodeValues?.length > 0 &&
          setCurrentValue
        ) {
          const newValue = personalCodeValues?.find(
            (c) => String(c?.value) === String(e.target.value),
          );
          if (newValue) {
            setCurrentValue(newValue);
          }
        }
      }}
      values={personalCodeValues}
      currentValue={value}
    />
  );
};

export default PersonalCodeSelect;
