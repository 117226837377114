import React from "react";
import Warning from "@base/components/Error/Warning";
import { Link, P, Section } from "@base/components/Global";
import { CmsPage } from "@base/components/Global/cmsPage";
import { Col, Row } from "@base/components/Layout";
import { useAppDispatch, useAppSelector } from "@store";
import { fetchGoodbyePage, selectGoodbyePage } from "@store/cms";
import { createUseStyles } from "@theme";

const useGoodbyePageStyles = createUseStyles(({ spacing }) => ({
  container: {
    gap: spacing.xxl,
    marginTop: spacing.l + spacing.xxs,
  },
  page: {
    marginTop: spacing.xxl,
  },
}));

const Goodbye = () => {
  const goodbyePage = useAppSelector(selectGoodbyePage);
  const dispatch = useAppDispatch();
  const classes = useGoodbyePageStyles();

  return (
    <CmsPage
      pageStateMachine={goodbyePage}
      fetchPage={() => dispatch(fetchGoodbyePage())}
    >
      {({ page }) => (
        <Row className={classes.page}>
          <Col aboveMd colMd={3} />
          <Col noMargin col={12} colMd={6} directionColumn>
            <Section noAdditionalMargin title={page.title}>
              <Row noMargin className={classes.container}>
                <Col col={12} noMargin>
                  {page.warningText && (
                    <Warning xSmall message={page.warningText} noMargin />
                  )}
                </Col>
                <Col col={12} noMargin>
                  <P>{page.mainText}</P>
                </Col>
                <Col col={12} directionColumn noMargin>
                  <P xSmall noMargin gray3>
                    {page.linksLabel}
                  </P>
                  {page.links?.map((link) => (
                    <Link key={link.title} bold brandColor href={link.data}>
                      {link.title}
                    </Link>
                  ))}
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      )}
    </CmsPage>
  );
};

export default Goodbye;
