import React, { useState } from "react";
import { UseFormTrigger } from "react-hook-form";
import {
  CountryIso2,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { mergeRefs } from "react-merge-refs";
import DropdownBlock from "@base/components/Form/PhoneField/DropdownBlock";
import DropdownButton from "@base/components/Form/PhoneField/DropdownButton";
import { useStyles as useTextFieldStyles } from "@base/components/Form/TextField";
import { BaseTextFieldProps, InputAdornment, TextField } from "@mui/material";
import { useAppSelector } from "@store";
import { selectCurrentRegionPrefix, selectRegions } from "@store/region";
import { createUseStyles } from "@theme";
import classNames from "classnames";

export const DROPDOWN_BUTTON_ID = "dropdown-button";

type StyleProps = {
  isFocused: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
};

export type MUIPhoneProps = {
  value?: string;
  onChange: (e: { target: { value: string } }) => void;
  hasError?: boolean;
  innerRef?: React.RefCallback<HTMLInputElement>;
  onEnter?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trigger: UseFormTrigger<any>;
    name: string;
  };
  submitOnEnter?: boolean;
  handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
} & BaseTextFieldProps;

const useStyle = createUseStyles<StyleProps>(({ color, font }) => ({
  containerOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: "rgba(0, 0, 0, 1)",
  },
  container: {
    fontSize: font.size.s,
    lineHeight: font.lineHeight.m,
    paddingTop: 0,
    borderColor: ({ isFocused, hasError, isDisabled }) => {
      if (hasError) {
        return color.error;
      }
      if (isFocused) {
        return color.primaryBrand;
      }
      if (isDisabled) {
        return color.gray5;
      }
      return color.gray4;
    },
  },
  textField: {
    borderWidth: ({ isFocused }) => (isFocused ? 2 : 1),
    borderStyle: "solid",
    borderColor: ({ isFocused, hasError, isDisabled }) => {
      if (hasError) {
        return color.error;
      }
      if (isFocused) {
        return color.primaryBrand;
      }
      if (isDisabled) {
        return color.gray5;
      }
      return color.gray4;
    },
  },
}));

const PhoneField: React.FC<MUIPhoneProps> = ({
  value = "",
  onChange,
  innerRef,
  onEnter,
  submitOnEnter,
  handleKeyDown,
  ...restProps
}) => {
  const currentRegionPrefix = useAppSelector(selectCurrentRegionPrefix);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(restProps.focused);
  const regions = useAppSelector(selectRegions);
  const defaultCountry = currentRegionPrefix
    ? (currentRegionPrefix as CountryIso2)
    : "ee";
  const preferredCountries = regions?.map(
    (region) => region.prefix as CountryIso2,
  );
  const sharedClasses = useTextFieldStyles();
  const classes = useStyle({
    isFocused: Boolean(isDropdownOpen || isFocused),
    hasError: restProps.hasError,
    isDisabled: restProps.disabled,
  });
  const preparedCountries = preferredCountries
    ? [
        ...defaultCountries.filter((country) => {
          const { iso2 } = parseCountry(country);
          return preferredCountries.includes(iso2);
        }),
        ...defaultCountries.filter((country) => {
          const { iso2 } = parseCountry(country);
          return !preferredCountries.includes(iso2);
        }),
      ]
    : defaultCountries;
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry,
      value,
      countries: preparedCountries,
    });

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!submitOnEnter) e.preventDefault();
      if (onEnter) {
        void onEnter.trigger(onEnter.name, { shouldFocus: true });
      }
    }
    if (handleKeyDown) handleKeyDown(e);
  };

  return (
    <div
      className={classNames([
        sharedClasses.container,
        classes.container,
        isDropdownOpen && classes.containerOpen,
      ])}
    >
      <TextField
        variant="outlined"
        label="Phone number"
        color="primary"
        placeholder="+123 456 7890"
        value={inputValue}
        onChange={(e) => {
          handlePhoneValueChange(e);
          void onChange(e);
        }}
        type="tel"
        inputRef={mergeRefs([inputRef, innerRef])}
        focused={isFocused || isDropdownOpen}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DropdownButton
                country={country}
                setOpen={setIsDropdownOpen}
                isOpen={isDropdownOpen}
                isDisabled={restProps.disabled}
              />
            </InputAdornment>
          ),
        }}
        onKeyDown={onKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...restProps}
      />
      {isDropdownOpen && (
        <DropdownBlock
          countries={preparedCountries}
          isOpen={isDropdownOpen}
          setCountry={setCountry}
          setOpen={setIsDropdownOpen}
        />
      )}
    </div>
  );
};

export default PhoneField;
