import React from "react";
import { IPerk } from "@api/interfaces/perkLayouts";
import { CardSideProps } from "@base/components/FlippableCard/FlippableCard";
import { createUseStyles } from "@theme";

type UseStyleOptions = Omit<CardSideProps, "isVisible">;

const useStyles = createUseStyles(({ borderRadius }) => ({
  img: {
    maxWidth: ({ maxWidth }: UseStyleOptions) => maxWidth,
    width: ({ width }: UseStyleOptions) => width,
    minWidth: ({ minWidth }: UseStyleOptions) => minWidth,

    cursor: "pointer",
    boxShadow: "0px 0px 15px rgba(130, 130, 130, 0.5)",
    borderRadius: borderRadius.m,
  },
}));

type CardFrontSideProps = CardSideProps & {
  perk: IPerk;
  onClick?: () => void;
};

export const CardFrontSide: React.FC<CardFrontSideProps> = ({
  perk,
  onClick,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <img
      src={perk.portraitImage.url}
      onClick={() => onClick?.()}
      className={classes.img}
      key={perk.usageBarcode}
      draggable={false}
    />
  );
};
