import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ILocationsBlock } from "@api/interfaces/locationPageLayout";
import { useAccordionList } from "@base/components/AccordionList/AccordionListProvider";
import { P } from "@base/components/Global";
import { Col, Row } from "@base/components/Layout";
import { sortOrderCompare } from "@base/utils/arrayHelpers";
import { getUniqueKeyValues } from "@base/utils/objectHelpers";

import BusinessListItem from "./BusinessListItem";

export const BusinessListItems: React.FC<{
  locations: ILocationsBlock[];
  onCopyUrl: () => void;
}> = ({ locations, onCopyUrl }) => {
  const [{ activeItem }] = useAccordionList();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const handleCloseItem = () => {
    searchParams.delete("brand");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!activeItem) return;

    searchParams.set("brand", activeItem.id);
    setSearchParams(searchParams);
  }, [activeItem]);

  const getFilteredBusiness = (brandSystemKey?: string): ILocationsBlock[] =>
    locations
      .filter((location) => location.brandSystemKey === brandSystemKey)
      .sort((a, b) => sortOrderCompare(a.unitSortOrder, b.unitSortOrder));

  const currentBrandsKey = useMemo(
    () =>
      getUniqueKeyValues(
        "brandSystemKey",
        locations.sort((a, b) =>
          sortOrderCompare(a.businessSortOrder, b.businessSortOrder),
        ),
      ),
    [locations],
  );

  return (
    <>
      {currentBrandsKey?.map((brand, index) => (
        <BusinessListItem
          key={brand}
          id={String(brand ?? index)}
          onCopyUrl={onCopyUrl}
          onClose={handleCloseItem}
          locationsBlocks={getFilteredBusiness(brand)}
          isActive={searchParams.get("brand") === brand}
        />
      ))}
      {currentBrandsKey?.length === 0 && !!locations && (
        <Row noMargin>
          <Col col={12}>
            <P>{t("no_locations")}</P>
          </Col>
        </Row>
      )}
    </>
  );
};
