import React from "react";
import { useMediaQuery } from "src/base/components/MediaQueryProvider";

export type VisibilityProps = {
  // Question is: when to show? - belowMd / aboveSm
  hide?: boolean;
  belowSm?: boolean;
  belowMd?: boolean;
  belowLg?: boolean;
  aboveSm?: boolean;
  aboveMd?: boolean;
  aboveLg?: boolean;
  aboveXl?: boolean;
};

type VisibilityBlockProps = VisibilityProps & {
  children?: React.ReactNode | string;
};

const VisibilityBlock: React.FC<VisibilityBlockProps> = ({
  hide = false,
  belowSm,
  belowMd,
  belowLg,
  aboveSm,
  aboveMd,
  aboveLg,
  aboveXl,
  children,
}) => {
  const [
    { isCurrentSizeXl, isCurrentSizeLg, isCurrentSizeMd, isCurrentSizeSm },
  ] = useMediaQuery();

  if (hide) return null;

  if (isCurrentSizeXl) {
    if (belowSm || belowMd || belowLg) return null;
  } else if (isCurrentSizeLg) {
    if (belowSm || belowMd || aboveXl) return null;
  } else if (isCurrentSizeMd) {
    if (belowSm || aboveLg || aboveXl) return null;
  } else if (isCurrentSizeSm) {
    if (aboveMd || aboveLg || aboveXl) return null;
  } else {
    // regular size (mobile)
    if (aboveSm || aboveMd || aboveLg || aboveXl) return null;
  }

  return <>{children}</>;
};

export default VisibilityBlock;
